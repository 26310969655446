<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li class="item" v-for="item in upper" :key="item.key" @click="onClickMenu(item)">
                <i class="material-icons-outlined icon" v-if="item.icon">{item.icon}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyInfoMenu',
    props: {
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        upper() {
            return [
                {
                    key: 'CERTIFICATE_CENTER',
                    route: 'CertificatePage',
                    show: true,
                    step: 3, // 온보딩 5가지 스텝 넘버
                },
                {
                    key: 'IDEAL_TYPE',
                    route: 'EditStylePage',
                    show: true,
                    step: 4, // 온보딩 5가지 스텝 넘버
                },
            ]
        },
    },
    methods: {
        onClickMenu(item) {
            if (item.route === 'CustomerCenterPage') {
                this.$router.push({ name: item.route })
                return
            }
            if (this.stepToast && this.stepToast.step <= item.step) {
                this.$toast.success(this.stepToast.msg)
                return
            }
            this.$stackRouter.push({ name: item.route })
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    .hr.thick {
        background: $grey-02;
        border: 0;
        height: 8px;
    }

    .item {
        padding: 24px 16px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-bottom: solid 1px $grey-02;
        }

        .icon {
            color: $grey-06;
            margin-right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
    }
}
</style>

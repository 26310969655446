<template>
    <div class="signup upload-photo">
        <ProgressBar :step="$case.toSnake('upload_photo')" />
        <div class="content">
            <div class="category">Personal</div>
            <div class="title">프로필 사진</div>
            <div class="body">
                <div class="item-desc m-b-32">
                    <span class="f-bold">얼굴이 분명하게 나온 사진 2장</span>이 꼭 필요해요!
                </div>
                <div class="photos">
                    <div
                        :key="photo.url"
                        v-for="(photo, i) of photos"
                        class="photo"
                        :class="{ required: photo.required }"
                        @click.stop="onClickPhoto(i)"
                        :style="{ backgroundImage: `url('${photo.url}')` }"
                    >
                        <span v-if="photo.required && !photo.url" class="badge">필수</span>
                        <i v-if="!photo.url" class="material-icons">add</i>
                        <img
                            :src="require('@/assets/images/btn-close.png')"
                            v-if="photo.url"
                            class="close-icon"
                            @click.stop="removePhoto(i)"
                        />
                    </div>
                    <input
                        ref="imageUploader"
                        type="file"
                        class="image-input display-none"
                        accept="image/*"
                        @change="onChangeImage"
                    />
                </div>
            </div>
        </div>
        <BottomButton :disabled="disabled" @click="next" :label="`입력완료`" />
    </div>
</template>

<script>
import profileService from '@/services/profile'

export default {
    name: 'UploadPhotoPage',
    data: () => ({
        photos: null,
        selectedIndex: null,
        loading: false,
        terms: null,
    }),
    computed: {
        disabled() {
            if (!this.photos) return true
            return !this.photos.filter(p => p.required).every(p => p.blob)
        },
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
        profile() {
            const profile = this.$store.getters.me.profile

            return { ...profile, ...this.signupData }
        },
        sections() {
            const A = {
                title: 'Personal',
                column: [
                    'age',
                    'marry',
                    'height',
                    'shape',
                    'state',
                    'mbti',
                    'exercise_freq',
                    'exercise_type',
                    'smoking',
                    'drink_freq',
                    'drink_style',
                    'religion',
                    'pet',
                    'intro',
                ],
            }
            // const B = { title: 'Education', column: ['', 'jobCategory', 'company', 'jobType', 'jobDescription']}
            const B = { title: 'Education', column: ['school', 'doctor', 'master', 'bachelor', 'high'] }
            const C = {
                title: 'Career',
                column: ['job', 'jobCategory', 'company', 'company_department', 'company_task'],
            }
            const D = {
                title: 'Financial',
                column: ['income', 'asset', 'house_style', 'house_payment', 'car'],
            }
            const E = { title: 'Family', column: ['hometown', 'family_asset', 'family_intro'] }
            const F = { title: 'Marriage', column: ['marry_plan', 'child_plan', 'career_plan'] }

            return [A, B, C, D, E, F]
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        preparedPayload() {
            const keys = this.sections.reduce((result, keys) => {
                return result.concat(keys.column)
            }, [])
            const form = new FormData()
            form.append('id', this.$store.getters.me.profile.id)
            form.append('update_from', 'updatePhotoPage')
            keys.forEach(key => {
                const snake = this.$case.toSnake(key)

                if (snake === 'smoking') {
                    form.append('smoking', this.profile.smoking.name === 'NON_SMOKING' ? '0' : '1')
                    return
                }

                if (
                    [
                        'shape',
                        'religion',
                        'kind',
                        'mbti',
                        'pet',
                        'exercise_freq',
                        'exercise_type',
                        'drink_freq',
                        'drink_style',
                        'house_style',
                        'house_payment',
                        'marry_plan',
                        'child_plan',
                        'career_plan',
                    ].indexOf(snake) > -1
                ) {
                    return
                }

                if (snake === 'car') {
                    if (this.profile.car_model) {
                        form.append('car_model', this.profile.car_model)
                        return
                    } else {
                        form.append('car_model', '')
                    }
                }

                if (snake === 'marry' && this.profile.marry !== null) {
                    form.append('marry', this.$store.getters.me.profile.marry ? '1' : '0')
                    return
                }

                if (snake === 'state' || snake === 'hometown') {
                    if (snake === 'hometown') return

                    const prefix = snake !== 'state' ? `${snake}_` : ''
                    form.append(`${prefix}state_id`, this.profile[`${prefix}state`].id)
                    if (this.profile[`${prefix}state`].is_station) {
                        form.append(`${prefix}station_id`, this.profile[`${prefix}station`].id)
                    } else {
                        form.append(`${prefix}region_id`, this.profile[`${prefix}region`].id)
                    }

                    return
                }

                if (snake === 'bachelor' || snake === 'master' || snake === 'doctor') {
                    form.append(`${snake}_u_id`, this.profile[`${snake}_u_id`] || '')
                    form.append(`${snake}_u_name`, this.profile[`${snake}_u_name`] || '')
                    form.append(`${snake}_c_id`, this.profile[`${snake}_c_id`] || '')
                    form.append(`${snake}_c_name`, this.profile[`${snake}_c_name`] || '')
                    form.append(`${snake}_m_id`, this.profile[`${snake}_m_id`] || '')
                    form.append(`${snake}_m_name`, this.profile[`${snake}_m_name`] || '')
                    return
                }

                if (snake === 'high') {
                    form.append('high_school_name', this.profile.high_school_name || '')
                    return
                }

                if (['intro', 'finance', 'family_intro'].indexOf(snake) !== -1) {
                    form.append(snake, this.profile[snake] || '')
                    return
                }

                if (!this.profile[snake]) return

                if (
                    ['company_department', 'company_task', 'income', 'asset', 'family_asset', 'height'].indexOf(
                        snake
                    ) !== -1
                ) {
                    form.append(`${snake}`, this.profile[`${snake}`])
                    return
                }

                const prefix = this.userInputAllowed(snake) ? 'name' : 'id'
                form.append(`${snake}_${prefix}`, this.profile[snake][prefix])
            })
            const profileOptionIds = this.profile.profile_option_ids.filter(id => {
                return Number(id)
            })
            form.append('profile_option_ids', JSON.stringify(profileOptionIds))

            this.profile.photos
                .filter(p => p.blob)
                .forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))

            return form
        },
        userInputAllowed(key) {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(key) !== -1
        },
        sendEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `${this.$store.getters.me.gender === 0 ? 'Male_3' : 'Female_3'}_Complete_Profile`,
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Complete_Profile',
                },
            })
        },
        async next() {
            if (this.disabled) return

            this.$set(
                this.profile,
                'photos',
                this.photos.filter(p => p.blob)
            )
            try {
                this.$loading(true)
                const res = await profileService.update(this.preparedPayload())

                if (res) {
                    localStorage.removeItem('signupData')
                    this.sendEvents()

                    await this.$store.dispatch('loadMe')
                    const idx = await this.$modal.basic({
                        body: '(안내) 추가 프로필 입력을 추천드려요. 지금 입력하시겠어요?',
                        buttons: [
                            {
                                label: '나중에하기',
                                class: 'btn-default',
                            },
                            {
                                label: '입력하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx) {
                        await this.$router.push({ name: 'ChatsPage' })
                        setTimeout(() => {
                            this.$stackRouter.push({
                                name: 'EditProfilePage',
                                props: { initialTab: 'PROFILE' },
                            })
                        }, 200)
                    } else {
                        this.$router.push({ name: 'ChatsPage' })
                    }
                }
            } catch (e) {
                this.$router.push({ name: 'HomePage' })
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onClickPhoto(index) {
            this.selectedIndex = index
            this.$openGallery()

            let category = ''

            switch (index) {
                case 0:
                    category = 'Click_FirstPhotoUploadBox'
                    break
                case 1:
                    category = 'Click_SecondPhotoUploadBox'
                    break
            }

            if (category) {
                const { provider, gender } = this.$store.getters.payloads.signupData

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category,
                        option: {
                            provider: provider || 'email',
                            gender: gender.name === 'MALE' ? 0 : 1,
                        },
                    },
                })
            }
        },
        removePhoto(index) {
            this.photos[index].blob = null
            this.photos[index].url = null
            this.photos[index].name = null
        },
        onChangeImage(event) {
            const selectedPhoto = this.photos[this.selectedIndex]
            const imgFile = event.blob
            selectedPhoto.name = event.fileName

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
    },
    created() {
        this.photos = Array(6)
            .fill({})
            .map((p, i) => {
                p = { blob: null, name: null, url: null }
                this.$set(p, 'required', i < 2)
                return p
            })

        const storedPhotos = this.$store.getters.payloads.signupData.photos
        if (storedPhotos) {
            ;(storedPhotos || []).forEach((photo, idx) => {
                this.photos[idx] = storedPhotos[idx]
            })
        }
    },
    mounted() {
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangeImage)
        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'UploadPhotoPage_Enter_UploadPhotoPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
    beforeDestroy() {
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangeImage)
    },
}
</script>

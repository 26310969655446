<template>
    <div class="chat-pump" />
</template>
<script>
import ChatConst from '@/assets/constants/chat'

export default {
    name: 'ChatPump',
    computed: {
        authToken() {
            const header = this.$store.getters.header
            if (!header) return

            return header['X-User-Token']
        },
        chats() {
            return this.$store.getters.chats || []
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        connected: {
            get() {
                return this.$store.getters.connected
            },
            set(val) {
                this.$store.commit('connected', val)
            },
        },
        identifier() {
            return JSON.stringify({
                channel: ChatConst.CHANNEL_USER,
                role: 'user',
                from: 'mobile',
            })
        },
        notices() {
            return this.$store.getters.notices
        },
    },
    data: () => ({
        socket: null,
        pingInterv: null,
        lastPing: null,
    }),
    mounted() {
        this.$bus.$on('onRequestReconnect', this.openPump)
        this.$bus.$on('onRequestDisconnect', this.closePump)
        this.$bus.$on('onSendTextMessage', this.sendTextMessage)
        this.$bus.$on('updateUnread', this.updateUnread)
        this.init()
    },
    beforeDestroy() {
        this.closePump()
        this.$bus.$off('onRequestReconnect', this.openPump)
        this.$bus.$off('onRequestDisconnect', this.closePump)
        this.$bus.$off('onSendTextMessage', this.sendTextMessage)
        this.$bus.$off('updateUnread', this.updateUnread)

        clearInterval(this.pingInterv)
    },
    methods: {
        init() {
            this.openPump()

            if (!this.pingInterv) {
                this.pingInterv = setInterval(_ => {
                    const diff = this.$moment().diff(this.lastPing, 'seconds')

                    if (diff > 10 && !this.connected) {
                        this.closePump()
                    }
                }, 3000)
            }
        },
        subscribe() {
            if (!this.socket || !this.connected) return

            try {
                this.socket.send(
                    JSON.stringify({
                        command: ChatConst.COMMAND_SUBSCRIBE,
                        identifier: this.identifier,
                    })
                )
            } catch (e) {
                this.closePump()
            }
        },
        async closePump() {
            if (!this.socket) return

            try {
                this.socket.close()
            } catch (e) {}

            this.socket = null
            this.connected = false
            this.$loading(false)
        },
        async openPump() {
            if (this.connected) return

            // if (this.socket) {
            //     try {
            //         this.socket.close()
            //     } catch (e) {}
            // }
            this.socket = null

            this.$loading(true)
            const websocketEndpoint = process.env.VUE_APP_CABLE_URL
            const url = websocketEndpoint + `?token=${this.authToken}&from=mobile&role=user`
            this.socket = new WebSocket(url)

            this.socket.onopen = event => {
                this.connected = true
                this.$loading(false)
            }
            this.socket.onerror = event => {
                this.closePump()
            }
            this.socket.onclose = event => {
                this.closePump()
            }
            this.socket.onmessage = event => {
                if (!event.data) return

                this.handleSocketMessage(this.$mustParse(event.data))
            }
        },
        handleSocketMessage(message) {
            if (message.type === ChatConst.MESSAGE_TYPE_WELCOME) {
                this.subscribe()
            }

            if (message.type === ChatConst.MESSAGE_TYPE_CONFIRM_SUBSCRIPTION) {
                // const identifier = this.$mustParse(message.identifier)
            }

            if (message.type === ChatConst.MESSAGE_TYPE_PING) {
                this.lastPing = this.$moment()
                return
            }

            const mtype = (message.message || {}).mtype || ''

            // mtype이 open_chat인 경우 + 알리미의 경우 mtype이 text이기 때문에 역시 들어와야 함
            if (
                mtype.includes(ChatConst.MESSAGE_TYPE_OPEN_CHAT) ||
                mtype.includes(ChatConst.MESSAGE_TYPE_ALIMI) ||
                mtype.includes(ChatConst.MESSAGE_TYPE_PREMIUM_START) ||
                mtype.includes(ChatConst.MESSAGE_TYPE_PREMIUM_CHAT) ||
                mtype.includes(ChatConst.MESSAGE_TYPE_PREMIUM_SALES) ||
                mtype.includes(ChatConst.MESSAGE_TYPE_SOCIAL_INFO)
            ) {
                this.$store.dispatch('handleNewChat', message.message)
            }

            if (ChatConst.SKIP_TYPE.indexOf(message.type) !== -1) return

            this.handleMessage(message)
        },
        sendTextMessage({ text, is_template, premium_start }) {
            const message = {
                command: ChatConst.COMMAND_MESSAGE,
                identifier: this.identifier,
                data: JSON.stringify({
                    content: text,
                    chat_id: this.chat.id,
                    action: ChatConst.MESSAGE_ACTION_MESSAGE,
                    sender_role: 'user',
                    is_template,
                    premium_start,
                }),
            }

            if (!this.isWSOpened()) {
                setTimeout(_ => {
                    this.closePump()
                }, 500)
                return
            }

            this.socket.send(JSON.stringify(message))
        },
        isWSOpened() {
            if (!this.socket) return

            return this.socket.readyState === this.socket.OPEN
        },
        handleMessage(message) {
            if (!message) {
                return
            }

            const noti = message.message
            if (noti.action === ChatConst.MESSAGE_ACTION_BANNED_WORD) {
                this.$toast.error('금지어를 입력하셨습니다')
                return
            }

            if (noti.action === ChatConst.MESSAGE_ACTION_ALERT) {
                this.$modal.basic({
                    title: noti.title,
                    body: noti.content,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }

            if (noti.action === ChatConst.MESSAGE_ACTION_MESSAGE) {
                this.$store.dispatch('addMessage', message)
                const dom = document.getElementsByClassName('messages ver-scroll')[0]
                if (dom) {
                    this.$scroll.down(dom, true)
                }

                if (message.message.chat_id === this.chat.id && this.$route.name === 'ChatroomPage') {
                    this.updateUnread()
                }
                this.$updateAppIconBadgeCount()
            }
        },
        updateUnread() {
            const message = {
                command: ChatConst.COMMAND_MESSAGE,
                identifier: this.identifier,
                data: JSON.stringify({
                    chat_id: this.chat.id,
                    action: ChatConst.MESSAGE_ACTION_UNREAD,
                    sender_role: 'user',
                }),
            }
            this.chat.unread = 0

            try {
                this.socket.send(JSON.stringify(message))
            } catch (e) {
                this.closePump()
            }
        },
    },
}
</script>

<template>
    <div class="career-detail-profile" v-if="profile.company.length">
        <div class="wrapper">
            <!-- <h3 class="title" v-html="getTitle" /> -->
            <h3 class="title">
                <img class="m-r-2" src="@/assets/images/icons/company.png" width="24px" height="24px" />
                <span>직장/직업 사항</span>
            </h3>
            <DetailCareerItem class="list" :userId="userId" :profile="profile" />
        </div>
    </div>
</template>

<script>
import DetailCareerItem from '@/routes/my-page/components/DetailCareerItem'
export default {
    name: 'CareerProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    components: {
        DetailCareerItem,
    },
    computed: {
        getTitle() {
            return `<i class="material-icons">business_center</i> 직장/직업정보`
        },
    },
}
</script>
<style scoped lang="scss"></style>

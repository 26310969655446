<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li
                class="item"
                v-for="item in upper"
                :key="item.key"
                v-show="showContractTab(item.key)"
                @click="onClickMenu(item.route)"
            >
                <i class="material-icons-outlined icon" v-if="item.icon">{item.icon}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyPageMenu',
    props: {
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        upper() {
            return [
                {
                    key: 'BLOCK_FRIEND',
                    route: 'BlockUserPage',
                    show: true,
                },
                // {
                //     key: 'ENTER_INVITE_FRIEND',
                //     route: 'EnterCodePage',
                //     show: true,
                // },
                {
                    key: 'SETTINGS',
                    route: 'SettingsPage',
                },
                {
                    key: this.chats.some(chat => chat.agent_id === 4) ? 'CUSTOMER_CENTER' : 'SUPPORT_MANAGER',
                    route: 'ChatroomPage',
                },
                {
                    key: 'IMPORT_CONTRACT',
                    route: 'MakeContractPage',
                },
                {
                    key: 'IMPORT_TRUTH_CONTRACT',
                    route: 'MakeTruthContractPage',
                },
            ]
        },
        userContract() {
            return (this.$store.getters.me || {}).user_contract
        },
        showContractTab() {
            return key => {
                if (['IMPORT_CONTRACT', 'IMPORT_TRUTH_CONTRACT'].indexOf(key) < 0) return true
                else if (this.userContract) return true
                // else if (key === 'IMPORT_CONTRACT') return true
                // // 진실성 담보 계약서 계약서 버전별로 보여주는것 분기
                // else if (this.userContract.plan_type === 'postpaid') return true
                // else if (this.userContract.plan_type === 'prepaid' && this.userContract.version >= 3) return true
                // else return false
            }
        },
        chats() {
            return (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []
        },
    },
    methods: {
        async onClickMenu(route) {
            if (route === 'CustomerCenterPage') {
                this.$router.push({ name: route })
                return
            }
            if (route === 'ChatroomPage') {
                const chat =
                    this.chats.find(chat => chat.agent_id === 4) || this.chats.find(chat => chat.agent_id === 7)
                const unreadCount = chat.unread
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
                this.$router.push({
                    name: route,
                    params: { unreadCount },
                })
                return
            }

            if (route === 'MakeContractPage') {
                if (this.stepToast && this.stepToast.step <= 1) {
                    this.$toast.success(this.stepToast.msg)
                    return
                }
            }
            this.$stackRouter.push({ name: route })
        },
        onClickBack() {
            console.log('yes')
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    .hr.thick {
        background: $grey-02;
        border: 0;
        height: 8px;
    }

    // .hr {
    //     height: 1px;
    //     background: $grey-01;

    //     &.thick {
    //         height: 8px;
    //     }
    // }

    .item {
        padding: 24px 16px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-bottom: solid 1px $grey-02;
        }

        .icon {
            color: $grey-06;
            margin-right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
        &:nth-child(1) {
            border-bottom: solid 8px rgb(247, 248, 249);
        }
    }
}
</style>

<template>
    <div class="pay-account">
        <div class="grey-box m-b-24">
            <span>총 결제 금액</span>
            <span class="spoqa-f-bold f-18">{{ price.price }}원</span>
        </div>
        <section class="section m-b-40">
            <div class="title">입금자 성함</div>
            <TextareaWithX :placeholder="placeholder.name" v-model="name" :is-input-mode="true" />
        </section>
        <section class="section m-b-40">
            <div class="title">현금영수증 발행</div>
            <div class="toggle-container">
                <img
                    @click="toggle = !toggle"
                    width="32px"
                    height="32px"
                    class="m-r-4"
                    :src="require(`@/assets/images/icons/toggle${toggle ? '' : '-off'}.png`)"
                    alt=""
                />
                <span>현금영수증(소득공제용) 발행</span>
            </div>
        </section>
        <section class="section">
            <div class="title">휴대폰 번호</div>
            <TextareaWithX @focus="onfocus" :placeholder="placeholder.phone" v-model="phone" :is-input-mode="true" />
        </section>
        <BottomButton :disabled="!(me.name && me.phone_number)" :label="$translate('NEXT')" @click="clickNext" />
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'PayByAccount',
    data: () => ({
        name: null,
        phone: null,
        toggle: true,
    }),
    created() {
        this.name = this.me.name
        this.phone = this.me.phone_number
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        price() {
            return this.$store.getters.price
        },
        selectedProduct() {
            return this.$store.getters.selectedProduct
        },
        placeholder() {
            return { name: '입금하시는 분의 성함을 입력해주세요', phone: '숫자만 입력해주세요' }
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
    },
    methods: {
        async clickNext() {
            const alreadyOrdered = JSON.parse(localStorage.getItem('order'))
            if (!alreadyOrdered) {
                if (!(this.name && this.phone)) {
                    alert('성함, 전화번호를 정확히 입력하세요')
                    return
                }
                try {
                    // const { order, user_virtual_account: virtualAccount }
                    console.log(1)
                    const { order, user_virtual_account: virtualAccount } = await productService.createOrder(
                        this.selectedProduct.id,
                        {
                            payment_type: 'withdraw',
                            user_id: this.selectedCoupon ? this.me.id : null,
                            discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                            name: this.name,
                            phone_number: this.phone,
                            agree_cash_bill: this.toggle,
                        }
                    )
                    localStorage.setItem('order', JSON.stringify(order))
                    localStorage.setItem('virtualAccount', JSON.stringify(virtualAccount))
                } catch (e) {
                    this.$toast.error(e.data.msg)
                }
            }
            this.$router.push({
                name: 'AccountPayMethodPage',
            })
        },
        onFocus() {
            const main = document.querySelector('.pay-account')
            setTimeout(() => main.scrollBy({ top: 99999, behavior: 'smooth' }), 400)
        },
    },
}
</script>

<style scoped lang="scss">
.grey-box {
    background: $grey-01;
    padding: 13px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 12px;
    @include between;
}
.pay-account {
    padding: 16px;
    overflow-y: auto;

    .toggle-container {
        display: flex;
        align-items: center;
    }

    .title {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }
}
</style>

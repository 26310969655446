<template>
    <div>
        <div class="user-products" :class="{ expired: productToDisplay.status === 'expired' }">
            <p v-if="productDisplayedInfo.plan_type !== 'postpaid'" class="plan-name">
                {{ productDisplayedInfo.name }}
            </p>
            <!-- <p v-else class="plan-name">{{ productDisplayedInfo.name }}</p> -->
            <div v-if="productDisplayedInfo.plan_type !== 'postpaid'" class="product-info">
                <ProductToDisplayPending
                    v-if="productToDisplay.status === 'pending'"
                    @clickInfo="infoModal"
                    :product-displayed-info="productDisplayedInfo"
                    :product-to-display="productToDisplay"
                />
                <ProductToDisplay
                    v-else
                    @clickInfo="infoModal"
                    :product-displayed-info="productDisplayedInfo"
                    :product-to-display="productToDisplay"
                />
                <!-- <div @click="goStore" class="btn btn-brd" v-if="!isNewUser && !$isTester()">
                    <img
                        class="m-r-4"
                        width="16"
                        height="16"
                        :src="require('@/assets/images/icons/program.png')"
                        alt=""
                    />
                    <span>프로그램 구매</span>
                </div> -->
            </div>
            <div v-else class="product-intro">
                <span>{{ me.name }}님, 프로필을 받고<br />약속을 잡아보세요.</span>
            </div>
            <div class="introduce-day m-t-8" v-if="productDisplayedInfo.plan_type !== 'postpaid'">
                <div>
                    <span>프로필 전달 </span>
                    <span class="parse-intro-date">{{ parseIntroDay }}</span>
                </div>
            </div>
            <div class="noti-expire c-black" v-if="productToDisplay.status === 'expired'">
                <div class="icon-container">
                    <img width="16" height="16" :src="require('@/assets/images/icons/noti_off.png')" alt="" />
                </div>
                프로그램 <span class="m-l-4 spoqa-f-medium">이용이 만료</span>되었습니다.
            </div>
        </div>
        <ProgramInfo
            v-if="showInfo"
            :info-top="infoTop"
            :product-to-display="productToDisplay"
            :product-displayed-info="productDisplayedInfo"
        />
    </div>
</template>

<script>
import ProductToDisplay from '@/routes/chat/chats/components/ProductToDisplay'
import ProductToDisplayPending from '@/routes/chat/chats/components/ProductToDisplayPending'
import ProgramInfo from '@/routes/chat/chats/components/ProgramInfo'

export default {
    name: 'UserProductsInfo',
    props: ['userProducts', 'userRatePlans', 'productToDisplay', 'productDisplayedInfo', 'parseIntroDay'],
    async created() {
        await this.$store.dispatch('getProducts')
    },
    components: { ProductToDisplayPending, ProductToDisplay, ProgramInfo },
    data: () => ({
        showInfo: false,
        infoTop: null,
    }),
    methods: {
        goStore() {
            this.$router.push({ name: 'PrePaymentPage' })
        },
        infoModal(e) {
            const top = `${e.target.offsetHeight} + ${e.target.offsetTop} + ${16}px`
            this.showInfo = !this.showInfo
            this.infoTop = top
        },
    },
    computed: {
        isNewUser() {
            const me = this.$store.getters.me
            return me.products.some(p => p.product_id >= 19)
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>

<style scoped lang="scss">
.user-products {
    margin-bottom: 24px;

    &.expired {
        color: $grey-07 !important;

        ::v-deep .time-left > .used {
            color: $grey-06 !important;
        }
        ::v-deep .time-left {
            color: $grey-04 !important;
        }
        .parse-intro-date {
            color: $grey-09 !important;
        }
    }

    .plan-name {
        font-size: 14px;
        line-height: 24px;
    }
    .product-info {
        @include between;

        .btn {
            @include center;
            height: fit-content !important;
            padding: 4px 12px;
            line-height: 24px;
        }
    }
    .product-intro {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        padding-bottom: 8px;
        @include f-bold;
    }
    .introduce-day {
        font-size: 12px;
        line-height: 20px;
        padding: 4px 8px;
        background: $grey-01;
        border-radius: 100px;
        width: fit-content;
        color: #94999e;

        .parse-intro-date {
            color: black;
        }
    }

    .noti-expire {
        margin-top: 8px;
        padding: 8px 12px;
        background: #fff2f5;
        border: 1px solid #f52c2c;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;

        .icon-container {
            @include center;
            padding: 4px;
            background: white;
            border-radius: 50%;
            margin-right: 4px;
        }
    }
}
</style>

<template>
    <div class="my-page" @scroll="onScroll">
        <div class="top-border" v-if="scrollTop !== 0" />
        <div class="padding" />
        <div class="my-page-basic-profile p-l-0" @click="onClickMyProfile">
            <div class="img-wrapper">
                <div class="img-profile flex-wrap" v-if="mainPhoto" v-img-cover="$store.getters.mainPhoto" />
                <div class="alter-profile flex-wrap" v-else>
                    <img :src="alterPhoto" alt="" />
                </div>
                <!-- <i class="material-icons" v-if="!needEssentialProfile">search</i> -->
            </div>
            <div>
                <div v-if="me.name" class="name-nickname">
                    <span class="name" v-html="me.name" />
                    <span v-if="isTest">({{ me.id }})</span>
                    <i class="material-icons f-18 c-black">chevron_right</i>
                </div>
                <div class="age-height-gender" v-html="ageHeightGender" />
            </div>
        </div>
        <div class="my-programs-wrap">
            <div class="program-label">프로그램</div>
            <div class="my-programs">
                <div v-for="(plan, idx) in userPlansList" :key="idx">
                    {{ plan.name }}
                    <span :class="{ [plan.status]: plan.status }">{{ $translate(plan.status.toUpperCase()) }}</span>
                </div>
            </div>
        </div>
        <EditProfileMenu :stepToast="currentStepToastMessage" />
        <div class="hr" />
        <MyInfoMenu :stepToast="currentStepToastMessage" />
        <!-- <div class="hr" /> -->
        <!-- <EditFavorite /> -->
        <div class="hr thick" />
        <MyPageMenu :stepToast="currentStepToastMessage" />
    </div>
</template>

<script>
import MyPageMenu from './components/MyPageMenu'
import EditProfileMenu from './components/EditProfileMenu'
// import EditFavorite from './components/EditFavorite'
import MyInfoMenu from './components/MyInfoMenu'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'MyPage',
    components: {
        MyPageMenu,
        EditProfileMenu,
        // EditFavorite,
        MyInfoMenu,
    },
    data: () => ({
        cta: `프로필 인증하면 <span class='f-bold'>바닐라 최대 250개</span>`,
        showVerificationBanner: false,
        scrollTop: 0,
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        mainPhoto() {
            return this.$store.getters.mainPhoto || false
        },
        alterPhoto() {
            return require('@/assets/images/person.png')
        },
        profile() {
            return (this.$store.getters.me || {}).profile
        },
        needEssentialProfile() {
            return this.$store.getters.submitAllRequired === false
        },
        profileConfirmed() {
            return (this.me.profile || {}).status !== 'created'
        },
        isVerifiedUser() {
            return (this.$store.getters.myVerificationBadges || []).some(badge => badge.name === '실명인증')
        },
        gender() {
            return this.me.gender
        },
        ageHeightGender() {
            const { profile } = this.me
            if (!profile) return

            const age = this.$options.filters.asAge(profile.birthday)
            // const gender = this.gender === 0 ? '남성' : '여성'

            if (!this.me.profile.height) {
                // return `${age}세<span>ㅣ</span>${gender}`
                return `${age}세<span>`
            }
            // return `${age}세<span class='c-black'>·</span>${profile.height}cm<span class='c-black'>·</span>${gender}`
            return `${age}세<span class='c-black'>·</span>${profile.height}cm`
        },
        isTest() {
            return process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        userPlansList() {
            const plansList = []
            if (
                (this.userRatePlans.expired || {}).length &&
                !this.userRatePlans.onGoing &&
                !(this.userRatePlans.pending || {}).length
            ) {
                const recentExpired = this.userRatePlans.expired.reduce(function (prev, current) {
                    const prevDate = new Date(prev.valid_until)
                    const currentDate = new Date(current.valid_until)
                    return prevDate > currentDate ? prev : current
                })
                const expired = getPlanNameById(recentExpired.product_id)
                Object.assign(expired, { status: 'expired' })
                plansList.push(expired)
            }

            if (this.userRatePlans.onGoing) {
                const onGoing = getPlanNameById(this.userRatePlans.onGoing.product_id)
                Object.assign(onGoing, { status: 'in_use' })
                plansList.push(onGoing)
            }
            if ((this.userRatePlans.pending || {}).length) {
                const pending = this.userRatePlans.pending.map(plan => {
                    return {
                        ...getPlanNameById(plan.product_id),
                        status: 'intent_to_use',
                    }
                })
                plansList.push(...pending)
            }
            return plansList
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
        currentStepToastMessage() {
            // return {step : 2, msg : '먼저 계약서를 작성해주세요'}
            if (!this.me.user_contract) return false
            if (!this.me.user_contract.is_complete)
                return { step: 1, msg: '먼저 계약서를 작성해주세요', title: 'contract' }
            if (
                (((this.userContract || {}).plan_type === 'prepaid' && (this.userContract || {}).version >= 3) ||
                    (this.userContract || {}).plan_type === 'postpaid') &&
                !this.me.user_contract.truth_complete
            )
                return { step: 2, msg: '먼저 진실성 담보 약정서를 작성해주세요', title: 'truth_contract' }
            else if (this.profile.status === 'created')
                return { step: 3, msg: '먼저 프로필을 작성해주세요', title: 'profile' }
            else if (!this.certiSubmitted) return { step: 4, msg: '먼저 서류를 제출해주세요', title: 'certi' }
            else return false
        },
    },
    created() {
        this.$store.dispatch('loadMe')

        if (!this.$store.getters.verificationBadges) {
            this.$store.dispatch('loadVerificationBadges')
            this.$store.dispatch('loadMyVerificationBadges')
        }
    },
    mounted() {
        this.$bus.$emit('setRootHeader', '마이페이지')
    },
    methods: {
        async onClickMyProfile() {
            if (!this.currentStepToastMessage && this.needEssentialProfile) {
                const idx = await this.$modal.basic({
                    body: '먼저 필수 프로필을 입력해 주세요',
                    buttons: [
                        {
                            label: '취소',
                            class: 'btn-default',
                        },
                        {
                            label: '입력하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx) this.$goProfileRegister()
                return
            }

            if (this.currentStepToastMessage && this.currentStepToastMessage.step <= 2) {
                this.$toast.success(this.currentStepToastMessage.msg)
                return
            }

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.me.id,
                },
            })
        },
        async onScroll({ target }) {
            this.scrollTop = target.scrollTop
        },
    },
    beforeDestroy() {
        this.$bus.$emit('setRootHeader', '')
    },
}
</script>

<style lang="scss" scoped>
.my-page {
    .padding {
        height: 8px;
    }

    .top-border {
        position: absolute;
        top: 48px;
        height: 1px;
        width: 100vw;
        background-color: $grey-02;
    }

    .my-page-basic-profile {
        display: flex;
        align-items: center;
        margin: 0 16px;
        padding: 10px 16px;

        .img-wrapper {
            position: relative;
            margin-right: 20px;

            .img-profile {
                width: 80px;
                height: 80px;
                border: solid 1px $grey-03;
                border-radius: 24px;
            }

            .alter-profile {
                @extend .img-profile;
                background: white;
                @include center;
                border-radius: 32px;
                padding: 22px;

                img {
                    width: 28px;
                    height: 28px;
                }
            }

            .material-icons {
                position: absolute;
                border: solid 2px $grey-01;
                border-radius: 50%;
                background-color: $grey-08;
                color: white;
                font-size: 18px;
                bottom: 0;
                right: 0;
            }
        }

        .name-nickname {
            margin-bottom: 4px;
            display: flex;
            align-items: center;
        }

        .name {
            color: black;
            font-size: 18px;

            @include f-medium;
        }

        .nickname {
            margin-left: 4px;
            font-size: 16px;
            color: $grey-08;

            @include f-demilight;
        }

        .age-height-gender {
            font-size: 15px;
            color: $grey-09;

            ::v-deep span {
                color: $grey-04;
            }
        }
    }
    .my-programs-wrap {
        display: flex;
        background: #f7f8f9;
        border-radius: 4px;
        padding: 8px 12px;
        margin: 0 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #666b70;
        .program-label {
            width: 64px;
            margin-right: 8px;
        }
        .my-programs {
            color: #111111;
            div {
                display: flex;
                align-items: center;
            }
            .intent_to_use,
            .expired {
                background: #94999e;
                border-radius: 4px;
                margin: 0px 4px;
                font-size: 10px;
                line-height: 12px;
                color: #ffffff;
                padding: 2px;
            }

            .in_use {
                background: #04c9b2;
                @extend .expired;
            }
        }
    }
    .verify-banner {
        margin: 0 16px 16px 16px;
        padding: 13px 16px;
        height: 48px;
        background-image: linear-gradient(278deg, #ba90ff, $blue-primary);
        border-radius: 12px;

        @include between;

        &.b {
            // display: block;
            background-color: rgba(138, 116, 255, 0.08);
            background-image: none;

            display: block;
            align-items: center;
            justify-content: normal;

            .text {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .achive {
                    float: right;
                    min-width: 150px;
                    text-align: right;
                    .point {
                        // margin-left: 50%;
                        @include spoqa-f-bold;
                    }

                    .arrow {
                        vertical-align: top;
                        color: $blue-primary;
                        margin-right: -6px;
                    }
                }
            }

            .bar {
                height: 4px;
                width: calc(100% + 24px);
                margin-top: 6px;
                margin-left: -12px;
                border-radius: 2px;
                background-image: linear-gradient(271deg, #b475ff, $blue-primary) !important;
            }
        }
    }
}
</style>

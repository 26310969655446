<template>
    <div class="schedule-item">
        <span class="status-badge" :class="schedule.schedule_status.style">
            {{ schedule.schedule_status.label }}
        </span>
        <div class="profile">
            <div class="user-image">
                <img class="active" v-if="schedule.user_status === 'active'" :src="schedule.photo" alt="" />
                <img
                    v-else
                    class="non-active"
                    src="@/assets/images/icons/nonactive_user.png"
                    width="35px"
                    height="35px"
                />
                <div v-if="schedule.schedule_status.style === 'disabled'"></div>
            </div>
            <div
                class="user-info"
                :class="[schedule.schedule_status.style, { disabled: schedule.user_status !== 'active' }]"
            >
                <div class="title-wrap list-comma" v-if="schedule.user_status === 'active'">
                    <div class="user-detail" v-if="schedule.job">
                        {{ schedule.job }}
                    </div>
                    <span v-if="schedule.age"> {{ schedule.age }}세 </span>
                </div>
                <div class="title-wrap" v-if="schedule.user_status !== 'active'">
                    <div class="user-detail">휴면 회원입니다.</div>
                </div>
                <div class="schedule-detail">
                    <div class="label">일시</div>
                    <div class="content">{{ schedule.schedule_date }}</div>
                </div>
                <div class="schedule-detail">
                    <div class="label">지역</div>
                    <div class="content list-comma">
                        <span v-if="schedule.schedule_place">
                            {{ schedule.schedule_place }}
                        </span>
                        <span v-if="schedule.schedule_type">
                            {{ schedule.schedule_type }}
                        </span>
                    </div>
                </div>
                <div class="schedule-detail">
                    <div class="label">장소</div>
                    <div class="content">
                        <span>
                            {{ schedule.restaurant_name }}
                        </span>
                        <div class="place-map">
                            <img
                                :src="require('@/assets/images/icons/location_black.png')"
                                alt=""
                                widtt="16"
                                height="16"
                            />

                            <div @click="showMap">지도보기</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleItem',
    data: () => ({
        disabled: false,
    }),
    props: {
        schedule: {
            type: Object,
        },
    },
    methods: {
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.schedule.restaurant_url,
            })
        },
    },
    computed: {},
}
</script>
<style lang="scss" scoped>
.schedule-item {
    // width: 100%;
    padding: 24px 0;

    .status-badge {
        background: #f7f8f9;
        border-radius: 100px;
        margin: 4px 0px;
        padding: 2px 6px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        flex: none;
        display: inline-block;
        color: #393d44;
        &.primary {
            color: #04c9b2;
            background: #f1fffc;
        }
        &.disabled {
            color: #94999e;
            background: #f7f8f9;
        }
    }
    .profile {
        display: flex;
        align-items: center;
        .user-image {
            width: 80px;
            height: 80px;
            aspect-ratio: 1/1;
            position: relative;
            background: #e9e9ed;
            border-radius: 8px;
            .active {
                border: 1px solid #e9e9ed;
                box-sizing: border-box;
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }
            .non-active {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            div {
                border: 1px solid #e9e9ed;
                box-sizing: border-box;
                border-radius: 8px;
                background: #c4c4c4;
                z-index: 2;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.7;
            }
        }
        .user-info {
            margin-left: 12px;
            color: #111111;
            width: 100%;
            .title-wrap {
                width: 100%;
                display: flex;
                white-space: no-wrap;
                width: 100%;
                align-items: center;
                font-size: 16px;
                line-height: 26px;
                font-weight: 700;
                .user-detail {
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span {
                    display: inline;
                    white-space: pre;
                    word-break: keep-all;
                }
            }
            .schedule-detail {
                display: flex;
                align-items: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
                .label {
                    color: #94999e;
                    margin-right: 8px;
                    word-break: keep-all;
                }
                .content {
                    // span:not(:last-child)::after{
                    //     content :', '
                    // }
                    width: 100%;
                    display: flex;

                    .place-map {
                        margin-left: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2px 4px;
                        min-width: 69px;
                        width: fit-content;
                        height: 24px;
                        padding: 2px 4px;
                        background: #e9e9ed;
                        border-radius: 100px;
                        color: #111;
                    }
                }
            }
            &.disabled {
                color: #94999e;
            }
        }
    }
    .list-comma :not(:last-child)::after {
        content: ', ';
    }
}
.schedule-item:not(:last-child) {
    border-bottom: 1px solid #e9e9ed;
}
</style>

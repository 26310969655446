<template>
    <div>
        <div class="schedule-list" v-if="scheduleInfo.length">
            <div class="process m-b-4">
                <div class="process-title">
                    <img class="m-r-2" src="@/assets/images/icons/promise.png" width="20x" height="20px" />

                    <div>약속 프로세스</div>
                </div>
                <div class="process-desc">
                    <span v-for="(proc, idx) in process" :key="idx">
                        {{ proc }}
                        <img
                            v-if="idx !== process.length - 1"
                            class="m-r-2"
                            src="@/assets/images/icons/arrow_right_mint.png"
                            width="12px"
                            height="12px"
                        />
                    </span>
                </div>
            </div>
            <ScheduleItem v-for="(schedule, idx) in scheduleInfo" :key="idx" :schedule="schedule" />
        </div>
        <div class="schedule-list empty" v-else>
            <img class="m-r-2" src="@/assets/images/icons/img_emptyschedule.png" width="160x" height="160px" />
            <div>
                아직 확정된 약속이 없습니다.<br />
                매니저와 함께 약속을 잡아보세요!
            </div>
        </div>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ScheduleListPage',
    components: {},
    data: () => ({
        scheduleInfo: [],
    }),
    mounted() {
        this.$bus.$emit('setRootHeader', '약속 관리')
        this.getScheduleList()
    },
    computed: {
        process() {
            return ['약속잡기 진행', '약속 확정', '만남 후 피드백', '완료']
        },
        me() {
            return this.$store.getters.me
        },
    },
    methods: {
        async getScheduleList() {
            const res = await scheduleService.scheduleList(this.me.id)
            const schedules = res.data
            const arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            const scheduleInfo = schedules
                .sort((a, b) => (b.created_at > a.created_at ? 1 : -1))
                .map(s => {
                    return {
                        age: this.$options.filters.asAge(s.birthday),
                        job: s.job,
                        schedule_status: this.scheduleStatus(s.schedule.schedule_status, s.schedule.date_confirmed),
                        schedule_place: s.schedule.meet_place || '미정',
                        schedule_date: s.schedule.date_confirmed
                            ? this.$moment(s.schedule.date_confirmed).format('YY/MM/DD') +
                              ' ' +
                              this.$translate(arr[this.$moment(s.schedule.date_confirmed).day() % 7])
                            : '미정',
                        schedule_type: s.schedule.meet_type_confirmed
                            ? this.$translate(s.schedule.meet_type_confirmed.toUpperCase())
                            : null,
                        user_status: !s.enabled && s.hidden ? 'withdrawn' : s.is_dormant ? 'dormant' : 'active',
                        photo: s.urls[0],
                        restaurant_url: s.schedule.restaurant_url,
                        restaurant_name: s.schedule.restaurant_name,
                    }
                })

            this.scheduleInfo = [...scheduleInfo]
        },
        scheduleStatus(status, date) {
            const start = [
                'start_a',
                'entered_date',
                'entered_type',
                'start_b',
                'restart_a',
                'restart_b',
                'entered_place',
                'confirmed_a',
                'confirmed_b',
            ]
            const confirmed = [
                'confirmed_both',
                'make_chat',
                'send_feedback',
                'complete_feedback_b',
                'complete_feedback_a',
            ]
            const canceled = ['break']
            const confirmedDate = this.$moment(date).hours(0).add(1, 'd')
            const finished = this.$moment().isAfter(this.$moment(confirmedDate))
            // console.log(this.$moment(confirmed_date).format('YYYY-MM-DD H:mm'), this.$moment(date).format('YYYY-MM-DD H:mm'), finished)
            const gender = this.me.gender ? 'a' : 'b'
            if (status === 'complete_feedback_both' || status === `complete_feedback_${gender}`) {
                return { label: '완료', style: 'default' }
            } else if (start.indexOf(status) > -1) {
                return { label: '약속잡기 진행', style: 'default' }
            } else if (canceled.indexOf(status) > -1) {
                return { label: '약속 취소', style: 'disabled' }
            } else if (confirmed.indexOf(status) > -1 && finished) {
                return { label: '만남 후 피드백', style: 'default' }
            } else {
                return { label: '약속 확정', style: 'primary' }
            }
        },
    },
    beforeDestroy() {
        this.$bus.$emit('setRootHeader', '')
    },
}
</script>

<style lang="scss" scoped>
.schedule-list {
    padding: 16px;

    .process {
        padding: 8px 12px;
        width: 100%;
        background: #f1fffc;
        border-radius: 12px;
        .process-title {
            display: flex;
            align-items: center;
            // justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #04c9b2;
        }
        .process-desc {
            color: #111111;
            margin: 0px 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
    &.empty {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #b9bdc1;
    }
}
</style>

<template>
    <div class="chats bg-white">
        <Disconnected v-if="!loading" />
        <Loading :loading="loading" />
        <template v-if="!loading">
            <OnboardChatsPage v-if="isOnboarding" :chats="chats" />
            <template v-else>
                <div class="ver-scroll layout" @scroll="onScroll">
                    <UserProductsInfo
                        v-if="$store.getters.products"
                        :user-products="userProducts"
                        :user-rate-plans="userRatePlans"
                        :product-to-display="productToDisplay"
                        :product-displayed-info="productDisplayedInfo"
                        :parse-intro-day="parseIntroDay"
                    />
                    <div ref="ver-scroll" class="flex-fill">
                        <component class="m-b-16" v-for="chat in chats" :is="'ChatItem'" :chat="chat" :key="chat.id" />
                    </div>
                    <div class="empty f-bold" v-if="!chats">
                        <div class="empty-title" v-html="$translate('CHAT_EMPTY')" />
                    </div>
                </div>
            </template>
            <!-- <RepurchaseBanner v-if="showBanner === 'repurchase'" @move-to-productlist="moveToProductList" /> -->
            <ExtendBanner v-if="showBanner === 'extend'" @move-to-productlist="moveToProductList" />
        </template>
    </div>
</template>

<script>
import ChatItem from './components/ChatItem'
import Disconnected from '@/components/common/Disconnected'
import OnboardChatsPage from '@/routes/chat/chats/OnboardChatsPage'
// import RepurchaseBanner from './components/RepurchaseBanner'
import ExtendBanner from './components/ExtendBanner'

import UserProductsInfo from '@/routes/chat/chats/components/UserProductsInfo'
import { getPlanInfo, getPlanNameById, parsePidToPlanName } from '@/store/data/product'
import scheduleService from '@/services/schedule'

export default {
    name: 'ChatsPage',
    components: {
        ChatItem,
        Disconnected,
        OnboardChatsPage,
        // RepurchaseBanner,
        ExtendBanner,
        UserProductsInfo,
    },
    data: () => ({
        scrollTop: 0,
        feedback: {},
    }),
    mounted() {
        this.init()
    },
    computed: {
        profile() {
            return (this.$store.getters.me || {}).profile
        },
        status() {
            return this.$store.getters.profile.status
        },
        loading() {
            return this.$store.getters.loading.chats
        },
        connected() {
            return this.$store.getters.connected
        },
        chats() {
            const chats = (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []

            return chats.filter(chat => ![4, 7].includes(chat.agent_id))
        },
        me() {
            return this.$store.getters.me || {}
        },

        // product_infos
        isAI() {
            const chats = this.$store.getters.chats || []
            return chats.some(c => c.agent_id === 6)
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        userProducts() {
            return this.$store.getters.userProducts || false
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || false
        },
        productToDisplay() {
            if (!this.userRatePlans) return {}

            if (this.userRatePlans.onGoing) {
                return {
                    status: 'on-going',
                    plan_type: parsePidToPlanName(this.userRatePlans.onGoing.product_id),
                    product: this.userRatePlans.onGoing,
                }
            }

            if (this.userRatePlans.pending.length) {
                const pendingLatest = this.userRatePlans.pending[this.userRatePlans.pending.length - 1]
                return {
                    status: 'pending',
                    plan_type: parsePidToPlanName(pendingLatest.product_id),
                    product: pendingLatest,
                }
            }

            const expiredLatest = this.userRatePlans.expired[this.userRatePlans.expired.length - 1]
            return {
                status: 'expired',
                plan_type: parsePidToPlanName(expiredLatest.product_id),
                product: expiredLatest,
            }
        },
        isOnboarding() {
            if (!this.$store.getters.me) return false

            if (
                ((this.$store.getters.me.profile || {}).status === 'created' &&
                    !(this.$store.getters.me || {}).user_contract) ||
                ((this.$store.getters.me.profile || {}).status !== 'confirmed' &&
                    (this.$store.getters.me || {}).user_contract)
            ) {
                return true
            } else {
                return false
            }
        },
        productDisplayedInfo() {
            if (!this.productToDisplay) return false

            const {
                plan_type: pType,
                product: { product_id: pid },
            } = this.productToDisplay

            return getPlanInfo(pType, pid)
        },
        parseIntroDay() {
            const introDay = this.userProducts.introduceDay
            if (!introDay || this.productToDisplay.status === 'pending')
                return this.isAI ? '서류 인증이 완료되면 결정됩니다' : '매니저와 상의 후 결정됩니다'

            const arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            return '매주 ' + this.$translate(arr[introDay % 7]) + '요일'
        },
        showBanner() {
            if (!this.userRatePlans) return false

            if (!(this.userRatePlans.pending || {}).length) {
                if (!this.userRatePlans.onGoing) {
                    // 다시 시작
                    return 'repurchase'
                } else if (
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') <= 7 &&
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') >= 0
                ) {
                    // 갱신하기
                    // 기간제, 횟수제 분기
                    return 'extend'
                } else if (
                    getPlanNameById(this.userRatePlans.onGoing.product_id).amount >= 2 &&
                    this.validTickets.length === 1
                ) {
                    // 횟수제 1회 남았을 때 갱신하기
                    return 'extend'
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        oppositeGender() {
            return this.$store.getters.me.gender ? 'b' : 'a'
        },
        gender() {
            return this.$store.getters.me.gender ? 'a' : 'b'
        },
    },
    watch: {
        '$store.getters.profile.status': {
            handler() {
                this.airbridgeEvent()
                this.$store.dispatch('loadChats', true)
            },
        },
    },
    methods: {
        async checkFeedbackNeeded() {
            const list = await scheduleService.scheduleList(this.me.id)
            const status = [`send_feedback_${this.gender}`, `send_feedback`, `complete_feedback_${this.oppositeGender}`]
            const needFeedback = list.data.some(sch => {
                if (
                    status.indexOf(sch.schedule.schedule_status) > -1 &&
                    this.$moment().isAfter(this.$moment(sch.schedule.date_confirmed).add(1, 'days').set('hour', 0)) &&
                    !sch.my_feedbacks.length &&
                    this.$moment(sch.schedule.date_confirmed).isAfter(this.$moment('2022-04-04'))
                ) {
                    this.feedback.scheduleId = sch.schedule.id
                    this.feedback.targetId = sch.schedule[`${this.oppositeGender}_user_id`]
                    this.feedback.messageId = sch.schedule[`${this.gender}_message_id`]
                    this.feedback.schedule = sch
                    return true
                }
            })

            if (needFeedback) this.openForceFeedbackModal()
        },
        init() {
            this.checkFeedbackNeeded()
            if (this.$store.getters.chat && !this.$route.params.preventSetChatNull) {
                this.$store.commit('setChat', { chat: null })
            }
            // 반려사유 알려주기
            const declinedBadges = (this.$store.getters.myVerificationBadges || []).filter(
                badge => !badge.enabled && badge.rejection_reason
            )
            const showModal = !localStorage.getItem('check-decline-reason')
            if (declinedBadges.length && showModal) {
                localStorage.setItem('check-decline-reason', true)
                this.$modal
                    .custom({
                        component: 'ModalBadgeDeclined',
                        options: { reasons: declinedBadges, hideBottomBtn: false },
                    })
                    .then(res => {
                        if (res === 1) this.$stackRouter.push({ name: 'CertificatePage' })
                    })
            }
            setTimeout(() => {
                this.showRepurchaseModal()
            }, 1000)

            // 첫 로그인때 리워드 모달
            // const showRewardModal = this.me.sign_in_count === 0
            // if (showRewardModal) {
            //     this.showRewardModal()
            // }
            const showRewardModal = !localStorage.getItem('user-logged-in')
            if (showRewardModal && this.me.id >= 4950) {
                this.showRewardModal()
            }
        },
        showRepurchaseModal() {
            // 재구매 안내 모달

            const showRepurchaseModal = localStorage.getItem('check-expire-notice') === 'true' ? false : true
            if (this.showBanner && showRepurchaseModal) {
                localStorage.setItem('check-expire-notice', true)
                this.$modal.custom({
                    component: 'ModalNoticeExpire',
                })
            }
        },

        async showRewardModal() {
            localStorage.setItem('user-logged-in', true)
            const idx = await this.$modal.basic({
                body: '친구로부터 초대를 받으셨나요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                this.$stackRouter.push({ name: 'EnterCodePage' })
            }
        },

        onScroll(event) {
            this.scrollTop.value = event.target.scrollTop
        },
        airbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `complete_profile_${this.$store.getters.me.gender === 0 ? 'male' : 'female'}`,
                },
            })
        },
        moveToProductList() {
            this.$router.push({ name: 'PrePaymentPage' })
        },

        async openForceFeedbackModal() {
            const idx = await this.$modal.custom({
                component: 'ModalForceFeedback',
                options: {
                    preventClose: true,
                    schedule: this.feedback.schedule,
                },
            })
            if (idx === 1) {
                // 매니저에게 문의

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Click_To_Manager',
                    },
                })
                try {
                    const chat = this.chats.find(chat => chat.agent_id && chat.agent_id !== 4)
                    const unreadCount = chat.unread
                    await this.$store.dispatch('loadChat', {
                        chatId: chat.id,
                        force: true,
                    })
                    this.$router.push({
                        name: 'ChatroomPage',
                        params: { unreadCount },
                    })
                    return
                } catch (e) {
                    console.log('send to manager chat', e)
                }
            } else {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Click_Feedback',
                    },
                })
                // 피드백 바로 진행
                const chat = this.chats.find(chat => chat.agent_id !== 4)
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
                this.$stackRouter.push({
                    name: 'MeetingFeedback',
                    props: {
                        person: this.feedback.schedule.name,
                        message: { id: this.feedback.messageId },
                        scheduleId: this.feedback.scheduleId,
                        targetId: this.feedback.targetId,
                    },
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.chats {
    position: relative;
    .title {
        @include spoqa-f-bold;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
    }
    .layout {
        height: 100%;
        padding: 16px;
        @include flex;

        .top-border {
            position: absolute;
            top: 48px;
            height: 1px;
            width: 100vw;
            background-color: $grey-02;
        }
    }

    .empty {
        margin: 24px;
        text-align: center;

        img {
            width: 40px;
            margin-bottom: 16px;
        }
    }

    .chats-tab {
        .count {
            margin-top: -2px;
        }
    }

    .chat-title {
        margin: 0 16px 16px;
        font-size: 24px;
        color: black;
        line-height: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
    }
}
</style>

<template>
    <div class="user-detail-photos">
        <Carousel
            :per-page="1"
            :pagination-position="'bottom-overlay'"
            :pagination-size="8"
            :pagination-padding="4"
            :pagination-active-color="'#FFFFFF'"
            :pagination-color="'#808080'"
            :navigateTo="[initialPage, true]"
            class="carousel"
        >
            <Slide class="p-relative" :key="photo.id" v-for="photo in photos">
                <div class="overlay top"></div>
                <div
                    class="img-cover"
                    style="position: relative"
                    v-img-cover="photo.url.replace('/medium/', '/large/')"
                >
                    <div class="watermark">
                        <div class="triangle">
                            <div class="one m-b-32">
                                {{ phoneNumber }}
                            </div>
                            <div class="two">
                                <div class="m-r-40">
                                    {{ phoneNumber }}
                                </div>
                                <div>
                                    {{ phoneNumber }}
                                </div>
                            </div>
                        </div>
                        <div class="triangle">
                            <div class="two m-b-32">
                                <div class="m-r-40">
                                    {{ phoneNumber }}
                                </div>
                                <div>
                                    {{ phoneNumber }}
                                </div>
                            </div>
                            <div class="one" style="">
                                {{ phoneNumber }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay bottom"></div>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
export default {
    name: 'UserDetailPhotos',
    props: {
        photos: Array,
    },
    computed: {
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
    },
    data: () => ({
        initialPage: 0,
    }),
}
</script>

<style lang="scss" scoped>
.watermark {
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    font-size: 14px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(-45deg);

    .triangle {
        display: flex;
        flex-direction: column;

        .one {
            display: flex;
            justify-content: center;
        }

        .two {
            display: flex;
            justify-content: center;
        }
    }
}
.user-detail-photos {
    position: relative;

    .VueCarousel {
        margin-bottom: 8px;

        .overlay {
            height: $header-height;

            &.top {
                top: 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
            }

            &.bottom {
                top: initial;
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 95%);
            }
        }

        .img-cover {
            width: 100%;
            //height: 375px;
            padding-top: 100%;
        }

        ::v-deep .VueCarousel-pagination {
            display: initial;
            // bottom: 8px;
            margin-bottom: 16px;
        }

        ::v-deep .VueCarousel-dot {
            display: initial;
            width: 24px !important;
            height: 2px !important;
            border-radius: 100px !important;
            background-color: #ffffff !important;
            opacity: 0.5 !important;
        }
        ::v-deep .VueCarousel-dot--active {
            display: initial;
            width: 24px !important;
            height: 2px !important;
            border-radius: 100px !important;
            background-color: #ffffff !important;
            opacity: 1 !important;
        }
    }
}
</style>

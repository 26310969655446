<template>
    <div class="chat-item" v-if="chat">
        <div class="chat-container">
            <ChatItemPhoto class="center flex-wrap m-r-12" :src="chatUserPhoto" @clickPhoto="onClickAgentPhoto" />

            <div class="content-wrapper" @click="onClickChatItem">
                <div class="info">
                    <div class="name-and-badge">
                        <div class="name f-medium">
                            {{ chatName }}
                        </div>
                        <span v-html="timeLeft" />
                    </div>
                    <div class="last-message lines-2 m-t-8" v-html="lastMessage" />
                </div>
                <div class="right flex-wrap">
                    <div class="column">
                        <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                            {{ lastMessageAt }}
                        </div>
                        <div v-if="chat.unread > 0" class="badge badge-count flex-wrap m-t-12">
                            {{ chat.unread }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatItemPhoto from './ChatItemPhoto'

export default {
    name: 'ChatItem',
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    components: { ChatItemPhoto },
    computed: {
        isAlimi() {
            return this.chat.user && !this.chat.user.id
        },
        isDateCoach() {
            return this.chat.is_coaching
        },
        isRequestedDating() {
            return !this.chat.user
        },
        lastMessage() {
            const lastMessage = this.chat.last_message

            if (!lastMessage) return

            switch (lastMessage.mtype) {
                case 'text':
                    return lastMessage.content
                case 'open-chat':
                    if (this.chat.user.id === 2) return this.$translate('ACTION_FAQ_OPEN_CHAT_NORMAL_BODY')
                    if (this.chat.agent_id === 6) return this.$translate('ACTION_OPEN_CHAT_AI_BODY')
                    if (this.chat.agent_id === 8) {
                        return `이번에 회원님의 프로필과 서류 심사를 담당하게 된 심사팀 유나영입니다.`
                    } else return this.$translate('ACTION_OPEN_CHAT_NORMAL_BODY')
                case 'premium-counsel':
                    return lastMessage.content
                case 'alimi-basic':
                    return (
                        (this.$mustParse(lastMessage.content) || {}).title ||
                        (this.$mustParse(lastMessage.content) || {}).msg
                    )
                case 'photo':
                    return this.$translate('PHOTO')
                default:
                    return (this.$mustParse(lastMessage.content) || {}).msg
            }
        },
        lastMessageAt() {
            return this.$options.filters.formatDate((this.chat.last_message || {}).created_at, 'chatList') || 'NONE'
        },
        isInstant() {
            return !!this.chat.expire_at
        },
        isPersonalAgent() {
            return !(this.isRequestedDating || this.chat.expire_at || this.isAlimi)
        },
        agentId() {
            return this.chat.agent_id
        },
        chatUserPhoto() {
            const blank = require('@/assets/images/logo_symbol.png')

            if (['pro_agent', 'users'].includes(this.chat.chat_type)) return (this.chat.user || {}).photo_url || blank
            else if (this.chat.chat_type === 'alimi') return blank
            else return ''
        },
        chatName() {
            if (['pro_agent'].includes(this.chat.chat_type)) {
                return (this.chat.user || {}).nickname
            } else if (['users'].includes(this.chat.chat_type)) {
                return (this.chat.user || {}).name
            } else if (['alimi'].includes(this.chat.chat_type)) {
                return this.$translate('SKYMARRY_SUPPORT')
            }
            return ''
        },
        // 임시 챗방일 경우에는 남은 시간을 일 / 시간으로 보여준다.
        timeLeft() {
            const days = this.$moment(this.chat.expire_at).diff(this.$moment(), 'days')
            const hours = this.$moment(this.chat.expire_at).diff(this.$moment(), 'hours')
            if (this.isInstant) {
                return days >= 1
                    ? `<span class="instant-friend-days f-medium flex-wrap">D-${days}</span>`
                    : `<span class="instant-friend instant-friend-hours f-medium flex-wrap">${hours}시간 남음</span>`
            }
            return ''
        },
    },
    methods: {
        async onClickChatItem(e) {
            // 매니저가 링크 전송 시 챗아이템 클릭해서 브라우저 열리지 않도록
            e.preventDefault()

            try {
                this.$loading(true)
                const unreadCount = this.chat.unread
                await this.$store.dispatch('loadChat', {
                    chatId: this.chat.id,
                    force: true,
                })

                this.$router.push({
                    name: 'ChatroomPage',
                    params: { unreadCount },
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async onClickChatItemProfileImage(event) {
            event.stopPropagation()
            const photoUrls = await this.$store.dispatch('loadUserImages', this.chat.user.id)
            if (!photoUrls) return

            this.$modal.images({
                images: photoUrls,
            })
        },
        onClickAgentPhoto() {
            if (process.env.NODE_ENV === 'test') {
                this.$router.push({ name: 'HeightPage' })
            } else {
                this.$modal.custom({
                    component: 'ModalImages',
                    options: {
                        // images: [this.chat.user.photo_url],
                        images: [this.chat.user.large_photo_url],
                        selectedIndex: 1,
                    },
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-item {
    border: 1px solid $grey-03;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(148, 148, 152, 0.12);
    $photo-width: 56px;
    user-select: none;
    padding: 16px;

    ::v-deep .instant-friend-hours {
        font-family: SpoqaHanSansNeo;
        padding: 0 4px;
        border-radius: 4px;
        background-color: #070a0e;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        margin-left: 8px;
        font-size: 12px;
        padding: 0 6px;
        line-height: 1.67;
    }
    ::v-deep .instant-friend-days {
        @extend .instant-friend-hours;
        background-color: #e9e9ed;
        color: #111;
    }

    .chat-container {
        display: flex;
        width: 100%;

        .content-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // width: calc(100vw - 32px - 70px - 50px);

            .name-and-badge {
                display: flex;
                align-items: center;
                line-height: normal;
                min-width: 0;
            }
            .name {
                color: black;
                font-size: 15px;

                @include lines-1;
            }

            .personal-agent,
            .instant-friend,
            .real-friend,
            .group-chat {
                height: 20px;
                white-space: nowrap;
                flex-shrink: 0;

                @include center;
            }

            .personal-agent {
                margin-left: 8px;
                padding: 0 6px;
                font-size: 12px;
                border-radius: 6px;
                border: solid 1px $grey-04;
                color: $grey-07;
            }

            .premium-agent {
                margin-left: 8px;
                padding: 0 6px;
                font-size: 12px;
                color: $yellow-premium;
                border-radius: 6px;
                border: solid 1px $yellow-premium;
            }

            .instant-friend {
                margin-left: 8px;
                background-color: $grey-02;
                border-radius: 6px;
                font-size: 12px;
                color: $grey-08;
                padding: 0 6px;
                line-height: normal;

                @include f-medium;
            }

            .real-friend {
                margin-left: 8px;
                padding: 0 6px;
                font-size: 11px;
                color: $blue-primary;
                border: solid 1px $blue-primary;
                border-radius: 6px;

                @include f-medium;
            }

            .group-chat {
                margin-left: 8px;
                font-size: 12px;
                color: $grey-06;

                @include f-medium;
            }
        }

        .age-region {
            font-size: 11px;
            margin-top: 2px;
            color: $grey-07;
        }

        .last-message {
            font-size: 12px;
            line-height: 16px;
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .column {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50px;
            }

            .timestamp {
                font-size: 11px;
                letter-spacing: -0.4px;
                color: $grey-05;
                text-align: right;
                display: table;
                margin-top: 2px;
            }

            .badge-count {
                height: 18px;
                min-width: 18px;
                border-radius: 9px;
                background: $blue-primary;
                padding: 0 5px;
            }
        }

        &.selected {
            background: rgba(0, 0, 0, 0.06);
        }
    }
}
</style>

<template>
    <div class="chat-header-main">
        <nav class="chat-header-bar" :class="{ instant: isInstantChat, secret: $isPrivacy() }">
            <button class="left-btn" @click="onClickLeftBtn">
                <img class="m-r-2" :src="require(`@/assets/images/icons/back.png`)" width="24px" height="24px" />
            </button>

            <div class="title-wrapper">
                <img
                    class="premium-agent-inner flex-wrap m-r-8"
                    :src="user.photo_url || require('@/assets/images/logo_symbol.png')"
                />
                <h1 class="title" :class="{ flexbox: isInstantChat }" v-html="title" />
            </div>

            <div class="right-btn" @click="onClickRightBtn" v-if="agentId === 6">
                <img class="m-r-2" src="@/assets/images/icons/questionmark.png" width="20px" height="20px" />
                <span>문의</span>
            </div>
            <div class="right-btn" @click="onClickBlock" v-if="$isTester() && agentId !== 6">
                <span>차단하기</span>
            </div>
            <!-- <SpecialFeats v-if="!isInstantChat && agentId === me.my_manager" /> -->
        </nav>
        <p
            v-if="chat.agent_id && ![4, 7, 8].includes(chat.agent_id) && parseIntroDay"
            class="text-center intro-day"
            v-html="parseIntroDay"
        />
    </div>
</template>

<script>
import chatService from '@/services/chat'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ChatHeaderBar',
    data: () => ({
        showInstantChatInfo: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        user() {
            return this.chat.user || {}
        },
        agentId() {
            return this.chat.agent_id
        },
        title() {
            if (!this.chat || (!this.chat.user && !this.chat.users)) return

            if (this.chat.chat_type === 'alimi') {
                return `SKY Marry`
            } else if (this.isInstantChat) {
                return this.timeLeft.day
                    ? `${this.chat.user.name} <span class="expire-badge expire-badge-days">D-${this.timeLeft.time}</span>`
                    : `${this.chat.user.name} <span class="expire-badge expire-badge-hours">${this.timeLeft.time}시간 남음</span>`
            }

            return `${this.chat.user.nickname}`
        },
        isInstantChat() {
            return !!this.chat.expire_at
        },
        timeLeft() {
            const days = this.$moment(this.chat.expire_at).diff(this.$moment(), 'days')
            if (days >= 1) {
                return { day: true, time: days }
            } else {
                const hours = this.$moment(this.chat.expire_at).diff(this.$moment(), 'hours')
                return { day: false, time: hours }
            }
        },
        parseIntroDay() {
            const introDay = this.me.introducing_day
            const userRatePlans = this.$store.getters.userRatePlans

            if (!introDay || !userRatePlans.onGoing) return '<span class="c-grey-05">프로필 전달 요일 미지정</span>'

            // * --후불제의 경우 요일 보여 주지 않기--
            const { product_id } = userRatePlans.onGoing

            if (getPlanNameById(product_id).plan_type === 'postpaid') {
                return false
            }
            // --후불제의 경우 요일 보여 주지 않기-- *
            const arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            return '매주 ' + this.$translate(arr[introDay % 7]) + '요일 프로필 전달'
        },
    },
    methods: {
        onClickLeftBtn() {
            // this.$router.go(-1)
            this.$router.push({ name: 'ChatsPage' })
        },
        async onClickBlock() {
            if (!this.$isTester()) return

            const idx = await this.$modal.basic({
                body: '해당 유저를 차단합니다',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                const res = await chatService.block(this.$store.getters.chat.id)
                await this.$store.dispatch('loadChats', true)
                if (res) {
                    this.$toast.success(res.msg)
                    this.$router.push({ name: 'HomePage' })
                }
            }
        },
        async onClickRightBtn() {
            const chat = this.$store.getters.chats.find(chat => chat.agent_id === 7 || chat.agent_id === 4)
            try {
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
            } catch (e) {
                console.log(e)
            }
        },
        openChatMenu() {
            this.$actionSheet({
                buttons: [
                    {
                        label: 'REPORT_AGENTS',
                        class: 'c-red',
                        handler: () => {
                            const type = 'agent'
                            const target = this.$store.getters.chat.user
                            target.agent_id = this.$store.getters.chat.agent_id

                            this.$stackRouter.push({
                                name: 'ReportSelectReasonPage',
                                props: { type, target },
                            })
                        },
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-header-main {
    background-color: white;

    .intro-day {
        font-size: 12px;
        padding-bottom: 12px;
        color: $blue-primary;
        @include spoqa-f-medium;
    }
}
.chat-header-bar {
    $chat-header-height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $chat-header-height;

    &.secret {
        background: $grey-10;
        border-bottom: 1px solid #666b70 !important;
        color: white !important;

        i {
            color: white !important;
        }
        .title-wrapper > h1 {
            color: white !important;
        }
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        max-width: 60%;
    }

    .qna {
        display: flex;
        align-items: center;
    }

    .title {
        color: black;
        font-size: 16px;
        font-weight: 500;

        @include lines-1;
        @include f-medium;

        &.flexbox {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
        }
    }
    .premium-agent-inner {
        width: 32px;
        height: 32px;
        border-radius: 12.8px;
    }

    .premium-agent {
        height: 20px;
        margin-left: 8px;
        padding: 1px 6px;
        font-size: 12px;
        color: $yellow-premium;
        border-radius: 6px;
        border: solid 1px $yellow-premium;

        @include center;
    }

    button {
        border: 0;
        background: 0;
        padding: 0;
    }

    .left-btn,
    .right-btn {
        width: calc(#{$chat-header-height} + 16px);
        height: $chat-header-height;

        @include center;

        .material-icons {
            color: $grey-08;
        }
    }

    .left-btn {
        position: absolute;
        height: $chat-header-height;
        left: 0;
    }

    .right-btn {
        position: absolute;
        right: 16px;
    }

    &.instant {
        ::v-deep .expire-badge-hours {
            font-family: SpoqaHanSansNeo;
            margin-left: 8px;
            font-size: 12px;
            padding: 0 4px;
            border-radius: 4px;
            color: #fff;
            background-color: #070a0e;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            @include f-medium;
            @include center;
        }
        ::v-deep .expire-badge-days {
            font-family: SpoqaHanSansNeo;
            margin-left: 8px;
            font-size: 12px;
            padding: 0 4px;
            border-radius: 4px;
            color: #111;
            background-color: #e9e9ed;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            @include f-medium;
            @include center;
        }
    }
}
</style>

<template>
    <div class="event-expire-banner">
        <div class="banner-title">
            {{ title }}
        </div>
        <!-- <div class="text-btn" @click="onClickExtend" v-if="!isNewUser">
            {{ buttonLabel }}
            <img :src="require(`@/assets/images/icons/arrow_right_white.svg`)" width="20px" height="20px" />
        </div> -->
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ChatExtendBanner',
    components: {},
    computed: {
        onGoingPlanName() {
            return getPlanNameById(this.userRatePlans.onGoing.product_id).name
        },
        title() {
            return `${this.onGoingPlanName} 프로그램이 곧 만료됩니다! `
        },
        buttonLabel() {
            return '갱신하기'
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        isNewUser() {
            const me = this.$store.getters.me
            return me.products.some(p => p.product_id >= 19)
        },
    },
    methods: {
        onClickExtend() {
            this.$emit('move-to-productlist')
        },
    },
}
</script>

<style scoped lang="scss">
.event-expire-banner {
    position: static;
    background: linear-gradient(93.48deg, #ff5c5a 42.35%, #ff9417 93.19%);
    height: 48px;
    .text-btn {
        display: flex;
        align-items: center;
    }
}
</style>

<template>
    <div v-if="keywords.length > 0" class="keywords">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/tag.png" width="24px" height="24px" />
            <span>취향태그</span>
        </h3>
        <ul class="list" v-if="userKeywords">
            <li class="item" v-for="(tags, categoryId) in userKeywords" :key="categoryId">
                <div class="key">{{ categoryIdToString(categoryId) }}</div>
                <div class="tags">
                    <span class="tag" v-for="tag in tags" :key="tag">{{ tag }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Keywords',
    props: {
        keywords: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        keywordCategories: [],
        userKeywords: {},
    }),
    watch: {
        keywords() {
            this.initKeywords()
        },
    },
    computed: {
        getTitle() {
            return `<i class="material-icons cyan-text">tag</i> 취향태그`
        },
    },
    methods: {
        initKeywords() {
            this.keywordCategories = this.$store.getters.keywordCategories || []
            this.keywords.forEach(keyword => {
                if (this.userKeywords[keyword.keyword_category_id]) {
                    this.userKeywords[keyword.keyword_category_id].push(keyword.name)
                } else {
                    this.$set(this.userKeywords, keyword.keyword_category_id, [keyword.name])
                }
            })
        },
        categoryIdToString(id) {
            const numId = Number(id)
            const matched = this.keywordCategories.find(c => c.id === numId)

            if (!matched) return

            return matched.title
        },
    },
    mounted() {
        this.initKeywords()
    },
}
</script>

<style lang="scss" scoped>
.keywords {
    border: none;
    .title::v-deep {
        margin-bottom: 24px;
        color: black;
        font-weight: 500;
        display: flex;
        align-items: center;
        @include f-medium;
        .material-icons {
            color: black;
        }
    }

    .item {
        margin-bottom: 24px;
    }

    .key {
        margin-bottom: 12px;
        font-size: 14px;
        color: $grey-07;

        @include f-medium;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;

        .tag {
            margin: 0 8px 8px 0;
            padding: 6px 12px;
            border-radius: 8px;
            background-color: $grey-01;
            color: black;
            font-size: 14px;
        }
    }
}
</style>

<template>
    <div class="on-board">
        <div class="header-onboard">
            <div class="skip" @click="clickNext" v-html="$translate('SKIP')" />
        </div>
        <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide v-for="idx in [1, 2, 3]" :key="idx">
                <component :is="`OnBoard${idx}`" />
            </swiper-slide>
        </swiper>
        <div class="pagination">
            <div
                class="pagination-item"
                :class="{ selected: idx === curIdx }"
                v-for="idx in [0, 1, 2]"
                :key="`pg-${idx}`"
                @click.stop="clickPagination(idx)"
            />
        </div>
        <BottomButton @click="clickNext" v-if="curIdx === 2" :label="$translate('START')" />
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'OnBoardingPage',
    data: () => ({
        curIdx: 0,
    }),
    components: {
        swiper,
        swiperSlide,
        OnBoard1: () => import('./components/OnBoard1'),
        OnBoard2: () => import('./components/OnBoard2'),
        OnBoard3: () => import('./components/OnBoard3'),
    },
    methods: {
        clickPagination(idx) {
            this.curIdx = idx
            this.swiperComponent.slideTo(idx, 1000, false)
        },
        clickNext() {
            this.$router.push({ name: 'FrontPage' })
        },
    },
    computed: {
        swiperComponent() {
            return this.$refs.mySwiper.swiper
        },
        swiperOptions() {
            return {
                onSlideChange: function (e) {
                    console.log(e)
                },
            }
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: 'white',
                bottom: 'white',
            },
        })
        this.swiperComponent.on('slideChange', e => {
            this.curIdx = this.swiperComponent.activeIndex
        })
    },
}
</script>

<style scoped lang="scss">
.pagination {
    margin-top: 32px;
    touch-action: none;
    @include center;

    .pagination-item {
        width: 32px;
        height: 2px;
        background: $grey-04;
        margin-right: 4px;

        &.selected {
            background: black;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
.header-onboard {
    height: $header-height;
    padding: 16px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 24px;
}
.on-board {
    padding-bottom: 16px;
}
::v-deep .swiper-container {
    .swiper-slide {
        touch-action: none;
    }
}
::v-deep .onboard-img-container {
    width: 100%;
    color: black;

    .img-wrapper {
        @include center;
        img {
            height: 300px;
            width: auto;
        }
    }

    .content {
        text-align: center;

        .title {
            font-size: 18px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .desc {
            font-size: 14px;
            line-height: 26px;
        }
    }
}
::v-deep button {
    background: black;
}
</style>

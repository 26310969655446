<template>
    <div class="signup verification white">
        <Loading :loading="loading" :animation="'dot-falling'" />
        <div class="content after">
            <div class="title-bold f-bold">
                마지막으로<br />
                <div class="title f-medium">본인 신원 인증이 필요합니다</div>
            </div>
            <div class="body">
                <div class="item">
                    <span class="f-regular" v-html="'서로 신뢰할 수 있는 만남을 위해<br>모든 회원은 '" />
                    <span class="f-bold verification-color" v-html="'정확한 실명, 나이, 연락처'" />
                    <span class="f-regular" v-html="'를<br>인증해야 합니다.'" />
                </div>
            </div>
        </div>
        <div v-if="!loading" class="button-wrapper white">
            <button class="btn btn-primary" @click="next">인증하고 시작하기</button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'VerificationPage',
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                this.finish(newVal)
            },
            deep: true,
        },
    },
    data: () => ({
        loading: false,
        signupFinished: false,
        terms: null,
    }),
    mounted() {
        this.$registerBackHandler(() => {})

        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'VerificationPage_Enter_VerificationPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
    methods: {
        next() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'VerificationPage_Click_NextBtn',
                    option: {
                        provider: this.$store.getters.payloads.signupData.provider || 'email',
                    },
                },
            })
            // 한 번 인증 받아서 인증정보가 스토어에 남아있으면 그냥 진행 다시시도 (사실 이런 경우 자체가 생기면 안되긴 함)
            if (this.$store.getters.niceData) return this.finish(this.$store.getters.niceData)

            this.$nativeBridge.postMessage({ action: 'verification' })
        },
        finish(niceData) {
            const signupData = this.$store.getters.payloads.signupData || {}
            if (this.checkNiceData(signupData, niceData)) {
                signupData.response_id = niceData.response_id
                this.$store.commit('setPayloads', { signupData })

                this.createUser(signupData)
            }
        },
        checkNiceData(signupData, niceData) {
            const checkStatus = status => {
                let errMsg = ''
                if (status === 'too_young')
                    errMsg = '바닐라브릿지에서는 미성년자를 위한 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.'
                if (status === 'duplicated') errMsg = '이미 가입된 핸드폰 번호입니다.'
                if (status === 'fail') errMsg = '본인 인증을 다시 시도해주세요.'

                if (errMsg) {
                    this.$toast.error(errMsg)
                    return false
                }
                return true
            }

            const checkGender = niceGender => {
                const liar =
                    (niceGender === '1' && signupData.gender.name === 'FEMALE') ||
                    (niceGender === '0' && signupData.gender.name === 'MALE')
                if (liar)
                    this.$toast.error('선택한 성별이 본인 인증을 통한 성별과 다릅니다. 처음부터 다시 진행해주세요.')
                return !liar
            }

            return checkStatus(niceData.status) && checkGender(niceData.gender)
        },
        async createUser(signupData) {
            try {
                this.loading = true

                await this.initTerms()
                const payload = this.preparedPayload(signupData)
                const { user } = await userService.create(payload)

                // 사진 업로드하는 시간을 고려하여 사진은 별도로 업로드
                userService.uploadPhotos(user.id, this.photosPayload(signupData.photos, user.token))

                // 회원 생성 시점에 가입 완료로 간주, 이벤트 전송
                const { provider, gender } = this.$store.getters.payloads.signupData

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Complete_Signup',
                        option: {
                            provider: provider || 'email',
                            gender: gender.name === 'MALE' ? 0 : 1,
                        },
                    },
                })

                if (signupData.provider.match(/facebook|apple|kakao/g)) {
                    await this.$store.dispatch('signInThirdParty', {
                        user,
                        uid: signupData.uid,
                        noRedirect: true,
                    })
                } else {
                    await this.$store.dispatch('signIn', {
                        email: signupData.email,
                        password: signupData.password,
                        noRedirect: true,
                    })
                }
                this.signupFinished = true
                this.sendAirbridgeEvent(signupData)

                this.$router.push({ name: 'PreferencesIntroPage' })
            } catch (e) {
                if (e.data.key === 'auth_validation_expired') {
                    this.$store.commit('setNiceData', null)
                }
                if (e.data.key === 'blocked_user') {
                    this.$modal.basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$toast.error(e.data)
                }
            } finally {
                this.loading = false
            }
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (key === 'smoking') {
                    formData.append(key, value.name === 'SMOKING' ? '1' : '0')
                } else if (['job', 'company', 'university'].indexOf(key) !== -1) {
                    formData.append(`${key}_id`, value.id)
                    formData.append(`${key}_name`, value.name)
                } else if (
                    ['state', 'station', 'region', 'job_category', 'school', 'school_type'].indexOf(key) !== -1
                ) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${key}_id`, value.id)
                } else if (key === 'profile_option_ids') {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })

            formData.append('service_terms_version', this.terms.service.version)
            formData.append('privacy_terms_version', this.terms.privacy.version)
            formData.append('privacy_third_terms_version', this.terms.privacy_third.version)
            formData.append('optional_consent', 0)

            return formData
        },
        photosPayload(photos = [], token) {
            if (photos.length === 0 || !token) return

            const formData = new FormData()

            photos.forEach((photo, i) => {
                formData.append(`photo${i + 1}`, photo.blob, photo.name)
            })

            formData.append('token', token)

            return formData
        },
        sendAirbridgeEvent({ gender }) {
            if (!gender) return

            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `user_signup_complete_${gender.name.toLowerCase()}`,
                },
            })
        },
    },
}
</script>

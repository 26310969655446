<template>
    <div class="basic-profile" v-if="financialProfile.length">
        <!-- <h3 class="title" v-html="`Financial`" /> -->
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/money.png" width="24px" height="24px" />
            <span>소득사항</span>
        </h3>
        <div style="z-index: 2" class="items" v-for="item in financialProfile" :key="item.id">
            <div class="item" v-if="item.value">
                <template>
                    <div class="key" v-html="$translate(item.title.toUpperCase())" />
                    <div class="value">
                        <div class="content">
                            <span
                                >{{ item.value
                                }}<span v-if="item.selective && showBadge(item)" class="manager-only"
                                    >매니저만</span
                                ></span
                            >
                            <span v-if="item.additional && showHouseOwner" class="content"
                                >, {{ item.additional
                                }}<span v-if="showBadge(item)" class="manager-only">매니저만</span></span
                            >
                        </div>
                        <div v-if="item.description" class="asset-detail">{{ item.description }}</div>
                    </div>
                </template>
            </div>
        </div>
        <div class="key m-b-12" v-html="`재테크`" v-if="profile.finance" />
        <SelfIntroduction v-if="profile.finance" :intro="profile.finance" />
    </div>
</template>

<script>
// 숨은 정보 연소득, 보유자산, 소유자명의
import SelfIntroduction from '@/routes/user-detail/components/SelfIntroduction'

export default {
    name: 'FinancialProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    components: {
        SelfIntroduction,
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getMoneyRange() {
            if (!this.isMe && !this.profile.privacy_setting.show_income) return null
            if (this.profile.privacy_setting.show_income > 1) {
                return this.$profile.moneyRangeParsed(this.profile.income)
            } else {
                if (!this.profile.income) return '소득없음'
                return this.$krwParsed(this.profile.income)
            }
        },
        getAssetRange() {
            if (!this.isMe && !this.profile.privacy_setting.show_asset) return null
            if (this.profile.privacy_setting.show_asset > 1) {
                return this.$profile.assetRangeParsed(this.profile.asset)
            } else {
                if (!this.profile.asset) return '자산없음'
                return this.$krwParsed(this.profile.asset)
            }
        },
        showHouseOwner() {
            if (this.isMe) return true
            if (this.profile.privacy_setting.show_house_owner) {
                return true
            }
            return false
        },
        financialProfile() {
            const items = [
                {
                    id: 1,
                    key: 'income',
                    title: 'income',
                    value: this.getMoneyRange,
                    selective: true,
                },
                {
                    id: 2,
                    key: 'asset',
                    title: 'asset',
                    value: this.getAssetRange,
                    description: this.profile.asset_detail,
                    selective: true,
                },
                {
                    id: 3,
                    key: 'house_style',
                    title: 'house_style',
                    value: this.profile.house_style,
                },
                {
                    id: 4,
                    title: 'house_owner',
                    key: 'house_owner',
                    value: this.profile.house_payment,
                    additional: this.profile.house_owner
                        ? this.profile.house_owner === 2
                            ? '형제소유'
                            : '부모님소유'
                        : '본인소유',
                },
                {
                    id: 5,
                    title: 'car_brand',
                    key: 'car_type',
                    value: this.profile.car_type
                        ? this.profile.car_type === '없음'
                            ? `${this.profile.car_type}`
                            : `${this.profile.car_type}(${this.profile.car_model})`
                        : null,
                    selective: true,
                },
            ]
            return items.filter(item => item.value)
        },
    },
    methods: {
        showBadge(item) {
            if (!this.isMe) return false
            return !this.profile.privacy_setting[`show_${item.key}`]
        },
    },
}
</script>
<style scoped lang="scss">
.asset-detail {
    margin-top: 3px;
}
</style>

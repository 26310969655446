import { render, staticRenderFns } from "./WaitlistPage.vue?vue&type=template&id=3b2b6858&scoped=true&"
import script from "./WaitlistPage.vue?vue&type=script&lang=js&"
export * from "./WaitlistPage.vue?vue&type=script&lang=js&"
import style0 from "./WaitlistPage.vue?vue&type=style&index=0&id=3b2b6858&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2b6858",
  null
  
)

export default component.exports
<template>
    <div class="user-chat-info">
        <div class="item flex-row" v-for="(item, i) in infos" :key="i">
            <div class="dot m-r-4" />
            <div class="desc" v-html="item" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserChatInfo',
    computed: {
        infos() {
            return [
                '남성분은 식당(카페) 장소를 공유해주세요.',
                '간단히 인사만 나누시고, 이야기는 내일 만나서 나누시는걸 추천드려요.',
                '애프터 및 연락처 교환은 매니저가 도와드리지 않습니다. 자율적으로 진행해주세요.',
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.user-chat-info {
    background: $grey-02;
    border-radius: 4px;
    padding: 8px 12px;

    .item {
        @include spoqa-f-regular;
        font-size: 12px;
        color: #666b70;
        line-height: 20px;
    }
}
</style>

<template>
    <div class="product-item">
        <div class="item-photo-container">
            <img :src="categoryItem.imgTagged" alt="" />
        </div>
        <div class="item-content">
            <div class="name f-16" v-html="$translate(categoryItem.category.toUpperCase())" />
            <div class="review f-12">
                <div class="review-item">
                    <img :src="require('@/assets/images/icons/star.png')" alt="" />
                    <span v-html="categoryItem.rate" />
                </div>
                <div class="review-item">
                    <img :src="require('@/assets/images/icons/person_grey.png')" alt="" />
                    <span v-html="`${categoryItem.users.toLocaleString()}명 가입`" />
                </div>
            </div>
            <div class="price f-16">
                <span class="spoqa-f-bold c-primary m-r-4" v-html="`${categoryItem.discount}%`" />
                <span
                    class="spoqa-f-bold"
                    v-html="
                        `${
                            categoryItem.category === 'unlimited' ? '월' : '회당'
                        } ${categoryItem.price.toLocaleString()}원~`
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductItem',
    props: ['categoryItem'],
}
</script>

<style scoped lang="scss">
.product-item {
    display: flex;
    margin-bottom: 16px;

    .item-photo-container {
        width: 40%;
        background: $grey-03;

        img {
            width: 100%;
            height: auto;
        }
    }
    .item-content {
        height: 24vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;

        img {
            width: 16px;
            height: 16px;
        }
        .review {
            color: $grey-07;
            display: flex;
            align-items: center;

            .review-item {
                display: flex;
                align-items: center;
                margin-right: 14px;
            }
        }
    }
}
</style>

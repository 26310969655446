<template>
    <div class="account-pay" v-if="virtualAccount">
        <div class="header">
            <div class="text-center">
                <div class="img-container">
                    <img width="48px" height="48px" :src="require('@/assets/images/icons/complete.png')" alt="" />
                </div>
                <div class="h3">아래의 계좌로 입금해주세요!</div>
            </div>
            <div class="title">결제 프로그램</div>
            <div class="grey-box">
                <span>{{ order.product_name }}</span>
            </div>
        </div>
        <main class="main" :class="{ rePurchase }">
            <div class="account-info m-b-40">
                <div class="title">입금처 정보</div>
                <div class="content">
                    <div class="item">
                        <div class="key">은행명</div>
                        <div class="value">우리은행</div>
                    </div>
                    <div class="item">
                        <div class="key">예금주</div>
                        <div class="value">주식회사 바닐라브릿지</div>
                    </div>
                    <div class="item">
                        <div class="key">계좌번호</div>
                        <div class="value">
                            <p>{{ accountNum }}</p>
                        </div>
                        <button class="btn-brd m-l-8" @click="copyAccount">복사</button>
                    </div>
                    <div class="item">
                        <div class="key">입금액</div>
                        <div class="value spoqa-f-bold">{{ price.toLocaleString() }}원</div>
                    </div>
                    <div class="item">
                        <div class="key">입금기한</div>
                        <div class="value spoqa-f-bold">{{ accountExpire }}</div>
                    </div>
                </div>
            </div>
            <div class="add-info m-b-40">
                <div class="title f-16">안내사항</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">입금 기한 경과시 해당 계좌로 입금이 불가합니다.</div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">시간이 지나도 입금완료 문자를 받지 못한 경우 고객센터로 문의해주세요.</div>
                    </div>
                </div>
            </div>
            <BottomButton label="대화방으로 가기" :greyLine="true" v-if="rePurchase" @click="onClickBtn" />
        </main>
    </div>
</template>

<script>
export default {
    name: 'AccountPayMethodPage',
    computed: {
        order() {
            return JSON.parse(localStorage.getItem('order'))
        },
        selectedPlan() {
            return [14, 15, 16].includes(this.order.product_id) ? 'prepaid' : 'unlimited'
        },
        price() {
            return this.order.price
        },
        rePurchase() {
            return this.$store.getters.me.rate_plan_history
        },
        virtualAccount() {
            return localStorage.getItem('virtualAccount') ? JSON.parse(localStorage.getItem('virtualAccount')) : null
        },
        accountExpire() {
            return `${this.$moment(this.virtualAccount.valid_until).format('YYYY.MM.DD HH시 mm분')}까지`
        },
        accountNum() {
            if (!this.virtualAccount) return '1005-904-223345'

            return this.virtualAccount.virtual_account.account_no
        },
    },
    methods: {
        onClickBtn() {
            if (this.rePurchase) {
                this.$modal.basic({
                    body: '입금이 확인되면 마이페이지에서 추가 구매 이력을 확인하실 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                this.$router.push({ name: 'ChatroomPage' })
                // this.$router.push({ name: 'HomePage' })
            }
        },
        copyAccount() {
            this.$copyText(`우리은행 ${this.accountNum}`)
            this.$toast.success('번호가 복사되었습니다.')
        },
    },
    mounted() {
        // const finished = this.$moment().isAfter(this.$moment(this.virtualAccount.valid_until))
        // if (finished) {
        //     localStorage.removeItem('order')
        //     localStorage.removeItem('virtualAccount')
        //     this.$toast.error('가상계좌 기간만료로 인해 홈으로 이동합니다')
        //     this.$router.push('/')
        // }
    },
}
</script>

<style scoped lang="scss">
.account-pay {
    .photo-container {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
    .main {
        padding: 0 16px 16px 16px;
        overflow-y: auto;
    }
    h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 16px 0 40px 0;
    }
    .title {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }
    .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 6px;
        line-height: 24px;

        .key {
            width: 72px;
            color: $grey-06;

            &.dotted {
                width: fit-content;
                margin-right: 4px;
                color: #111111;
            }
        }
        .value {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .header {
        padding: 16px 16px 32px 16px;
        .text-center {
            .img-container {
                @include center;
            }
            .h3 {
                text-align: center;
                font-size: 18px;
                margin: 11px 0 24px 0;
                @include spoqa-f-bold;
            }
        }
    }
    .grey-box {
        background: $grey-01;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
</style>

<template>
    <div class="program-info" :style="`top: ${infoTop}`">
        <div class="program-title">프로그램 기간</div>
        <div class="program-period">
            {{ programPeriod }}
        </div>
        <div class="program-desc">
            {{ programDescription }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgramInfo',
    props: {
        infoTop: String,
        productToDisplay: Object,
        productDisplayedInfo: Object,
    },
    computed: {
        programPeriod() {
            if (this.productToDisplay.status === 'pending') {
                return `프로필 받은 날 부터 ${this.productDisplayedInfo.valid_date}일간 유지됩니다`
            }
            const from = this.productToDisplay.product.valid_from
            const until = this.productToDisplay.product.valid_until
            return `${this.$moment(from).format('YY.MM.DD')} ~ ${this.$moment(until).format('YY.MM.DD')}`
        },
        programDescription() {
            if (this.productToDisplay.plan_type.includes('UNLIMITED')) {
                return '만료일 다음날부터 프로그램이 종료됩니다.'
            } else {
                return '기간내 횟수 소진시 프로그램이 즉시 종료됩니다'
            }
        },
    },
}
</script>

<style scoped lang="scss">
.program-info {
    position: absolute;
    top: 84px;
    left: 24px;
    background: #f7f8f9;
    border: 1px solid #94999e;
    border-radius: 4px;
    padding: 8px 12px;
    z-index: 2;
    .program-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #666b70;
    }
    .program-period,
    .program-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #666b70;
    }
}
</style>

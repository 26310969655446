<template>
    <div ref="userDetail" class="user-detail" @scroll="onScroll">
        <Loading :loading="loading" />
        <template v-if="user">
            <UserDetailHeaderBar
                :from="from"
                :user="user"
                :userId="userId"
                :photos="userPhotos"
                :show="showHiddenHeader"
                :nickname="user.profile.nickname"
                :customHeader="header"
                @open="openActionSheet"
            />
            <UserDetailPhotos :photos="userPhotos" />
            <UserProfile :user="user" :from="from" />
            <ProfilePassAcceptButtons
                class="bottom-btn-container message-btn"
                v-if="showProfilePassAcceptButtons"
                :count="remainProfilePhotoMessages.length"
                :message="profilePhotoMessage"
                :user="user"
                @action="onClickProfileActionButton"
            />
            <BottomButton
                v-if="showConfirmMyProfileButton"
                :blackBtn="true"
                :label="confirmMyProfileButtonLabel"
                :disabled="disabledConfirmMyProfileButton"
                class="my-profile-confirm"
                @click="confirmProfile"
            />
        </template>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import datingService from '@/services/dating'
import ProfilePassAcceptButtons from './components/ProfilePassAcceptButtons'
import UserDetailHeaderBar from './components/UserDetailHeaderBar'
import UserDetailPhotos from './components/UserDetailPhotos'
import UserProfile from './components/UserProfile'

export default {
    name: 'UserDetailPage',
    components: {
        ProfilePassAcceptButtons,
        UserDetailHeaderBar,
        UserDetailPhotos,
        UserProfile,
    },
    props: {
        from: String,
        userId: {
            type: Number,
            required: true,
        },
        agent: Object,
        agentId: Number,
        photos: {
            type: Array,
            default: () => [],
        },
        agentPhoto: String,
        agentMessage: String,
        profilePhotoMessage: Object,
        status: String,
        header: {
            type: Object,
        },
        message: {
            type: Object,
        },
        chat: {
            type: Object,
        },
    },
    data: () => ({
        user: null,
        showHiddenHeader: false,
        loading: false,
        userPhotos: [],
        profileConfirmed: false,
    }),
    watch: {
        userId: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init()
            }
        },
    },
    created() {
        this.init()
    },
    computed: {
        dom() {
            return this.$refs.userDetail
        },
        showProfilePassAcceptButtons() {
            return this.fromProfilePhotoComponent
        },
        showConfirmMyProfileButton() {
            return this.from === 'MyProfileConFirm'
        },
        disabledConfirmMyProfileButton() {
            if (!this.from) return false
            if (this.profileConfirmed) return true
            return this.$mustParse(this.message.content).user_confirmed
        },
        confirmMyProfileButtonLabel() {
            if (!this.from) return false
            if (this.disabledConfirmMyProfileButton) {
                return '프로필 확정완료'
            } else {
                return '이 프로필로 확정'
            }
        },
        fromProfilePhotoComponent() {
            return this.from === 'ProfilePhoto'
        },
        remainProfilePhotoMessages() {
            if (!this.fromProfilePhotoComponent) return []

            return (this.$store.getters.chat.$$messages || [])
                .filter(message => {
                    if (message.mtype !== 'profile-photo' || this.profilePhotoMessage.id >= message.id) {
                        return false
                    }

                    const content = this.$mustParse(message.content)
                    // show_photo 는 undefined 이거나 false 임
                    return !content.opened && !content.accept_status && content.show_photo === undefined
                })
                .reverse()
        },
    },
    methods: {
        async init() {
            if (!this.userId) {
                this.$stackRouter.pop()
                return
            }
            this.loading = true
            try {
                this.user = await this.$store.dispatch('loadUserDetail', { userId: this.userId })
            } catch (e) {
                this.$toast.error(e.data)
                this.$stackRouter.pop()
                return
            } finally {
                this.loading = false
            }

            if (this.photos.length === 0) {
                this.userPhotos = [...this.user.photos] || []
            } else {
                const parsedPhotos = this.photos.map(p => {
                    if (p.url.indexOf('thumb') > 0) {
                        const idx = p.url.indexOf('thumb')
                        const front = p.url.slice(0, idx)
                        const back = p.url.slice(idx + 5)

                        return {
                            url: front + 'medium' + back,
                        }
                    }
                    return { ...p }
                })

                this.userPhotos = [...parsedPhotos]
            }

            this.$scroll.up(this.dom, true)
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > window.innerWidth - 56 // 56 is @header-height
        },
        asString(obj) {
            return typeof obj === 'string' ? obj : obj.name
        },
        async onClickProfileActionButton(status) {
            if (status === 'undo') return

            if (this.remainProfilePhotoMessages.length > 0) {
                const photos = () => {
                    let photos = []
                    if (content.url) {
                        photos.push(content.url)
                    }

                    if (content.urls) {
                        photos = photos.concat(content.urls)
                    }

                    return photos.map(p => ({ url: p }))
                }

                const nextMessage = this.remainProfilePhotoMessages[0]
                const content = this.$mustParse(nextMessage.content)

                await chatService.openProfile(this.$store.getters.chat.id, nextMessage.id)
                content.opened = true
                const message = this.$store.getters.chat.$$messages.find(message => message.id === nextMessage.id)
                message.content = JSON.stringify(content)

                this.$stackRouter.replace({
                    name: 'UserDetailPage',
                    props: {
                        userId: content.source_id,
                        photos: photos(),
                        from: 'ProfilePhoto',
                        profilePhotoMessage: nextMessage,
                    },
                })
            } else {
                this.$stackRouter.pop()
            }
        },
        async confirmProfile() {
            // api 연결 await

            const res = await chatService.userConfirmedProfile(this.chat.id, this.message.id)
            console.log(res)
            this.profileConfirmed = true
            this.$toast.success('확정되었습니다.')
            this.$store.dispatch('loadChat', {
                chatId: this.chat.id,
                force: true,
            })
            // this.$stackRouter.pop()
        },
        openActionSheet() {
            this.$actionSheet({
                buttons: [
                    {
                        label: 'LEAVE_DATING_FEEDBACK',
                        handler: async () => {
                            try {
                                this.$loading(true)
                                const dating = await datingService.datings(this.user.id)

                                if ((dating || []).length === 0) {
                                    this.$toast.error('소개팅 성사된 회원끼리만 피드백을 남길 수 있어요!')

                                    return
                                }

                                if (dating[0].is_feedback) {
                                    this.$modal.basic({
                                        title: 'MODAL_DEFAULT_TITLE',
                                        body: this.$translate('ALREADY_SUBMIT_FEEDBACK'),
                                        buttons: [
                                            {
                                                label: 'CONFIRM',
                                                class: 'btn-primary',
                                            },
                                        ],
                                    })

                                    return
                                }

                                const dateDiff = this.$options.filters.dateDiff(dating[0].created_at)

                                if (dateDiff > 20) {
                                    this.$toast.error('피드백은 소개팅 성사 후 20일 동안만 가능해요')

                                    return
                                }
                                this.$stackRouter.push({
                                    name: 'DatingStatusCheckPage',
                                    props: {
                                        user: this.user,
                                        photos: this.userPhotos,
                                        dating: dating[0],
                                    },
                                })
                            } catch (e) {
                                console.error(e)
                            } finally {
                                this.$loading(false)
                            }
                        },
                    },
                    {
                        label: 'REPORT_PROFILE',
                        class: 'c-red',
                        handler: () => {
                            this.$stackRouter.push({
                                name: 'ReportSelectReasonPage',
                                props: {
                                    type: 'user',
                                    target: this.user,
                                },
                            })
                        },
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail {
    overflow-y: auto;

    hr {
        border-bottom: solid 1px $grey-02;
    }

    .user-profile {
        padding-bottom: 40px;
    }

    ::v-deep .bottom-btn-container {
        position: sticky;
        bottom: 0;
        width: 100%;
        background: white;
        border-top: 1px solid $grey-02;
        padding: 16px;
        z-index: 10;

        .desc {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 12px;
        }

        button {
            height: 48px;
            font-size: 15px;
            opacity: 1;

            &.btn-reject {
                width: 48px;
                background: $grey-05;

                i {
                    color: white;
                }
            }
        }

        &.message-btn {
            .btn-brd {
                flex: 1;
                margin-right: 8px;

                &.btn-pass {
                    max-width: 100px;
                }
            }

            .btn-primary {
                flex: 2;
                box-shadow: none;
            }
        }
    }
    .my-profile-confirm {
        padding: 16px 16px !important;
        z-index: 4;
    }
}
</style>

<template>
    <div v-if="productToDisplay.status === 'pending'" class="time-left">
        <span class="used">
            {{ usedInfo }}
        </span>
        <span class="f-18"> / </span>
        <span class="f-18">
            {{ productInfo }}
        </span>
        <img
            v-if="Object.keys(productToDisplay).length"
            @click="infoModal"
            :src="require(`@/assets/images/icons/info.png`)"
            width="16px"
            height="16px"
        />
    </div>
</template>

<script>
export default {
    name: 'ProductToDisplay',
    props: {
        productToDisplay: Object,
        productDisplayedInfo: Object,
    },
    methods: {
        infoModal(e) {
            this.$emit('clickInfo', e)
        },
    },
    computed: {
        productInfo() {
            if (this.productToDisplay.plan_type === 'EXTRA') {
                const { amount } = this.productDisplayedInfo
                return amount ? `${this.productDisplayedInfo.amount}회` : `${this.productDisplayedInfo.valid_date}일`
            }

            return this.productToDisplay.plan_type === 'UNLIMITED'
                ? `${this.productDisplayedInfo.valid_date}일`
                : `${this.productDisplayedInfo.amount}회`
        },
        usedInfo() {
            if (this.productToDisplay.plan_type === 'EXTRA') {
                const { amount } = this.productDisplayedInfo
                return amount ? '0회' : '0일'
            }

            return this.productToDisplay.plan_type === 'UNLIMITED' ? '0일' : '0회'
        },
    },
}
</script>

<style scoped lang="scss">
.time-left {
    color: $grey-08;
    @include spoqa-f-bold;

    .used {
        font-size: 24px;
        color: black;
    }
}
</style>

<template>
    <div class="event-expire-banner">
        <img class="notice-icon" :src="require('@/assets/images/icon_notice.png')" alt="" />

        <div class="banner-title-wrapper">
            <div class="sub-title">
                {{ subTitle }}
            </div>
            <div class="main-title">
                {{ mainTitle }}
            </div>
        </div>
        <!-- <button class="btn plain-btn" v-if="!isNewUser" @click="onClickExtend">
            {{ buttonLabel }}
        </button> -->
    </div>
</template>

<script>
// import BannerImages from '@/routes/chat/chats/components/BannerImages'

export default {
    name: 'ExtendBanner',
    components: {
        // BannerImages,
    },
    computed: {
        subTitle() {
            return '나와 딱맞는 인연, 절대 놓치지마세요!'
        },
        mainTitle() {
            return '곧 프로그램이 만료됩니다'
        },
        buttonLabel() {
            return '갱신하기'
        },
        isNewUser() {
            const me = this.$store.getters.me
            return me.products.some(p => p.product_id >= 19)
        },
    },
    methods: {
        onClickExtend() {
            this.$emit('move-to-productlist')
        },
    },
}
</script>

<style scoped lang="scss">
.event-expire-banner {
    background: linear-gradient(93.48deg, #ff5c5a 42.35%, #ff9417 93.19%);
}
</style>

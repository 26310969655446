<template>
    <div class="user-profile">
        <div class="section name-age">
            <span v-if="isMe && from !== 'MyProfileConFirm'">{{ user.name }}, </span>
            <span>{{ getUserAge }}세</span>
            <span> ({{ $moment(profile.birthday).format('YYYY년생') }})</span>
        </div>
        <PersonalProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <EducationProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <CareerProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <FinancialProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <FamilyProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <MarriageProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <OnlyToManager v-if="userId === me.id" class="section" :userId="userId" :profile="profile" />
        <template v-if="user.keywords.length > 0">
            <hr />
            <Keywords class="section" :keywords="user.keywords" />
        </template>
    </div>
</template>

<script>
import PersonalProfile from '@/routes/user-detail/components/PersonalProfile'
import EducationProfile from '@/routes/user-detail/components/EducationProfile'
import CareerProfile from '@/routes/user-detail/components/CareerProfile'
import FinancialProfile from '@/routes/user-detail/components/FinancialProfile'
import FamilyProfile from '@/routes/user-detail/components/FamilyProfile'
import MarriageProfile from '@/routes/user-detail/components/MarriageProfile'
import OnlyToManager from '@/routes/user-detail/components/OnlyToManager'
import Keywords from './Keywords'

export default {
    name: 'UserProfile',
    components: {
        PersonalProfile,
        EducationProfile,
        Keywords,
        CareerProfile,
        FinancialProfile,
        FamilyProfile,
        MarriageProfile,
        OnlyToManager,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        from: String,
    },

    computed: {
        getUserAge() {
            return this.$options.filters.asAge(this.profile.birthday)
        },
        intro() {
            const removeBlank = intro => (intro || '').trim()

            let intro = ''
            if (this.user) intro = this.user.profile.intro

            return removeBlank(intro)
        },
        userId() {
            return this.user.id
        },
        profile() {
            return this.user.profile
        },
        verificationBadges() {
            return this.user.verification_badges || []
        },
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
}
</script>
<style scoped lang="scss">
.name-age {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    padding: 16px 0;
}
</style>

<template>
    <div class="front" v-lazy:background-image="require(`@/assets/images/front/img_front_bg.png`)">
        <!-- <div class="front-header">
            <ChannelTalk :wrapStyle="wrapStyle" :textStyle="{ color: 'black' }" :hasIcon="true" />
        </div> -->

        <div class="entrance">
            <div class="head f-light" @click="increaseCounter">
                <div class="head-line center spoqa-f-bold">
                    <img :src="require('@/assets/images/front_logo.png')" alt="" />
                </div>
            </div>
            <!-- <div class="middle">
                어제 성사된 커플,
                <div style="width: 75px; text-align: center">
                    <transition name="slide-up">
                        <span v-show="coupleCnt" class="couple-count"> {{ coupleCnt }}쌍</span>
                    </transition>
                </div>
            </div> -->
            <div class="foot">
                <!-- <button class="btn signup m-b-8" @click="signupPage">
                    <p class="label f-regular">혜택받고 가입하기</p>
                </button> -->
                <button class="btn" @click="startWithPhone">
                    <p class="label f-regular">로그인</p>
                </button>
                <div class="hr m-t-16" />
                <!-- <div class="consent-text">
                    <span class="text" v-html="'회원가입, 로그인을 완료하면 '" />
                    <span class="text-bold" @click="openConsent('service')" v-html="'이용약관'" />
                    <span class="text" v-html="', <br>'" />
                    <span class="text-bold" @click="openConsent('privacy')" v-html="'개인정보처리방침'" />
                    <span class="text" v-html="'에 동의하는 것으로 간주합니다.'" />
                </div> -->
                <div class="signup-wrap">
                    <div class="move-web" @click="needGradeTest">아직 멤버가 아니신가요?</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ChannelTalk from '@/components/app/ChannelTalk'

export default {
    name: 'FrontPage',
    // components: { ChannelTalk },
    data: () => ({
        counter: 0,
        counterInterv: null,
        provider: null,
        coupleCnt: null,
    }),
    watch: {
        counter(newVal) {
            if (newVal !== 5) return

            this.$modal.custom({
                component: 'ModalSelectEnv',
                options: {
                    preventCloseOnMousedownMask: true,
                },
            })
        },
    },
    computed: {
        wrapStyle() {
            return {
                bottom: '0px',
                position: 'static',
                'background-color': 'transparent',
                'font-size': '14px',
                'font-family': 'SpoqaHanSansNeo-Regular',
            }
        },
    },
    methods: {
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://sm-api.vanillabridge.com/api/users/terms?term_type=${type}`,
            })
        },
        watchCounter() {
            this.counterInterv = setInterval(() => {
                if (this.counter > 0) this.counter -= 1
            }, 1000)
        },
        increaseCounter() {
            if (this.counter < 5) this.counter++
        },
        async signupPage() {
            const res = await this.$needUpdate('1.0.2')
            if (!res) {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: `FrontPage_Click_Signup`,
                    },
                })
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `FrontPage_Click_Signup`,
                    },
                })
                this.$router.push({ name: 'OnBoardWelcomePage' })
            }
        },
        startWithPhone() {
            this.$router.push({ name: 'StartWithPhonePage' })
        },
        login() {
            this.$router.push({ name: 'LoginPage' })
        },
        needGradeTest() {
            this.$stackRouter.push({ name: 'NeedGradeTest' })
        },
    },
    mounted() {
        // 로그아웃 하는 경우에도 여기 오게 되는데, 그 경우에도 loadConstants를 해줘야 회원가입시 문제없음
        this.$store.dispatch('loadConstants')
        // this.$nativeBridge.postMessage({
        //     action: 'setBackgroundColor',
        //     value: {
        //         top: 'white',
        //         bottom: 'white',
        //     },
        // })
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_FrontPage',
            },
        })

        setTimeout(() => {
            const date = new Date()
            const year = Number(date.getFullYear())
            const month = Number(date.getMonth())
            const day = Number(date.getDay())

            this.coupleCnt = ((year * (month + day)) % 67) + 60
        }, 500)

        this.watchCounter()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
        if (this.counterInterv) {
            clearInterval(this.counterInterv)
            this.counterInterv = null
        }
    },
}
</script>

<style lang="scss" scoped>
.front {
    //background-image: linear-gradient(to top, #1877f2, #36b3ff) !important;
    $footer-height: 152px;
    height: 100vh !important;
    position: relative;
    overflow: hidden !important;
    // background-image: require(`@/assets/images/front/img_front_bg.png`);
    width: 100%;
    background-size: 100%;

    .front-header {
        //     background: white;
        color: black;
        height: $header-height;
        padding: 14px;
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include spoqa-f-regular;
    }

    .circle {
        width: 600px;
        height: 600px;
        position: absolute;
        transform: translateX(-16%) translateY(45%);
        z-index: -1;
        border-radius: 50%;
        background: #f4fcff;
    }

    .couple-count {
        @include spoqa-f-bold;
        font-size: 24px;
        text-align: center;
    }
    .middle {
        @include center;
        color: black;
        font-size: 18px;
    }

    .entrance {
        // height: calc(100% - 52px);
        height: 100%;
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .head {
            color: white;
            padding-top: 210px;

            &-line {
                img {
                    width: 130px;
                    height: auto;
                }

                @include spoqa-f-regular;
                text-align: center;
                font-size: 16px;
                line-height: 26px;
            }

            .dots {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                align-items: baseline;

                .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: white;
                    margin-left: 12px;
                }

                &-line {
                    &:nth-child(3) {
                        margin-left: 8px;
                    }

                    &:nth-child(5) {
                        margin-right: 8px;
                    }
                }
            }

            .img-b {
                width: 130px;
            }

            .img-c {
                width: calc(100vw - 40px);
                // max-width: 300px;
            }
        }

        .foot {
            padding-bottom: 100px;
            .divider {
                border-bottom: solid 1px rgba(255, 255, 255, 0.2);
            }

            .bar {
                color: $grey-04;
                margin: 10px;
            }

            button {
                height: 48px;
                width: 100%;
                font-size: 15px;
                background: #04c9b2;
                color: white;
                @include spoqa-f-medium;

                &.signup-email {
                    color: $grey-09;
                    font-size: 14px;

                    @include f-regular;

                    .material-icons {
                        margin-right: 10px;
                        color: $blue-primary;
                    }
                }
                &.signup {
                    background: $blue-primary;
                }
            }

            .others {
                width: 100%;
                text-align: center;

                .text-btn {
                    color: white;
                    font-size: 14px;
                }

                .vertical-bar {
                    margin: 0 32px;
                    color: white;
                }
            }

            .hr {
                width: 100vw;
                margin-left: -20px;
                height: 1px;
                background: rgba(255, 255, 255, 0.2);
            }

            .consent-text {
                //padding: 0 16px 0 16px;
                text-align: center;
                font-size: 12px;
                @include f-medium;
                color: $grey-05;

                .text-bold {
                    text-decoration: underline;
                    @include f-bold;
                }
            }

            .signup-wrap {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #393d44;
                text-align: center;

                .not-member {
                }
                .move-web {
                    line-height: 22px;
                    text-decoration-line: underline;
                }
            }
        }
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal"}},[(!_vm.preventModal)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.transparent),expression:"!transparent"}],staticClass:"modal",on:{"mousedown":_vm.closeOnMousedownMask}},[_c('div',{class:['ModalBasic', 'ModalForceCheck', 'ModalBadgeDeclined', 'ModalForceFeedback'].includes(
                    _vm.modal.component
                )
                    ? 'modal-container-basic'
                    : _vm.modal.component === 'ModalSelectDate'
                    ? 'modal-container-calendar'
                    : _vm.modal.component === 'ModalContact'
                    ? 'modal-container-contact'
                    : ['ModalHowToUseFeats'].includes(_vm.modal.component)
                    ? 'modal-black-screen'
                    : _vm.modal.component === 'ModalCompanyAddInfo'
                    ? 'modal-bottom-sheet'
                    : 'modal-container'},[_c(_vm.modal.component,{tag:"component",attrs:{"options":_vm.modal.options},on:{"close":_vm.onClose,"update:options":function($event){return _vm.$set(_vm.modal, "options", $event)}}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
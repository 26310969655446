<template>
    <div class="bottom-border">
        <div class="basic-profile" v-if="familyProfile.length || profile.family.length">
            <h3 class="title">
                <img class="m-r-2" src="@/assets/images/icons/family.png" width="24px" height="24px" />
                <span>가족관계</span>
            </h3>
            <div style="z-index: 2" class="items" v-for="item in familyProfile" :key="item.id">
                <div class="item" v-if="item.value">
                    <template>
                        <div class="key" v-html="$translate(item.key.toUpperCase())" />
                        <div class="value">
                            <div class="content">
                                <span>{{ item.value }}</span>
                                <span v-if="showBadge(item)" class="manager-only">매니저만</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="family-detail-profile no-border" v-if="showFamily">
            <div class="key m-t-12 m-b-6" v-html="`가족현황`" />
            <DetailFamilyItem :userId="userId" :profile="profile" />
        </div>
        <div class="family-detail-profile" v-if="profile.family_intro">
            <div class="key m-t-12 m-b-12" v-html="`가족소개`" />
            <SelfIntroduction :intro="profile.family_intro" />
        </div>
    </div>
</template>

<script>
import SelfIntroduction from '@/routes/user-detail/components/SelfIntroduction'
import DetailFamilyItem from '@/routes/my-page/components/DetailFamilyItem'

export default {
    name: 'FamilyProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    components: {
        SelfIntroduction,
        DetailFamilyItem,
    },
    computed: {
        getTitle() {
            return `<i class="material-icons">people</i> <span>가족관계</span>`
        },
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getSiblingStatus() {
            if (this.profile.brother_count === null) return null
            if (this.profile.sister_count === null) return null
            if (this.profile.order_of_children === null) return null
            return `${this.profile.brother_count}남 ${this.profile.sister_count}녀 중 ${this.profile.order_of_children}째`
        },
        getAssetRange() {
            if (!this.isMe && !this.profile.privacy_setting.show_family_asset) return null
            if (this.profile.privacy_setting.show_family_asset > 1) {
                if (!this.profile.family_asset) return '자산없음'
                return this.$profile.assetRangeParsed(this.profile.family_asset)
            } else {
                if (!this.profile.family_asset) return '자산없음'
                return this.$krwParsed(this.profile.family_asset)
            }
        },

        familyProfile() {
            const items = [
                {
                    id: 1,
                    key: 'hometown',
                    value: this.profile.hometown_shorten,
                },
                {
                    id: 2,
                    key: 'sibling_status',
                    value: this.getSiblingStatus,
                },
                {
                    id: 3,
                    key: 'parent_status',
                    value: this.getParentState(this.profile.parent_status),
                    selective: true,
                },
                {
                    id: 4,
                    key: 'family_asset',
                    // value: this.$krwParsed(this.profile.family_asset),
                    value: this.getAssetRange,
                    selective: true,
                },
            ]

            return items.filter(item => item.value)
        },
        showFamily() {
            if (!this.profile.family.length) return false
            if (this.isMe) return true
            return this.profile.family.some(f => {
                // return f.show_live_status || f.show_education_status  || f.show_university_name ||f.show_job_name
                return f.show_live_status || f.show_education_status || f.show_job_name
            })
        },
    },
    methods: {
        getParentState(val) {
            let result = ''
            switch (val) {
                case 0: {
                    result = '함께 거주'
                    break
                }
                case 1: {
                    result = '별거'
                    break
                }
                case 2: {
                    result = '이혼'
                    break
                }
                case 3: {
                    result = '사별'
                    break
                }
            }
            return result
        },
        showBadge(item) {
            if (!this.isMe) return false
            if (item.selective) {
                return !this.profile.privacy_setting[`show_${item.key}`]
            }
            return false
        },
    },
}
</script>
<style scoped lang="scss">
.basic-profile {
    border: none;
}
.family-detail-profile {
    border: none;
    .key {
        // font-size: 15px;
        color: #949498;
    }
}
.bottom-border {
    border-bottom: solid 1px $grey-02;
}
</style>

<template>
    <section class="section" :class="{ disabled }">
        <div class="title">결제 수단</div>
        <div class="payment-buttons grid-col">
            <div
                v-for="item in paymentMethod"
                :key="item.id"
                class="btn btn-normal"
                :class="{ selected: selected === item.id }"
                @click="clickPayMethod(item.id)"
                v-text="item.kor"
            />
        </div>
    </section>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
    },
    data: () => ({
        selected: null,
        paymentMethod: [
            {
                id: 0,
                name: 'card',
                kor: '카드',
            },
            {
                id: 1,
                name: 'bank_account',
                kor: '무통장 입금',
            },
        ],
    }),
    methods: {
        clickPayMethod(id) {
            this.selected = id
            this.$emit('select', id)
        },
    },
}
</script>

<style scoped lang="scss">
.btn-normal {
    border: 1px solid $grey-03 !important;
    color: black !important;
    height: 48px;
}

.inactive {
    border: 1px solid $grey-03 !important;
    color: $grey-04 !important;
}
.selected {
    border: 1px solid $blue-primary !important;
    color: $blue-primary !important;
    background: #f1fffc;
}
.disabled * {
    color: #e9e9e9 !important;
}
</style>

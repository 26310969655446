<template>
    <section class="product-bill m-b-30">
        <div class="title">결제 금액</div>
        <div class="price-detail">
            <div class="row">
                <div class="key">프로그램 금액</div>
                <div class="value" v-html="`${price.prePrice} 원`" />
            </div>
            <div v-if="selectedCoupon" class="row">
                <div class="key">쿠폰 할인</div>
                <div class="value" v-html="`-${price.couponDiscount} 원`" />
            </div>
            <div class="row">
                <div class="key">이벤트 할인</div>
                <div class="value" v-html="`-${price.eventDiscount} 원`" />
            </div>
            <div class="row">
                <div class="key">포인트 할인</div>
                <div class="value" v-html="`-${Number(selectedPoint).toLocaleString()} 원`" />
            </div>
            <div class="row under-line">
                <div class="key">
                    <span>부가세(10%)</span>
                    <i @click="mouseOver = !mouseOver" class="material-icons-outlined">info</i>
                </div>
                <div class="value" v-html="`${price.tax} 원`" />
                <div class="info" v-if="mouseOver">
                    <p class="spoqa-f-bold">부가세 계산법</p>
                    <p>(프로그램 금액-할인액)*10%</p>
                </div>
            </div>
            <div class="row m-t-8">
                <div class="key c-black">최종 결제금액</div>
                <div class="value">
                    <span class="c-primary spoqa-f-bold">
                        {{ price.price }}
                    </span>
                    <span>원</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: () => ({
        mouseOver: false,
    }),
    computed: {
        price() {
            return this.$store.getters.price
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
        selectedPoint() {
            return this.$store.getters.selectedPoint
        },
    },
}
</script>

<style scoped lang="scss">
.product-bill {
    .price-detail {
        .row {
            position: relative;
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 8px;
            @include between;

            .info {
                position: absolute;
                width: 200px;
                background: $grey-01;
                bottom: -54px;
                left: 16px;
                z-index: 5;
                font-size: 12px;
                line-height: 20px;
                padding: 8px 12px;
                border-radius: 4px;
                border: 1px solid #94999e;
            }

            &.under-line {
                border-bottom: dashed $grey-03;
            }

            .key {
                color: $grey-06;
                @include center;
                i {
                    margin-left: 4px;
                    color: $grey-06;
                    font-size: 20px;
                }
            }
            .value {
                color: black;
            }
        }
    }
}
</style>

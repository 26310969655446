<template>
    <div class="product-spec">
        <TabComponent :tab-items="tabItems" :onClickTab="onClickTab" :selectedTab="selectedTab" />
        <div class="img-container">
            <img :src="require(`@/assets/images/product-image/${category}-info.png`)" alt="" />
        </div>
    </div>
</template>

<script>
import TabComponent from '@/components/common/TabComponent'

export default {
    name: 'ProductSpecific',
    components: { TabComponent },
    props: {
        category: String,
    },
    data: () => ({
        selectedTab: 'spec',
        tabItems: [
            {
                type: 'spec',
                label: '상세정보',
            },
            // {
            //     type: 'review',
            //     label: '후기',
            // },
            // {
            //     type: 'refund',
            //     label: '환불정책',
            // },
            // {
            //     type: 'qna',
            //     label: '자주 묻는 질문',
            // },
        ],
    }),
    methods: {
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
    },
}
</script>

<style scoped lang="scss">
.product-spec {
    .img-container {
        width: 100%;
        margin-top: 12px;
        @include center;

        img {
            width: 102vw;
            height: auto;
        }
    }
}
</style>

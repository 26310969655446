<template>
    <div class="product-info">
        <div class="review-cnt">
            <div class="stars">
                <img v-for="i in starCount" :key="i" :src="require('@/assets/images/icons/star-color.png')" alt="" />
            </div>
            <div class="cnt" v-text="`후기 ${category.users.toLocaleString()}건`" />
        </div>
        <div class="title-desc">
            <h3 class="title" v-text="$translate(category.category.toUpperCase())" />
            <p class="desc" v-text="category.desc" />
        </div>
        <div class="price-info">
            <!--            Math.round(((mostCheap.pre_price / 6 )))-->
            <p
                class="discount"
                v-text="`${category.category === 'unlimited' ? '월' : '회당'} ${(77770).toLocaleString()}원`"
            />
            <div class="between">
                <span class="f-14"></span>
                <div class="flex-row">
                    <span class="spoqa-f-bold c-primary m-r-4" v-text="`${mostCheap.discount_limit}%`" />
                    <span
                        class="spoqa-f-bold"
                        v-text="
                            `${
                                category.category === 'unlimited' ? '월' : '회당'
                            } ${mostCheap.discount.toLocaleString()}원~`
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductInfo',
    props: {
        category: Object,
    },
    computed: {
        mostCheap() {
            const category = this.$store.getters.products[this.category.category].filter(p => p.id < 17 && p.id > 10)
            const mostCheap = category[category.length - 1]
            const per = this.category.category === 'unlimited' ? mostCheap.valid_week / 4 : mostCheap.amount
            return {
                ...mostCheap,
                price: Math.round(mostCheap.price / per),
            }
        },
        starCount() {
            const round = Math.round(this.category.rate)
            const arr = []
            for (let i = 1; i <= round; i++) {
                arr.push(i)
            }

            return arr
        },
    },
}
</script>

<style scoped lang="scss">
.product-info {
    padding: 16px;
    border-bottom: 8px solid $grey-03;

    .align-center {
        display: flex;
        align-items: center;
    }

    .review-cnt {
        @extend .align-center;

        .stars {
            @extend .align-center;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    .title-desc {
        .title {
            line-height: 28px;
            margin-bottom: 4px;
        }
        .desc {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .price-info {
        margin-top: 8px;
        .discount {
            text-align: right;
            text-decoration: line-through;
            color: $grey-06;
            font-size: 16px;
        }
        .between {
            @include between;

            .flex-row {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
}
</style>

import productService from '@/services/product'
import { store as $store } from '@/store'
import Vue from 'vue'

export const parsePidToPlanName = (pid, upper = true) => {
    const products = $store.getters.products
    const result = products.prepaid.some(item => item.id === pid) ? 'prepaid' : 'unlimited'
    return upper ? result.toUpperCase() : result
}

export const getPlanInfo = (planName, pid) => {
    const plans = $store.getters.products[planName.toLowerCase()]
    const res = plans.find(item => item.id === pid)

    return res || $store.getters.products.all.find(item => item.id === pid)
}

export const getPlanNameById = pid => {
    const plans = $store.getters.products.all
    return plans.find(item => item.id === pid)
}

const defaultState = () => ({
    products: null,
    selectedProduct: null,
    plans: [
        {
            id: 1,
            category: 'prepaid',
            desc: '구매한 횟수만큼 매칭(만남)을 가질 수 있는 상품입니다.',
            rate: 4.7,
            users: 2245,
            discount: 33,
            price: 44444,
            img: require('@/assets/images/product-image/prepaid.png'),
            imgTagged: require('@/assets/images/product-image/prepaid-tagged.png'),
        },
        {
            id: 2,
            category: 'unlimited',
            desc: '정해진 기간동안 매칭(만남)횟수 제한없이 사용할 수 있는 상품입니다.',
            rate: 4.5,
            users: 1248,
            discount: 57,
            price: 33333,
            img: require('@/assets/images/product-image/unlimited.png'),
            imgTagged: require('@/assets/images/product-image/unlimited-tagged.png'),
        },
    ],
    selectedPlan: null,
    selectedCoupon: null,
    selectedPoint: 0,
    userProducts: null,
    userRatePlans: null,
})

const state = defaultState()

const getters = {
    products: state => state.products,
    selectedProduct: state => state.selectedProduct,
    price: state => {
        const selectedProduct = state.selectedProduct
        const selectedCoupon = state.selectedCoupon
        const selectedPoint = state.selectedPoint
        console.log(
            '66 : ',
            selectedProduct,
            selectedPoint,
            selectedProduct.price,
            selectedProduct.price - selectedPoint
        )
        if (!selectedCoupon) {
            return {
                prePrice: selectedProduct.pre_price.toLocaleString(),
                price: Math.round(((selectedProduct.pre_price - selectedPoint) * 11) / 10).toLocaleString(),
                // price: (selectedProduct.price - selectedPoint).toLocaleString(),
                tax: Math.round((selectedProduct.pre_price - selectedPoint) / 10).toLocaleString(),

                priceExcludeTax: ((selectedProduct.price - selectedPoint) / 11).toLocaleString(),
                eventDiscount: (selectedProduct.pre_price - (selectedProduct.price / 11) * 10).toLocaleString(),
            }
        } else {
            if (selectedCoupon.discount.discount_type === 'percent') {
                const price =
                    (selectedProduct.price / 11) * 10 * ((100 - selectedCoupon.discount.amount) / 100) - selectedPoint

                const couponDiscount = (selectedProduct.price / 11) * 10 * (selectedCoupon.discount.amount / 100)
                return {
                    prePrice: selectedProduct.pre_price.toLocaleString(),
                    price: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (selectedProduct.pre_price - (selectedProduct.price / 11) * 10).toLocaleString(),
                }
            } else if (selectedCoupon.discount.discount_type === 'quantity') {
                const price = (selectedProduct.price / 11) * 10 - selectedCoupon.discount.amount - selectedPoint

                const couponDiscount = selectedCoupon.discount.amount
                return {
                    prePrice: selectedProduct.pre_price.toLocaleString(),
                    price: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (selectedProduct.pre_price - (selectedProduct.price / 11) * 10).toLocaleString(),
                }
            } else if (selectedCoupon.discount.discount_type === 'fix_price') {
                const price = selectedCoupon.discount.amount

                const couponDiscount =
                    (selectedProduct.price / 11) * 10 - selectedCoupon.discount.amount - selectedPoint
                return {
                    prePrice: selectedProduct.pre_price.toLocaleString(),
                    price: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (selectedProduct.pre_price - (selectedProduct.price / 11) * 10).toLocaleString(),
                }
            }
        }
    },
    plans: state => state.plans,
    selectedPlan: state => state.selectedPlan,
    selectedCoupon: state => state.selectedCoupon,
    selectedPoint: state => state.selectedPoint,
    userProducts: state => state.userProducts,
    userRatePlans: state => {
        const userProducts = state.userProducts
        if (!userProducts) return false

        const ratePlans = userProducts.products.filter(item => item.ptype === 'rate_plan')
        const tickets = userProducts.products.filter(item => item.ptype === 'ticket' && item.enabled)
        const m = Vue.prototype.$moment
        return {
            introduceDay: userProducts.introduceDay,
            onGoing: ratePlans.find(item => {
                if (!item.enabled) return false
                const dateValid = item.valid_from && m().isBefore(m(item.valid_until))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.product_id)
                if (!dateValid) return false

                return plan === 'UNLIMITED' ? true : ticketsValid.length
            }),
            expired: ratePlans.filter(item => {
                if (!item.enabled) return true
                const dateExpired = item.valid_from && m().isAfter(m(item.valid_until))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.product_id)
                if (dateExpired) return true

                return plan === 'UNLIMITED' ? false : ticketsValid.length === 0
            }),
            pending: ratePlans.filter(item => !item.valid_from && !item.valid_until),
        }
    },
    validTickets: (state, getters) => {
        const userRatePlans = getters.userRatePlans
        const userProducts = state.userProducts.products
        const expireLatest = userRatePlans.expired.length
            ? userRatePlans.expired[userRatePlans.expired.length - 1]
            : false
        if (userRatePlans.onGoing) {
            return userProducts.filter(
                item => item.ptype === 'ticket' && item.related_user_product_id === userRatePlans.onGoing.id
            )
        } else if (userRatePlans.pending.length) {
            const pendingLatest = userRatePlans.pending[userRatePlans.pending.length - 1]
            return userProducts.filter(
                item => item.ptype === 'ticket' && item.related_user_product_id === pendingLatest.id
            )
        } else if (expireLatest) {
            return userProducts.filter(
                item => item.ptype === 'ticket' && item.related_user_product_id === expireLatest.id
            )
        } else {
            return []
        }
    },
}

const mutations = {
    setProducts(state, value) {
        state.products = {
            all: value.rate_plan,
            unlimited: value.rate_plan.filter(item => item.code.includes('unlimited_') || item.amount === 0),
            prepaid: value.rate_plan.filter(item => item.code.includes('prepaid_') || item.amount > 0),
            ticket: value.ticket.filter(item => item.ptype === 'ticket'),
        }
    },
    setSelectedProduct(state, value) {
        state.selectedProduct = value
    },
    setSelectedPlan(state, value) {
        state.selectedPlan = value
    },
    setSelectedCoupon(state, value) {
        state.selectedCoupon = value
    },
    setSelectedPoint(state, value) {
        state.selectedPoint = value
    },
    setUserProducts(state, value) {
        state.userProducts = value
    },
}

const actions = {
    async getProducts({ commit }) {
        // const { rate_plan } = await productService.all()
        const products = await productService.all()

        commit('setProducts', products)
    },
}
export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}

<template>
    <div class="education-detail-profile">
        <!-- <h3 class="title" v-html="getTitle" /> -->
        <div class="wrapper">
            <h3 class="title">
                <img class="m-r-2" src="@/assets/images/icons/edu.png" width="24px" height="24px" />
                <span>학력사항</span>
            </h3>
            <DetailEducationItem class="list" :schoolItems="schoolItems" :profile="profile" :userId="userId" />
        </div>
    </div>
</template>

<script>
import DetailEducationItem from '@/routes/my-page/components/DetailEducationItem'
export default {
    name: 'EducationProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    components: {
        DetailEducationItem,
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getTitle() {
            return `<i class="material-icons">school</i> <span>학력정보</span>`
        },
        userSchools() {
            return ['high_school', 'bachelor', 'master', 'doctor'].filter(
                type => this.profile[`${type}_u_name`] || this.profile[`${type}_name`]
            )
        },
        getSchoolName() {
            return sType => {
                const item = []
                const words = ['u', 'c', 'm']
                words.forEach(w => {
                    if (this.profile.privacy_setting[`show_${sType}_${w}_name`]) {
                        item.push(this.profile[`${sType}_${w}_name`])
                    } else {
                        return false
                    }
                })
                return item.join(' ')
            }
        },
        schoolItems() {
            const items = []
            this.userSchools.forEach(sType => {
                items.push({
                    type: sType,
                    // name: sType === 'high_school' ? `${this.profile.high_school_name}` : this.getSchoolName(sType),
                    name:
                        sType === 'high_school' ? `${this.profile.high_school_name}` : this.profile[`${sType}_u_name`],
                    show: this.isMe
                        ? sType === 'high_school'
                            ? `${this.profile.privacy_setting.show_high_school_name}`
                            : `${this.profile.privacy_setting[`show_${sType}_u_name`]}`
                        : null,
                    college: this.profile[`${sType}_c_name`],
                    major: this.profile[`${sType}_m_name`],
                    status: this.profile[`${sType}_status`],
                    start: this.profile[`${sType}_start`],
                    end: this.profile[`${sType}_end`],
                    show_name: this.profile.privacy_setting[`show_${sType}_u_name`],
                    // show_bachelor_u_name
                })
            })
            return items
        },
    },
    methods: {},
}
</script>

<template>
    <div class="product-pay">
        <div class="container">
            <section class="section m-b-28">
                <div class="title">결제 상품</div>
                <div class="grey-box">
                    <span>{{ selectedProduct.name }}</span>
                </div>
            </section>
            <UseCoupon />
            <ProductBill :selected-product="selectedProduct" class="m-t-28" />
            <SelectPayMethod @select="clickPayMethod" :disabled="!Number(this.price.price.replace(',', ''))" />
        </div>
        <!-- <RefundPolicy :refund-policy="refundPolicy" :type="category.category" /> -->
        <BottomButton :label="`${price.price}원 결제하기`" :disabled="disabled" @click="clickPay" />
    </div>
</template>

<script>
import productConstants from '@/assets/constants/products'
// import RefundPolicy from '@/routes/prepay/components/RefundPolicy'
import ProductBill from '@/routes/prepay/components/ProductBill'
import UseCoupon from '@/routes/prepay/components/UseCoupon'
import SelectPayMethod from '@/routes/prepay/components/SelectPayMethod'
import productService from '@/services/product'
import orderService from '@/services/order'

export default {
    name: 'TicketPaymentMethodsPage',
    components: { ProductBill, SelectPayMethod, UseCoupon },
    data: () => ({ order: null, selected: null }),
    mounted() {
        window.addEventListener('message', this.onMessageFromRN)
        document.addEventListener('message', this.onMessageFromRN)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessageFromRN)
        document.removeEventListener('message', this.onMessageFromRN)
    },
    computed: {
        selectedProduct() {
            return this.$store.getters.selectedProduct
        },
        price() {
            return this.$store.getters.price
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
        selectedPoint() {
            return this.$store.getters.selectedPoint
        },
        category() {
            return this.$store.getters.selectedPlan || {}
        },
        refundPolicy() {
            return productConstants.refundPolicy
        },
        label() {
            const price = this.$store.getters.price.price

            return `${price}원 결제하기`
        },
        disabled() {
            if (Number(this.price.price.replace(',', ''))) {
                return this.selected === null
            } else {
                return false
            }
        },
        isPostPaid() {
            return (this.selectedProduct || {}).plan_type === 'postpaid'
        },
        me() {
            return this.$store.getters.me || {}
        },
    },
    methods: {
        onMessageFromRN(event) {
            const data = this.$mustParse(event.data)
            if (!data || !data.action) return

            if (data.action === 'orderComplete') {
                this.$loading(true)
                setTimeout(() => {
                    this.checkOrder()
                }, 1000)
            }
        },
        async checkOrder() {
            // 결제 완료했을때 다음 플로우로 넘어가야함

            if (!this.order) return

            try {
                const { msg } = await orderService.get(this.order.id)
                await this.$store.dispatch('loadMe')
                await this.$store.dispatch('loadChats', true)
                this.$loading(false)

                // if (this.$store.getters.me.rate_plan_history) {
                //     localStorage.setItem('check-expire-notice', false)
                //     await this.$modal.basic({
                //         preventCloseOnMousedownMask: true,
                //         body: '추가로 구매하신 프로그램 이력은 마이페이지에서 확인하실 수 있습니다.',
                //         buttons: [
                //             {
                //                 label: '마이페이지로 이동',
                //                 class: 'btn-default',
                //             },
                //             {
                //                 label: 'CONFIRM',
                //                 class: 'btn-primary',
                //             },
                //         ],
                //     })
                // }
                // this.$router.push({ name: 'HomePage' })
                localStorage.setItem('order', JSON.stringify(this.order))
                this.$router.push({ name: 'CompletePaymentPage' })
                this.$toast.success(msg)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        clickPayMethod(id) {
            this.selected = id
        },
        async clickPay() {
            if (!Number(this.price.price.replace(',', ''))) {
                const { order } = await productService.createOrder(this.selectedProduct.id, {
                    payment_type: 'credit',
                    user_id: this.selectedCoupon ? this.me.id : null,
                    discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                    name: this.me.name,
                    phone_number: this.me.phone_number,
                    discount_point: this.selectedPoint,
                })
                localStorage.setItem('order', JSON.stringify(order))
                this.$store.dispatch('loadMe')
                this.$router.push({ name: 'CompletePaymentPage' })
            }
            if (this.selected === null) return
            if (this.selected === 0) {
                const payload = {
                    payment_type: 'credit',
                    user_id: this.selectedCoupon ? this.$store.getters.me.id : null,
                    discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                    name: this.$store.getters.me.name,
                    phone_number: this.$store.getters.me.phone_number,
                    discount_point: this.selectedPoint,
                }
                productService
                    .createOrder(this.selectedProduct.id, payload)
                    .then(({ order, checkout_page }) => {
                        this.order = order
                        this.$nativeBridge.postMessage({
                            action: 'pgPurchase',
                            value: checkout_page,
                        })
                    })
                    .catch(e => this.$toast.error(e.data.msg))
            } else {
                this.$router.push({ name: 'PayByAccountPage', params: { categoryName: this.category.category } })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.product-pay {
    .container {
        height: calc(100% - 80px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 16px 100px 16px;
    }
    ::v-deep.title {
        font-size: 16px;
        line-height: 26px;
        color: black;
        margin-bottom: 8px;
    }

    .grey-box {
        background: $grey-01;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
</style>

<template>
    <div class="time-left">
        <span class="used">
            {{ usedInfo }}
        </span>
        <span class="f-18"> / </span>
        <span class="f-18">
            {{ productInfo }}
        </span>
        <img
            v-if="Object.keys(productToDisplay).length"
            @click="infoModal"
            :src="require(`@/assets/images/icons/info.png`)"
            width="16px"
            height="16px"
        />
    </div>
</template>

<script>
export default {
    name: 'ProductToDisplay',
    props: {
        productToDisplay: Object,
        productDisplayedInfo: Object,
    },
    computed: {
        validTickets() {
            if (this.productDisplayedInfo.plan_type === 'UNLIMITED') return 0
            const { product } = this.productToDisplay
            const allTickets = this.$store.getters.validTickets

            return allTickets.filter(t => {
                return t.valid_until <= product.valid_until && t.valid_until >= product.valid_from
            })
        },
        usedInfo() {
            return this.productToDisplay.plan_type === 'UNLIMITED'
                ? `${
                      this.productDisplayedInfo.valid_date -
                      this.$moment(this.productToDisplay.product.valid_until).diff(
                          this.productToDisplay.status === 'expired'
                              ? this.$moment(this.productToDisplay.product.valid_until)
                              : this.$moment(),
                          'days'
                      )
                  }일`
                : `${this.productDisplayedInfo.amount - this.validTickets.length}회`
        },
        productInfo() {
            return this.productToDisplay.plan_type === 'UNLIMITED'
                ? `${this.productDisplayedInfo.valid_date}일`
                : `${this.productDisplayedInfo.amount}회`
        },
    },
    methods: {
        infoModal(e) {
            this.$emit('clickInfo', e)
        },
    },
}
</script>

<style scoped lang="scss">
.time-left {
    color: $grey-08;
    @include spoqa-f-bold;

    .used {
        font-size: 24px;
        color: black;
    }
}
</style>

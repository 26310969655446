import Vue from 'vue'
import { store as $store } from '@/store'

export const Profile = {
    install(Vue) {
        Vue.prototype.$profile = {
            requiredKey: [
                'age',
                'height',
                'state',
                'smoking',
                'health_status',
                'religion',
                'intro',
                'income',
                'asset',
                'family_relations',
                'parent_status',
                'family_asset',
                // 'family',
                // 'marry_plan',
                'child_plan',
                'career_plan',
            ],
            needProfileOption: key => {
                return [
                    'shape',
                    'mbti',
                    'religion',
                    'exercise_freq',
                    'drink_freq',
                    'house_style',
                    'house_payment',
                    // 'marry_plan',
                    'child_plan',
                    'career_plan',
                    'pet',
                    'drink_style',
                    'exercise_type',
                ].includes(key)
            },
            multipleChoice: key => ['pet', 'drink_style', 'exercise_type'].includes(key),
            async editState(key) {
                const profile = $store.getters.profile
                const title = key === 'state' ? 'PERSONAL' : 'FAMILY'
                const item = await Vue.prototype.$modal.custom({
                    component: 'ModalProfileItemSteps',
                    options: {
                        model: 'state',
                        headerTitle: Vue.prototype.$translate(title),
                    },
                })
                if (!item) return
                const prefix = key !== 'state' ? `${key}_` : ''
                profile[`${prefix}station_name`] = null
                profile[`${prefix}region_name`] = null

                const region = {}
                if (item[0].is_station) {
                    region[`${prefix}state`] = item[0]
                    region[`${prefix}state_id`] = item[0].id
                    region[`${prefix}station`] = item[1]
                    region[`${prefix}station_id`] = item[1].id

                    region[`${prefix}station_name`] = `${item[0].name} ${item[1].name}`
                } else {
                    region[`${prefix}state`] = item[0]
                    region[`${prefix}state_id`] = item[0].id
                    region[`${prefix}region`] = item[1]
                    region[`${prefix}region_id`] = item[1].id

                    region[`${prefix}region_name`] = `${item[0].name} ${item[1].district}`
                }

                $store.commit('setProfile', { ...$store.getters.profile, ...region })
            },
            needMoneyInput(key) {
                return ['family_asset', 'income', 'asset'].includes(key)
            },
            updateProfileOptions(key, item) {
                const profile = $store.getters.profile
                const currentProfileOptions = profile.profile_options
                if (this.multipleChoice(key)) {
                    for (let i = currentProfileOptions.length - 1; i >= 0; i -= 1) {
                        if (currentProfileOptions[i].ptype === key) {
                            currentProfileOptions.splice(i, 1)
                        }
                    }

                    item.forEach(i => {
                        currentProfileOptions.push({ profile_option_id: i.id, ptype: key })
                    })
                } else {
                    const index = currentProfileOptions.findIndex(po => po.ptype === key)

                    if (index < 0) {
                        currentProfileOptions.push({ profile_option_id: item.id, ptype: key, name: item.name })
                    } else {
                        currentProfileOptions[index].profile_option_id = item.id
                    }
                }

                $store.commit('setProfile', { ...profile, profile_options: currentProfileOptions })
            },
            required(key) {
                return this.requiredKey.indexOf(key) !== -1
            },
            setPrivacySettings(obj) {
                return $store.commit('setProfile', { ...$store.getters.profile, privacy_settings: obj })
            },
            moneyRangeParsed(value) {
                // if (String(value).length < 4) return '3천 ~ 4천'

                // const floor = Math.floor(value / 1000) * 1000
                if (!value) {
                    return '소득없음'
                } else if (value < 2000) {
                    return '2천만원 미만'
                } else if (value < 4000) {
                    return '2~3천만원대'
                } else if (value < 7000) {
                    return '4~6천만원대'
                } else if (value < 10000) {
                    return '7~9천만원대'
                } else if (value < 15000) {
                    return '1억 ~ 1억4천만원대'
                } else if (value < 20000) {
                    return '1억5천 ~1억9천만원대'
                } else if (value < 30000) {
                    return '2억원대'
                } else if (value < 40000) {
                    return '3억원대'
                } else {
                    return '4억원 이상'
                }
                // return `${Vue.prototype.$krwParsed(floor)} ~ ${Vue.prototype.$krwParsed(floor + 2000)}`
            },
            assetRangeParsed(value) {
                if (!value) {
                    return '자산없음'
                } else if (value < 10000) {
                    return '1억원 미만'
                } else if (value < 40000) {
                    return '1~3억원대'
                } else if (value < 70000) {
                    return '4~6억원대'
                } else if (value < 100000) {
                    return '7~9억원대'
                } else if (value < 200000) {
                    return '10억원대'
                } else if (value < 300000) {
                    return '20억원대'
                } else if (value < 400000) {
                    return '30억원대'
                } else if (value < 500000) {
                    return '40억원대'
                } else if (value < 1000000) {
                    return '50억~90억원대'
                } else {
                    return '100억 이상'
                }
            },
            jobFreePage: jobCategoryId => {
                return [1, 12, 13, 14, 16, 17].includes(jobCategoryId)
                // 1 : 대학생
                // 12 : 자영업/개인 사업자
                // 13 : 프리랜서
                // 14 : 기타
                // 16 : 무직/취업준비
                // 17 :  대학생
            },
            familyType: {
                0: { id: 0, name: 'father' },
                1: { id: 1, name: 'mother' },
                2: { id: 2, name: 'sibling' },
            },
            userEduStatus: {
                0: {
                    id: 0,
                    title: '졸업',
                    key: 'graduation',
                    highSchool: true,
                },
                1: {
                    id: 3,
                    title: '재학',
                    key: 'enrollment',
                },
                2: {
                    id: 1,
                    title: '수료',
                    key: 'completion',
                },
                3: {
                    id: 2,
                    title: '중퇴',
                    key: 'withdrawal',
                    highSchool: true,
                },
                4: {
                    id: 4,
                    title: '휴학',
                    key: 'absence',
                },
                5: {
                    id: 5,
                    title: '편입재학',
                    key: 'transfer_enrollment',
                },
                6: {
                    id: 6,
                    title: '편입졸업',
                    key: 'transfer_graduation',
                },
            },
            educationStatus: {
                0: { id: 0, title: '초등학교 졸업' },
                1: { id: 1, title: '중학교 졸업' },
                2: { id: 2, title: '고등학교 졸업' },
                3: { id: 3, title: '대학교 졸업' },
                4: { id: 4, title: '대학교 석사 졸업' },
                5: { id: 5, title: '대학교 박사 졸업' },
            },
            parentStatus: {
                0: { id: 0, title: '함께 거주' },
                1: { id: 1, title: '별거' },
                2: { id: 2, title: '이혼' },
                3: { id: 3, title: '사별' },
            },
            healthStatus: {
                0: { id: 0, title: '양호' },
                1: { id: 1, title: '좋지 않음' },
            },
            employmentType: {
                0: {
                    id: 0,
                    title: '정규직',
                    key: 'regular',
                },
                1: {
                    id: 1,
                    title: '계약직',
                    key: 'contract',
                },
                2: {
                    id: 2,
                    title: '파견직',
                    key: 'dispatch',
                },
                3: {
                    id: 3,
                    title: '위촉직',
                    key: 'commissioned',
                },
                4: {
                    id: 4,
                    title: '기타',
                    key: 'others',
                },
            },
            houseOwner: {
                0: {
                    id: 0,
                    name: '본인 소유',
                },
                1: {
                    id: 1,
                    name: '부모님 소유',
                },
                2: {
                    id: 2,
                    name: '형제 소유',
                },
            },
            productPlan: {
                9: {
                    id: 9,
                    name: '무료 매칭 체험권',
                },
                11: {
                    id: 11,
                    name: '기간제 1개월',
                },
                12: {
                    id: 12,
                    name: '기간제 3개월',
                },
                13: {
                    id: 13,
                    name: '기간제 6+3개월',
                },
                14: {
                    id: 14,
                    name: '횟수제 1회',
                },
                15: {
                    id: 15,
                    name: '횟수제 3회',
                },
                16: {
                    id: 16,
                    name: '횟수제 6+3회',
                },
            },
        }
    },
}

Vue.use(Profile)

<template>
    <div class="product-page" v-if="plans">
        <ChannelTalk :textStyle="{ color: 'black' }" :default-launcher="true" />
        <div class="photo-container">
            <img :src="require('@/assets/images/product-image/main-banner.png')" alt="" />
        </div>
        <div class="items">
            <h3 class="m-b-16">매칭 프로그램</h3>
            <ProductItem
                v-for="item in plans"
                :key="item.id"
                :category-item="item"
                @click.native="onClickCategory(item)"
            />
        </div>
    </div>
</template>

<script>
import ChannelTalk from '@/components/app/ChannelTalk'
import ProductItem from '@/routes/prepay/components/ProductItem'

export default {
    name: 'PrePaymentPage',
    components: { ChannelTalk, ProductItem },
    data: () => ({
        selected: null,
    }),
    methods: {
        async onClickCategory(category) {
            const res = await this.$needUpdate('1.0.2')
            if (!res) {
                this.$store.commit('setSelectedPlan', category)
                this.$router.push({
                    name: 'PrePaymentCategoryPage',
                    params: { categoryName: category.category, category },
                })
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
        plans() {
            return this.$store.getters.plans
        },
    },
}
</script>

<style scoped lang="scss">
.product-page {
    .photo-container {
        width: 100%;
        margin-bottom: 32px;

        img {
            width: 100%;
            height: auto;
        }
    }
    .items {
        padding: 0 16px;
    }
}
</style>

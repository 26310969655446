<template>
    <div class="profile-pass-accept">
        <p class="info">
            <strong>패스/수락</strong>을 선택하면, 다음 프로필<strong class="c-primary">({{ count }}개 남음)</strong>로
            이동합니다!
        </p>
        <div class="btns">
            <button
                v-if="shouldShowUndo"
                class="btn btn-brd"
                @click="onClickButton('undo')"
                v-html="$translate('UNDO')"
            />
            <button
                v-else-if="alreadyAccepted"
                class="btn btn-primary accepted-btn"
                :disabled="true"
                v-html="acceptCTA"
            />
            <template v-else>
                <button class="btn btn-brd" @click="onClickButton('rejected')" v-html="$translate('PASS')" />
                <button
                    class="btn btn-primary accepted-btn"
                    :class="{ premium: info }"
                    @click="onClickAccept"
                    v-html="acceptCTA"
                />
            </template>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ProfilePassAcceptButtons',
    props: ['count', 'message', 'user'],
    data: () => ({
        timer: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        shouldShowUndo() {
            return this.content.accept_status === 'rejected'
        },
        alreadyAccepted() {
            return this.content.accept_status === 'accepted'
        },
        info() {
            return (this.user.premium_infos || []).length > 0 || ((this.me || {}).premium_infos || []).length > 0
        },
        acceptCTA() {
            return this.$translate('INTRODUCE_ME')
        },
    },
    methods: {
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        async onClickButton(status) {
            try {
                const chat = this.$store.getters.chat
                await chatService.acceptProfile(chat.id, this.message.id, status)

                // 최초 수락 시 가이드페이지 추가
                // if (status === 'accepted') {
                //     const alreadyReadHowToUseFeat = localStorage.getItem('how-to-use')
                //     if (!alreadyReadHowToUseFeat) {
                //         await this.$modal.custom({
                //             component: 'ModalHowToUseFeats',
                //             options: {
                //                 preventCloseOnMousedownMask: true,
                //             },
                //         })
                //         localStorage.setItem('how-to-use', true)
                //     }
                // }

                const message = chat.$$messages.find(message => message.id === this.message.id)
                const content = this.$mustParse(message.content)

                if (status === 'undo') delete content.accept_status
                else {
                    content.accept_status = status
                }

                message.content = JSON.stringify(content)
                this.$emit('action', status)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        async onClickAccept() {
            const alert = await this.$modal.basic({
                body: '소개 받으시겠어요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (alert) {
                this.throttle(this.onClickButton('accepted'), 1000)
            }
        },
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
.profile-pass-accept {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info {
    margin: 0;
    margin-bottom: 12px;
    font-size: 12px;

    strong {
        font-family: NotoSans-Bold;
        font-weight: normal;
    }
}

.btns {
    display: flex;
    width: 100%;

    .accepted-btn {
        &.premium {
            background-image: $yellow-premium-grad !important;
            color: white !important;
        }
    }

    .btn-brd {
        color: $grey-08;
    }
}
</style>

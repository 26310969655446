<template>
    <div class="onboard-img-container">
        <div class="content">
            <div class="title">환영합니다!</div>
        </div>
        <div class="img-wrapper">
            <img :src="require('@/assets/images/onboard/onboard-welcome.png')" alt="" />
        </div>
        <div class="content">
            <div class="desc">
                <p>딱맞는 인연을 찾아드리기 위해서는</p>
                <p>간단한 몇가지 정보들이 필요해요.</p>
            </div>
        </div>
        <BottomButton @click="clickTempSignup" :blackBtn="true" :label="$translate('START')" />
    </div>
</template>

<script>
export default {
    name: 'OnBoardWelcomePage',
    methods: {
        clickTempSignup() {
            this.$router.push({ name: 'OnboardState' })
        },
    },
}
</script>
<style scoped lang="scss">
.onboard-img-container {
    width: 100%;
    height: calc(100vh - 81px) !important;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 100px 0;

    .img-wrapper {
        @include center;
        img {
            height: 280px;
            width: auto;
        }
    }

    .content {
        text-align: center;

        .title {
            font-size: 24px;
            margin-bottom: 8px;
            @include spoqa-f-bold;
        }
        .desc {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
</style>

import { render, staticRenderFns } from "./ProfilePassAcceptButtons.vue?vue&type=template&id=812e9e48&scoped=true&"
import script from "./ProfilePassAcceptButtons.vue?vue&type=script&lang=js&"
export * from "./ProfilePassAcceptButtons.vue?vue&type=script&lang=js&"
import style0 from "./ProfilePassAcceptButtons.vue?vue&type=style&index=0&id=812e9e48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "812e9e48",
  null
  
)

export default component.exports
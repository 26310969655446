<template>
    <div class="career-items">
        <div class="item-wrapper" :key="idx" v-for="(c, idx) in profile.company">
            <template>
                <div class="job-wrapper">
                    <div class="job-status">{{ isCurrent(c) }}</div>
                    <div class="job-title" v-if="!$profile.jobFreePage(c.job_category_id) && c.show_company_name === 1">
                        {{ c.company_name }}
                    </div>
                    <div class="job-title" v-if="$profile.jobFreePage(c.job_category_id)">
                        {{ c.job_category_name }}
                    </div>
                    <div class="job-title" v-if="c.show_company_name === 2">
                        {{ c.company_name_modified }}
                    </div>
                    <div class="job-description" v-if="!$profile.jobFreePage(c.job_category_id)">
                        <span class="seperator" v-for="(ci, idx) in companyItem(c)" :key="idx">
                            <span>{{ ci.value }}</span>
                            <span class="manager-only" v-if="showBadge(c, ci.key)">매니저만</span>
                        </span>
                    </div>

                    <div class="job-description">{{ c.company_task }}</div>
                    <div class="job-description" v-if="!$profile.jobFreePage(c.job_category_id)">
                        <span class="seperator" v-if="c.employment_type !== null">{{
                            $profile.employmentType[c.employment_type].title
                        }}</span>
                        <span class="seperator" v-if="c.region_shorten">{{ c.region_shorten }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
f

<script>
export default {
    name: 'DetailCareerItem',
    props: ['value', 'keys', 'profile', 'userId'],
    data: () => ({
        // profile: {},
        profileChanged: false,
    }),
    beforeDestroy() {
        if (this.profileChanged) this.$toast.success('SAVED')
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        // company_name 비공개 처리로직인데, 좀 더 기획 해봐야할 사항.
        // getCompanyName() {
        //     return (c) => {
        //         if (this.isMe) return c.company_name
        //         if (c.show_company_name===2) {
        //             const starLength = c.company_name.length -1
        //             return  c.company_name[0] + ('*'.repeat(starLength))
        //         }
        //         return c.company_name
        //     }
        // },

        companyItem() {
            return c => {
                const Item = [
                    {
                        key: 'job_category_name',
                        value: c.job_category_name,
                    },
                    {
                        key: 'company_department',
                        value: c.company_department,
                        selective: c.show_company_department,
                    },
                    {
                        key: 'job_name',
                        value: c.job_name,
                    },
                    {
                        key: 'company_rank',
                        value: c.company_rank,
                        selective: c.show_company_rank,
                    },
                ]
                return Item.filter(item => item.value)
            }
        },
    },
    methods: {
        // 직장명 부서 직급 세개선택적임
        isCurrent(c) {
            return parseInt(c.employment_status) ? '현직장' : '전직장'
        },

        showBadge(c, ci) {
            if (!this.isMe) return false
            return c[`show_${ci}`] !== 1 ? true : false
        },
    },
}
</script>
<style scoped lang="scss">
.seperator {
    white-space: nowrap;
}
.seperator:not(:last-child)::after {
    content: '\A';
    width: 4px;
    height: 4px;
    background-color: #94999e;
    border-radius: 100px;
    display: inline-block;
    padding: auto;
    vertical-align: middle;
    white-space: nowrap;
    // word-break:nowrap;
    margin: 0 7px;
}
</style>

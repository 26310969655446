<template>
    <div class="edit-profile-detail">
        <div class="title">이성을 만나는데<br />어떤 어려움이 있나요?</div>
        <div class="sub-title">
            <p class="c-grey-06 f-12">(복수 선택 가능)</p>
        </div>
        <div class="section">
            <div class="column">
                <div
                    class="item"
                    :class="{ selected: item.selected }"
                    v-for="item in items"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item.id)"
                />
            </div>
            <TextareaWithX
                @focus="onFocus"
                placeholder="편안한 마음으로 적어주세요."
                v-model="whyDifficultDetail"
                v-if="needInput"
                :is-input-mode="true"
            />
        </div>
        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />
    </div>
</template>

<script>
import signupConstant from '@/assets/constants/onboard-temp-signup'
import userService from '@/services/user'

export default {
    name: 'AppealPoint',
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        disabled() {
            return !this.items.some(item => item.selected)
        },
        needInput() {
            return this.items.some(item => item.id === 5 && item.selected)
        },
        preparedPayload() {
            if (!this.tempSignupPayload.response_id) return false

            const {
                marry,
                use_manager: useManager,
                state,
                why_difficult_ids: arr,
                response_id: responseId,
            } = this.tempSignupPayload
            const payload = {
                response_id: responseId,
                marry,
                use_manager: useManager,
                state_id: state.id,
                why_difficult_ids: arr,
            }

            if (this.needInput) payload.why_difficult_detail = this.whyDifficultDetail

            return payload
        },
    },
    data: () => ({
        items: null,
        whyDifficultDetail: null,
    }),
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                this.finish(newVal)
            },
            deep: true,
        },
    },
    methods: {
        onFocus() {
            const dom = document.querySelector('.edit-profile-detail')
            setTimeout(() => dom.scrollBy({ top: 99999, behavior: 'smooth' }), 400)
        },
        checkNiceData(niceData) {
            const checkStatus = ({ gender, status, email, token }) => {
                let errMsg = ''
                // if (status === 'too_young' || this.preparedPayload.marry === 1 || this.preparedPayload.state.id > 2) {
                //     this.$modal.basic({
                //         body: `<p>${
                //             gender === '1' ? '29' : '26'
                //         }세 이상부터 가입이 가능합니다.</p><p>나이조건 미달로 서비스 이용이 불가합니다.</p>`,
                //         buttons: [
                //             {
                //                 label: 'CONFIRM',
                //                 class: 'btn-primary',
                //             },
                //         ],
                //     })
                // }
                if (status.includes('duplicated')) {
                    this.$store
                        .dispatch('signInPhoneNumber', { email, token })
                        .then(() => this.$router.push({ name: 'ChatsPage' }))
                    return false
                }
                if (status === 'fail') errMsg = '본인 인증을 다시 시도해주세요.'

                if (errMsg) {
                    this.$toast.error(errMsg)
                    return false
                }
                return true
            }

            return checkStatus(niceData)
        },
        sendEvents() {
            const events = ['sendFirebaseEvent', 'sendAirbridgeEvent']
            events.forEach(e => {
                this.$nativeBridge.postMessage({
                    action: e,
                    value: {
                        category: `Complete_Signup`,
                    },
                })
            })
        },
        async finish(niceData) {
            this.$set(this.tempSignupPayload, 'response_id', niceData.response_id)
            localStorage.setItem('tempSignupPayload', JSON.stringify(this.tempSignupPayload))

            if (!this.checkNiceData(niceData)) return
            try {
                const { res, user, msg } = await userService.create(this.preparedPayload)
                if (res === 'success') {
                    this.sendEvents()
                    localStorage.removeItem('tempSignupPayload')
                    if (user.profile.status.includes('waitlist')) {
                        await this.$store.dispatch('signInPhoneNumber', { ...user, noRedirect: true })
                        this.$router.push({
                            name: 'WaitlistPage',
                            params: {
                                user: user,
                            },
                        })
                    } else {
                        await this.$store.dispatch('signInPhoneNumber', user)
                    }
                } else this.$toast.error(msg)
            } catch (e) {
                console.log(e)
            }
        },
        onClickItem(idx) {
            if (idx === 0) {
                this.items = this.items.map(item => ({
                    ...item,
                    selected: item.id === 0,
                }))
            } else {
                this.items = this.items.map(item => ({
                    ...item,
                    selected: item.id === idx ? !item.selected : item.id === 0 ? false : item.selected,
                }))
            }
        },
        async next() {
            const arr = []
            this.items.forEach(item => {
                if (item.selected) arr.push(item.id)
            })
            const payload = { ...this.tempSignupPayload, why_difficult_ids: arr }
            this.$set(this.tempSignupPayload, 'why_difficult_ids', arr)
            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))

            const idx = await this.$modal.basic({
                title: '마지막 본인인증을 진행해주세요!',
                body: '본인확인을 위한 인증입니다.<br>인증이 끝나면 상담 정보입력이 완료됩니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `Popup_Verif`,
                    },
                })
                this.$nativeBridge.postMessage({ action: 'verification' })
            }
        },
    },
    mounted() {
        this.items = signupConstant.whyDifficult.map(item => ({
            ...item,
            selected: (this.tempSignupPayload.why_difficult_ids || []).some(idx => item.id === idx),
        }))
        if (this.tempSignupPayload.why_difficult_ids === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_Difficulty`,
                },
            })
        }
    },
}
</script>

<style scoped></style>

<template>
    <div class="header-bar">
        <transition name="slide-down">
            <div v-if="customHeader" v-show="show" @click.stop="customHeader.handler" class="custom-header">
                <img
                    class="m-r-2"
                    :src="require(`@/assets/images/icons/${customHeader.icon}.png`)"
                    width="24px"
                    height="24px"
                />
                <span>{{ customHeader.label }}</span>
            </div>
            <div v-else v-show="show" class="title">
                <img
                    class="img-profile"
                    :src="photos[0].url || photos[0] || $blankProfile"
                    @error="$event.target.src = $blankProfile"
                />
                {{ user.profile.job_name }}, {{ $options.filters.asAge(user.profile.birthday) }}
            </div>
        </transition>
        <div v-if="!customHeader || !show" @click="$stackRouter.pop()" class="left">
            <!-- <i class="material-icons" :class="{ 'c-white': !show }">arrow_back</i> -->
            <img class="m-r-2" :src="require(`@/assets/images/icons/back_white.png`)" width="24px" height="24px" />
        </div>
        <!--        <div v-if="!isMe" class="right" @click="onClickReport">-->
        <!--            <i class="material-icons" :class="{ 'c-white': !show }">more_horiz</i>-->
        <!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'UserDetailHeaderBar',
    props: {
        from: String,
        user: Object,
        userId: Number,
        show: Boolean,
        photos: Array,
        nickname: String,
        customHeader: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isMe() {
            return this.userId === this.me.id
        },
        nameOrNickname() {
            return this.user.name
        },
    },
    methods: {
        onClickReport() {
            this.$emit('open')
        },
    },
}
</script>

<style lang="scss" scoped>
.header-bar {
    $header-height: 52px;
    position: sticky;
    left: 0;
    right: 0;
    z-index: 10;
    border-bottom: none;

    .title {
        position: fixed;
        height: $header-height;
        top: 0;
        left: 0;
        right: 0;
        color: black;
        background: white;
        border-bottom: 1px solid $grey-02;
        z-index: inherit;

        @include center;
        @include f-medium;

        .img-profile {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }

    .custom-header {
        @extend .title;
        display: flex;
        justify-content: flex-start;
        padding: 14px 16px;
        img {
            margin-right: 8px;
        }
    }

    .left,
    .right {
        z-index: inherit;
        position: fixed;
        top: 0;
        height: $header-height;
        width: $header-height;

        @include center;
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .material-icons {
        color: $grey-08;

        &.c-white {
            color: white;
        }
    }
}
</style>

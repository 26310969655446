<template>
    <div class="message flex-row" :class="[me ? 'justify-end' : '', friendType]">
        <div class="timestamp flex-wrap" v-if="showTimestamp && me">
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
        <MessageAction v-if="message.mtype !== 'text'" class="flex-wrap" :message="message" />
        <MessageText v-if="message.mtype === 'text'" class="flex-wrap" :message="message" />
        <div class="timestamp flex-wrap" v-if="showTimestamp && !me">
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
    </div>
</template>
<script>
import MessageAction from './MessageAction'
import MessageText from './MessageText'

export default {
    name: 'Message',
    props: ['message', 'me'],
    components: {
        MessageAction,
        MessageText,
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        friendType() {
            const friendType = this.chat.expire_at ? 'recommend' : 'normal'

            this.$set(this.message, '$$friendType', friendType)
            return friendType
        },
        showTimestamp() {
            return (
                [
                    'multi-photo',
                    'profile-photo',
                    'photo',
                    'contact-done-sender',
                    'contact-done-receiver',
                    'gift-info',
                ].indexOf(this.message.mtype) === -1
            )
        },
    },
}
</script>

import { render, staticRenderFns } from "./StartWithIdForTest.vue?vue&type=template&id=6362f6ee&scoped=true&"
import script from "./StartWithIdForTest.vue?vue&type=script&lang=js&"
export * from "./StartWithIdForTest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6362f6ee",
  null
  
)

export default component.exports
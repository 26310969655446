<template>
    <div class="basic-profile" v-if="marriageProfile.length">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/Union.png" width="24px" height="24px" />
            <span>결혼가치관</span>
        </h3>
        <div style="z-index: 2" class="items" v-for="item in marriageProfile" :key="item.id">
            <div class="item" v-if="item.value">
                <template>
                    <div class="key" v-html="$translate(item.key.toUpperCase())" />
                    <div class="value">
                        <span class="content">{{ item.value }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarriageProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    computed: {
        getTitle() {
            return `<i class="material-icons">favorite</i> 결혼가치관`
        },
        marriageProfile() {
            const items = [
                // {
                //     id: 1,
                //     key: 'marry_plan',
                //     value: this.profile.marry_plan,
                // },
                {
                    id: 2,
                    key: 'child_plan',
                    value: this.profile.child_plan,
                },
                {
                    id: 3,
                    key: 'career_plan',
                    value: this.profile.career_plan,
                },
            ]

            return items.filter(item => item.value)
        },
    },
}
</script>
<style scoped lang="scss">
.careerTitle {
    width: 200px;
}
</style>

<template>
    <div class="product-detail">
        <div class="content">
            <div
                class="photo-container"
                v-lazy:background-image="require(`@/assets/images/product-image/${category.category}.png`)"
            >
                <div class="back-button-wrapper" @click="$router.go(-1)">
                    <img
                        class="m-r-2 back-button"
                        :src="require('@/assets/images/icons/back_white.png')"
                        width="24px"
                        height="24px"
                    />
                </div>
            </div>
            <ProductInfo :category="category" />
            <ProductSpecific :category="category.category" />
        </div>
        <BottomButton label="가입하기" @click="clickBtn" />
    </div>
</template>

<script>
import ProductInfo from '@/routes/prepay/components/ProductInfo'
import ProductSpecific from '@/routes/prepay/components/ProductSpecific'

export default {
    name: 'PrePaymentCategoryPage',
    components: { ProductInfo, ProductSpecific },
    computed: {
        category() {
            return this.$store.getters.selectedPlan
        },
    },
    methods: {
        async clickBtn() {
            const selected = await this.$modal.custom({
                component: 'ModalSelectPlan',
                options: {
                    category: this.category.category,
                },
            })
            if (selected) {
                this.$store.commit('setSelectedProduct', selected)
                this.$router.push({ name: 'PaymentMethodsPage', params: { categoryName: this.category.category } })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.product-detail {
    overflow-x: hidden;
    .content {
        height: calc(100% - 80px);
        overflow-y: auto;
    }
    .photo-container {
        width: 100%;
        height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;

        .back-button-wrapper {
            display: flex;
            padding: 16px;
        }
    }
}
</style>

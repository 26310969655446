<template>
    <div class="signup basics phone-number">
        <QnaHeader :whiteMode="true" />
        <StartWithIdForTest v-if="$isTestEnv()" />
        <div class="content" v-else>
            <div class="title">휴대폰 번호로 로그인하세요</div>
            <div class="body">
                <div class="upper">
                    <div class="input-wrapper input-phone-number" :class="{ 'border-primary': !showButton }">
                        <input
                            ref="phoneNumber"
                            type="tel"
                            class="flex-fill"
                            :placeholder="$translate('PLACEHOLDER_PHONENUMBER')"
                            v-model="phoneNumber"
                            maxlength="13"
                            @input="onPhoneNumberKeydown()"
                            @keydown.backspace="isClickBackSpace"
                            @focus="onFocus"
                            @blur="onBlur"
                        />
                        <i v-if="phoneNumber && !sent" class="material-icons" @click="phoneNumber = ''">cancel</i>
                    </div>
                    <div v-if="sent" class="btn-resend" @click="sendVerificationCode" v-html="'다시 받기'"></div>
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    전화번호 형식이 올바르지 않습니다
                </div>

                <div v-if="sent" class="sent">
                    <div
                        class="flex-row input-wrapper input-code-number"
                        :class="{ 'border-primary': !showButtonCode }"
                    >
                        <input
                            v-model="code"
                            ref="code"
                            type="number"
                            placeholder="인증번호 4자리 입력"
                            maxlength="6"
                            class="flex-fill"
                            @focus="onFocusCode"
                            @blur="onBlurCode"
                            @input="onCodeKeydown()"
                        />
                    </div>
                    <div class="left-seconds">
                        <span v-html="`${humanizeLeftSeconds}`" />
                    </div>
                </div>
                <button
                    v-if="sent"
                    class="m-t-40 btn btn-black"
                    :class="{ disabled: disabledStart }"
                    @click="confirm"
                    v-html="'시작하기'"
                ></button>
                <button
                    v-else
                    class="m-t-40 btn btn-black"
                    :class="{ disabled: disabled, error: error }"
                    @click="sendVerificationCode"
                    v-html="'인증번호 받기'"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'
import StartWithIdForTest from '@/routes/signup/components/StartWithIdForTest'

export default {
    name: 'StartWithPhonePage',
    components: { StartWithIdForTest },
    data: () => ({
        error: null,
        disabled: true,
        disabledStart: true,
        isBackSpace: false,
        sent: false,
        code: null,
        phoneNumber: null,
        email: null,
        interv: null,
        leftSeconds: null,
        showButton: true,
        showButtonCode: true,
        terms: null,
    }),
    computed: {
        humanizeLeftSeconds() {
            const minutes = '0' + Math.floor(this.leftSeconds / 60).toString()
            let seconds = Math.floor(this.leftSeconds % 60).toString()
            seconds = seconds.length === 2 ? seconds : `0${seconds}`

            return `남은시간 ${minutes}:${seconds}`
        },
    },
    mounted() {
        this.phoneNumber = this.$store.getters.payloads.signupData.phoneNumber
        this.$refs.phoneNumber.focus()
        this.checkPhoneNumberFormat(this.phoneNumber)
    },
    methods: {
        onPhoneNumberKeydown() {
            setTimeout(() => {
                if (this.isBackSpace) {
                    this.phoneNumber = this.$refs.phoneNumber.value.substring(0, this.phoneNumber.length)
                } else {
                    if (this.$refs.phoneNumber.value.length === 9) {
                        this.phoneNumber =
                            this.$refs.phoneNumber.value.substring(0, 8) +
                            '-' +
                            this.$refs.phoneNumber.value.substring(8)
                    } else if (this.$refs.phoneNumber.value.length === 4) {
                        this.phoneNumber =
                            this.$refs.phoneNumber.value.substring(0, 3) +
                            '-' +
                            this.$refs.phoneNumber.value.substring(3)
                    } else {
                        this.phoneNumber = this.$refs.phoneNumber.value
                    }
                }

                this.isBackSpace = false

                this.checkPhoneNumberFormat()

                if (this.$refs.phoneNumber.value.length >= this.$refs.phoneNumber.maxLength) {
                    this.$refs.phoneNumber.blur()
                }
            })
        },
        onCodeKeydown() {
            setTimeout(() => {
                if (this.$refs.code.value.length > this.$refs.code.maxLength) {
                    this.code = this.$refs.code.value.slice(0, this.$refs.code.maxLength)
                } else {
                    this.code = this.$refs.code.value
                }

                this.checkCodeFormat()
            })
        },
        isClickBackSpace() {
            this.isBackSpace = true
        },
        checkPhoneNumberFormat() {
            if (!this.phoneNumber) {
                this.error = null
                this.disabled = true
                return
            }

            // const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            const regValidPhone = /[0-9]{3}-[0-9]{4}-[0-9]{4}/
            // console.log(this.phoneNumber)
            if (!regValidPhone.test(this.phoneNumber.trim())) {
                this.error = '전화번호 형식이 올바르지 않습니다'
                this.disabled = true
                return
            }

            this.error = null
            this.disabled = false
        },
        checkCodeFormat() {
            if (!this.code) {
                // this.error = null
                this.disabledStart = true
                return
            }

            // const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            const regValidCode = /[0-9]{4}/
            if (!regValidCode.test(this.code)) {
                // this.error = '전화번호 형식이 올바르지 않습니다'
                this.disabledStart = true
                return
            }

            // this.error = null
            this.disabledStart = false
        },

        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        async confirm() {
            if ((this.code || '').length < 4) {
                this.$alert('인증번호 형식이 올바르지 않습니다. 숫자 4자리를 입력해주세요.')
                return
            }

            this.$loading(true)
            await this.initTerms()

            try {
                const data = await userService.verificationCode.confirm({
                    code: this.code,
                    phone_number: this.phoneNumber.replace(/-/g, ''),
                    service_terms_version: this.terms.service.version,
                    privacy_terms_version: this.terms.privacy.version,
                    privacy_third_terms_version: this.terms.privacy_third.version,
                    optional_consent: 0,
                    // device_id: this.$store.getters.device.id,
                    device_id: null,
                })

                this.$loading(false)
                if (!data.enable_login) {
                    const res = await this.$modal.basic({
                        body: '가입된 계정이 없습니다. 회원가입을 진행하시겠습니까? ',
                        buttons: [
                            {
                                label: '아니오',
                                class: 'btn-default',
                            },
                            {
                                label: '예',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (res) {
                        this.$router.push({ name: 'VerifyMaleConditionPage' })
                    }
                    return
                }
                if (data.msg === 'OK') {
                    await this.$store.dispatch('signInPhoneNumber', {
                        email: data.user.email,
                        token: data.user.token,
                        productId: data.user.product_id,
                    })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
        async sendVerificationCode() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SMSVerifPage_Click_GetVerifNum',
                },
            })
            if ((this.phoneNumber || '').length < 11 || !(this.phoneNumber || '').startsWith('0')) {
                this.$alert('휴대폰 번호 형식이 올바르지 않습니다. 01012341234 형식으로 입력해주세요.')
                return
            }

            try {
                const data = await userService.verificationCode.create({
                    phone_number: this.phoneNumber.replace(/-/g, ''),
                })
                this.$toast.success(data)
                this.sent = true
                this.leftSeconds = 180

                if (this.interv) {
                    clearInterval(this.interv)
                    this.interv = null
                }

                this.interv = setInterval(() => {
                    if (this.leftSeconds <= 0) {
                        clearInterval(this.interv)
                        this.leftSeconds = null
                        this.interv = null
                        this.sent = false
                        this.code = null
                        return
                    }
                    this.leftSeconds--
                }, 1000)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            setTimeout(() => {
                this.showButton = true
            }, 100)
        },
        onFocusCode() {
            this.showButtonCode = false
        },
        onBlurCode() {
            setTimeout(() => {
                this.showButtonCode = true
            }, 100)
        },
    },
}
</script>

<style lang="scss" scoped>
.phone-number {
    input {
        &::placeholder {
        }
    }
    .content {
        .title {
            margin-top: 32px;
            text-align: center;
            font-size: 16px;
        }
        .body {
            .upper {
                display: flex;

                .input-phone-number {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid $grey-03;
                    padding: 8px 0;
                    width: 100%;
                    //flex: auto;

                    &.border-primary {
                        border-bottom: 1px solid $blue-primary;
                    }
                }
                .btn-resend {
                    margin-left: 20px;
                    border: 1px solid $grey-03;
                    border-radius: 8px;
                    opacity: 0.9;
                    width: 150px;
                    @include center;
                    //display: inline-block;
                    //flex: auto;
                    padding: 12px;
                }
            }
            .input-code-number {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey-03;
                padding: 8px 0;
                margin-top: 12px;

                &.border-primary {
                    border-bottom: 1px solid $blue-primary;
                }
            }

            .sent {
                .left-seconds {
                    margin-top: 8px;
                    margin-left: 8px;
                    color: $grey-06;
                    font-size: 14px;
                }
            }

            .btn {
                &.error {
                    margin-top: 12px !important;
                }
            }
        }
        .signup-link {
            margin-top: 16px;
            color: $blue-primary;
            text-decoration: underline;
        }
    }
}
</style>

<template>
    <div class="textarea-with-x textarea-wrapper flex-row" :class="{ focus, isInputMode, disabled, error }">
        <input
            v-if="isInputMode"
            ref="textarea"
            v-model="text"
            :class="btn ? '' : 'flex-fill'"
            :placeholder="placeholder"
            :type="type || 'text'"
            :readonly="disabled"
            @focus="onFocus"
            @blur="onBlur"
            @input="onKeyUp"
        />
        <textarea
            v-else
            ref="textarea"
            v-model="text"
            class="flex-fill"
            :rows="textareaRows"
            :placeholder="placeholder"
            :readonly="disabled"
            @focus="onFocus"
            @blur="onBlur"
            @input="onKeyUp"
        />
        <img
            v-if="(text || '').length > 0 && !btn"
            @click="clear"
            class="flex-wrap"
            :src="require(`@/assets/images/icons/delete.png`)"
            width="24px"
            height="24px"
        />
        <div v-if="btn" class="register" :class="[btn.class, btn.disabled ? 'disabled' : '']" @click="clickBtn(btn)">
            {{ btn.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextareaWithX',
    props: ['type', 'value', 'placeholder', 'from', 'isInputMode', 'textareaRows', 'btn', 'disabled', 'error'],
    data: () => ({
        text: '',
        focus: false,
    }),
    mounted() {
        setTimeout(() => {
            this.text = this.value
        })
    },
    watch: {
        value: {
            handler() {
                // this.clear()
                this.text = this.value
            },
            deep: true,
        },
    },
    methods: {
        clickBtn() {
            this.$emit('click')
        },
        onKeyUp() {
            setTimeout(() => {
                this.text = this.$refs.textarea.value
                console.log(this.text)
                this.$emit('input', this.text)
            })
        },
        clear() {
            this.text = ''
            this.$emit('input', this.text)
        },
        onFocus() {
            if (this.disabled) return

            this.focus = true
            this.$emit('focus')
        },
        onBlur() {
            if (this.disabled) return

            this.focus = false
            this.$emit('blur')
        },
    },
}
</script>
<style scoped lang="scss">
.register {
    border-radius: 8px;
    font-size: 14px;
    padding: 4px 12px;
    width: fit-content;
    min-width: 20%;
    line-height: 24px;
    @include center;

    &.disabled {
        background: $grey-02 !important;
        color: $grey-07 !important;
    }
}
</style>

<template>
    <div class="uid-login content">
        <div class="title f-16 text-center">착한 사람한테만 보여요☺️</div>
        <div class="body">
            <div class="item m-b-8">
                <div class="label f-13 c-black spoqa-f-medium m-b-4">아이디</div>
                <TextareaWithX type="tel" v-model="uid" :is-input-mode="true" />
            </div>
            <div class="item m-b-32">
                <div class="label f-13 c-black spoqa-f-medium m-b-4">비밀번호</div>
                <TextareaWithX v-model="password" type="password" :is-input-mode="true" />
            </div>
        </div>
        <BottomButton
            @click="login"
            :disabled="disabled"
            :black-btn="true"
            :non-fixed="true"
            :label="$translate('LOGIN')"
        />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'StartWithIdForTest',
    data: () => ({
        password: null,
        uid: null,
    }),
    computed: {
        disabled() {
            return !(this.password && this.uid)
        },
    },
    methods: {
        async login() {
            this.$loading(true)
            try {
                const data = await userService.verificationCode.confirm({
                    code: this.password,
                    phone_number: this.uid,
                })
                if (data.msg === 'OK') {
                    await this.$store.dispatch('signInPhoneNumber', {
                        email: data.user.email,
                        token: data.user.token,
                        productId: data.user.product_id,
                    })
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style scoped lang="scss"></style>

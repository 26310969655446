<template>
    <transition name="modal">
        <div class="modal" v-show="!transparent" v-if="!preventModal" @mousedown="closeOnMousedownMask">
            <div
                :class="
                    ['ModalBasic', 'ModalForceCheck', 'ModalBadgeDeclined', 'ModalForceFeedback'].includes(
                        modal.component
                    )
                        ? 'modal-container-basic'
                        : modal.component === 'ModalSelectDate'
                        ? 'modal-container-calendar'
                        : modal.component === 'ModalContact'
                        ? 'modal-container-contact'
                        : ['ModalHowToUseFeats'].includes(modal.component)
                        ? 'modal-black-screen'
                        : modal.component === 'ModalCompanyAddInfo'
                        ? 'modal-bottom-sheet'
                        : 'modal-container'
                "
            >
                <component @close="onClose" :options.sync="modal.options" :is="modal.component" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: ['modal'],
    data: () => ({
        transparent: false,
    }),
    components: {
        ModalBasic: () => import('@/components/modals/ModalBasic'),
        ModalImages: () => import('@/components/modals/ModalImages'),
        ModalAlert: () => import('@/components/modals/ModalAlert'),
        ModalActionSheet: () => import('@/components/modals/ModalActionSheet'),
        ModalCropper: () => import('@/components/modals/ModalCropper'),
        ModalNotice: () => import('@/components/modals/ModalNotice'),
        ModalProfileItemSelector: () => import('@/components/modals/ModalProfileItemSelector'),
        ModalPremiumInfo: () => import('@/components/modals/ModalPremiumInfo'),
        ModalEditStyleType: () => import('@/components/modals/ModalEditStyleType'),
        ModalDropout: () => import('@/components/modals/ModalDropout'),
        ModalSelectEnv: () => import('@/components/modals/ModalSelectEnv'),
        ModalRequestAppReview: () => import('@/components/modals/ModalRequestAppReview'),
        ModalAlertCustom: () => import('@/components/modals/ModalAlertCustom'),
        ModalContact: () => import('@/components/modals/ModalContact'),
        ModalAlertNoX: () => import('@/components/modals/ModalAlertNoX'),
        ModalRegionStation: () => import('@/components/modals/ModalRegionStation'),
        ModalForceCheck: () => import('@/components/modals/ModalForceCheck'),
        ModalMoneyInput: () => import('@/components/modals/ModalMoneyInput'),
        ModalSearchList: () => import('@/components/modals/ModalSearchList'),
        ModalCompanyAddInfo: () => import('@/components/modals/ModalCompanyAddInfo'),
        ModalSignPad: () => import('@/components/modals/ModalSignPad'),

        ModalCollegeSearch: () => import('@/components/modals/ModalCollegeSearch.vue'),
        ModalBlockInput: () => import('@/components/modals/ModalBlockInput'),
        ModalProfileItemSteps: () => import('@/components/modals/ModalProfileItemSteps'),
        ModalHowToUsePoint: () => import('@/components/modals/ModalHowToUsePoint'),
        ModalHowToUseFeats: () => import('@/components/modals/ModalHowToUseFeats'),

        // 약속잡기
        ModalSelectDate: () => import('@/components/modals/ModalSelectDate'),
        ModalScheduleAlert: () => import('@/components/modals/ModalScheduleAlert'),
        ModalMeetingPlaceInput: () => import('@/components/modals/ModalMeetingPlaceInput'),

        // 인증센터
        ModalCertificate: () => import('@/components/modals/ModalCertificate'),
        ModalBadgeDeclined: () => import('@/components/modals/ModalBadgeDeclined'),

        // profile 수정 커리어 테스트
        ModalCategoySelector: () => import('@/components/modals/ModalCategorySelector'),
        ModalVerticalButtons: () => import('@/components/modals/ModalVerticalButtons'),

        ModalPayTicket: () => import('@/components/modals/ModalPayTicket'),
        ModalSelectPlan: () => import('@/components/modals/ModalSelectPlan'),
        ModalNoticeExpire: () => import('@/components/modals/ModalNoticeExpire'),

        // 피드백
        ModalForceFeedback: () => import('@/components/modals/ModalForceFeedback'),
    },
    created() {
        document.addEventListener('keydown', this.onKeydown)

        // this.$nativeBridge.postMessage({
        //     action: 'setBackgroundColor',
        //     value:
        //         ['ModalProfileItemSelector', 'ModalEditStyleType', 'ModalNotice'].indexOf(this.modal.component) > -1
        //             ? '#FFFFFF'
        //             : '#666666',
        // })

        this.$nativeBridge.postMessage({
            action: 'setCanGoBack',
            value: true,
        })
    },
    beforeDestroy() {
        this.modal.resolve()
        document.removeEventListener('keydown', this.onKeydown)
        // this.$nativeBridge.postMessage({
        //     action: 'setBackgroundColor',
        //     value: '#FFFFFF',
        // })
        if ((this.modal.options || {}).prevRouter) {
            this.$nativeBridge.postMessage({
                action: 'setCanGoBack',
                value:
                    [
                        'HomePage',
                        'FrontPage',
                        'ChatsPage',
                        'MyDatingPage',
                        'MyPage',
                        'SystemMaintenanceNoticePage',
                    ].indexOf(this.prevRouter) !== -1,
            })
        }
    },
    computed: {
        preventModal() {
            // Modal 뜨는 걸 방지하는 페이지들
            return ['NewTermsPage'].includes(this.$route.name)
        },
    },
    methods: {
        onClose(event) {
            if ((this.modal.options || {}).preventClose) return
            this.transparent = true

            if (this.modal.resolve) {
                this.modal.resolve(event)
            }

            this.$store.commit('popModal')
        },
        closeOnMousedownMask(event) {
            if ((this.modal.options || {}).preventCloseOnMousedownMask) return
            if (this.modal.component === 'ModalIntroduction') {
                window.localStorage.setItem('readIntroduction', 'true')
            }

            if (event.target.classList.contains('modal')) {
                this.onClose()
            }
        },
        onKeydown(event) {
            if (event.key === 'Escape') {
                this.onClose()
            }
        },
    },
}
</script>

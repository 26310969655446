<template>
    <section class="refund-policy" :class="{ hidden: !show }">
        <div class="policy-header spoqa-f-bold" @click="expandOptions">
            <span class="m-r-4">{{ refundPolicy.title }}</span>
            <i class="m-l-8 material-icons">{{ show ? 'expand_more' : 'expand_less' }}</i>
        </div>
        <div v-if="show" class="content">
            <div class="item" v-for="item in refundPolicy.content[type]" :key="`refund-policy-${item.id}`">
                <div class="top">
                    <div class="dot m-r-4" />
                    <div v-html="item.title" />
                </div>
                <div class="bottom">
                    <p v-html="item.body" />
                </div>
                <!-- <div class="bottom" v-for="(desc, i) in item.desc" :key="`refund-policy-item-${i}`">
                    <p v-html="desc" />
                </div> -->
            </div>
            <div class="item" v-for="item in refundPolicy.notice[type]" :key="`refund-policy-${item.id}`">
                <div class="notice">
                    {{ item }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'RefundPolicy',
    props: ['refundPolicy', 'type'],
    data: () => ({
        show: false,
        channelTalk: document.querySelector('#ch-plugin-core > div'),
    }),
    watch: {
        show(newVal) {
            if (newVal === true) {
                this.channelTalk.style.transition = 'right 1s ease-in-out'
                this.channelTalk.style.setProperty('right', '16px', 'important')
            } else {
                this.channelTalk.style.transition = 'right 1s ease-in-out'
                this.channelTalk.style.setProperty('right', '64px', 'important')
            }
        },
    },
    mounted() {
        const channelTalk = this.channelTalk

        channelTalk.style.transition = 'right 1s ease-in-out'
        channelTalk.style.setProperty('right', '64px', 'important')
    },
    beforeDestroy() {
        this.channelTalk.style.transition = 'right 1s ease-in-out'
        this.channelTalk.style.setProperty('right', '16px', 'important')
    },
    methods: {
        expandOptions() {
            this.show = !this.show
            const dom = document.querySelector('.product-pay > .container')
            setTimeout(() => dom.scrollBy({ top: this.show ? 9999 : 0, behavior: 'smooth' }), 200)
        },
    },
}
</script>

<style scoped lang="scss">
.refund-policy {
    padding: 16px;
    color: $grey-09;
    background: $grey-01;
    font-size: 12px;
    position: fixed;
    bottom: 80px;
    left: 0;
    right: 0;
    z-index: 10;

    &.hidden {
        height: 56px;
    }

    .policy-header {
        padding: 0;
        display: flex;
        align-items: center;
    }

    .item {
        line-height: 20px;
        margin-bottom: 8px;

        .top {
            display: flex;
        }
        .bottom {
            padding-left: 8px;
            margin-bottom: 2px;
        }
        .notice::before {
            content: '※';
        }
    }
}
</style>

<template>
    <div class="basic-profile" v-if="profile.only_to_manager">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/manager-talk.png" width="24px" height="24px" />
            <span>매니저에게만 살짝!</span>
            <span class="manager-only">매니저만</span>
        </h3>
        <SelfIntroduction :intro="profile.only_to_manager" />
    </div>
</template>

<script>
import SelfIntroduction from '@/routes/user-detail/components/SelfIntroduction'

export default {
    name: 'OnlyToManager',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    components: {
        SelfIntroduction,
    },
}
</script>
<style scoped lang="scss"></style>

<template>
    <div class="native-bridge"></div>
</template>

<script>
import axios from 'axios'
import userService from '@/services/user'
import commonService from '@/services/common'

export default {
    name: 'NativeBridge',
    mounted() {
        document.addEventListener('message', this.onNativeMessage) // Android
        window.addEventListener('message', this.onNativeMessage) // iOS
    },
    beforeDestroy() {
        document.removeEventListener('message', this.onNativeMessage) // Android
        window.removeEventListener('message', this.onNativeMessage) // iOS
    },
    methods: {
        onNativeMessage(event) {
            const data = this.$mustParse(event.data)

            if (!data || !data.action) return
            const httpHeader = this.$http.defaults.headers.common

            switch (data.action) {
                case 'registration': {
                    this.$store.commit('setDeviceToken', {
                        role: 'user',
                        platform: data.platform,
                        registration_id: data.registration_id,
                        adid: data.adid,
                    })
                    const payload = {
                        platform: this.$store.getters.deviceToken.platform,
                        registration_id: this.$store.getters.deviceToken.registration_id,
                        page_status: 0,
                    }
                    commonService.create(payload)

                    break
                }
                case 'niceData':
                    this.$store.commit('setNiceData', data.params)
                    break
                case 'resetPassword':
                    this.$bus.$emit('resetPassword', data.url)
                    break
                case 'setDevice':
                    if (data.device) {
                        this.setDeviceAndHeader(data.device, httpHeader)
                        this.$store.commit('setDevice', data.device)
                    }
                    break
                case 'toast':
                    this.$toast[data.value.type](data.value.message)
                    break
                case 'changeAppState':
                    if (data.value === 'foreground') {
                        const callbacks = async () => {
                            await this.$store.dispatch('afterAuthCallbacks')
                            // 현재 라우트가 chatroom이 아닌데, 스토어에는 chat 값이 남아 있으면 해당 chat을 다시 불러옴
                            if (this.$store.getters.chat && this.$route.name !== 'ChatroomPage') {
                                this.$store.dispatch('loadChat', {
                                    chatId: this.$store.getters.chat.id,
                                    force: true,
                                })
                            } else {
                                // 현재 chatroom이면 메시지 다시 불러오기
                                this.$bus.$emit('reloadMessage')
                            }
                        }

                        const firstLoad = this.$store.getters.isAppFirstLoaded
                        setTimeout(() => {
                            if (!firstLoad) {
                                this.$bus.$emit('onRequestReconnect')
                            }
                            callbacks()
                        }, 200)
                    } else if (data.value === 'background') {
                        this.$bus.$emit('onRequestDisconnect')
                        this.$store.commit('setIsAppFirstLoaded', false)
                    }

                    this.$updateAppIconBadgeCount()
                    break
                case 'openPushNotification': {
                    this.onPushNotifications(data.value)
                    break
                }
                case 'checkInAppPurchase': {
                    this.$store.dispatch('purchaseInAppProduct', data.value)
                    break
                }
                case 'finishInAppPurchase': {
                    this.$store.dispatch('finishInAppPurchase')
                    break
                }
                case 'errorInAppPurchase': {
                    this.$loading(false)
                    break
                }
                case 'sendContactList': {
                    this.$store.commit('setContacts', data.value)

                    break
                }
                case 'sendPhoto': {
                    this.imagePreprocessing(data.value).then(blob => {
                        this.$bus.$emit('onRequestPhotoFromGallery', {
                            blob,
                            fileName: data.value.fileNAme,
                        })
                    })

                    break
                }
            }
        },
        setDeviceAndHeader(device, header) {
            // 앱을 삭제하지 않고 업데이트하는 경우 로컬에 저장된 헤더 값을 업데이트 해주어야함
            if (!device || !header) return
            let { 'X-User-Agent': userAgent, 'X-User-OS': userOS } = header

            if (!userAgent || !userOS) return

            if (userAgent && userOS) {
                userAgent = JSON.parse(userAgent)
            }

            let shouldUpdateUserAgent = false
            let shouldUpdateUserOS = false

            for (const prop in device) {
                if (device[prop] !== userAgent[prop]) {
                    userAgent[prop] = device[prop]
                    shouldUpdateUserAgent = true
                }
            }

            if (shouldUpdateUserAgent) axios.defaults.headers.common['X-User-Agent'] = JSON.stringify(userAgent)

            if (device.os !== userOS) {
                userOS = device.os
                axios.defaults.headers.common['X-User-OS'] = userOS
                shouldUpdateUserOS = true
            }

            if (shouldUpdateUserAgent || shouldUpdateUserOS) {
                const newHeader = axios.defaults.headers.common
                window.localStorage.removeItem('header')
                window.localStorage.setItem('header', JSON.stringify(newHeader))
            }
        },
        onPushNotifications(remoteMessage) {
            if (!remoteMessage || !remoteMessage.action) return

            switch (remoteMessage.action) {
                // TO-DO
                case 'chats': {
                    setTimeout(() => {
                        this.openChat(remoteMessage)
                    }, 1000)
                    break
                }

                case 'friend_meeting': {
                    setTimeout(() => {
                        this.openSocialPostDetail(remoteMessage)
                    }, 1000)
                    break
                }

                case 'front_page': {
                    setTimeout(() => {
                        this.$router.push({ name: 'FrontPage' })
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'Click_Push_FromCustomerChurn',
                            },
                        })
                    }, 1000)
                    break
                }

                case 'verification_badge': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'VerificationBadgesPage' })
                    }, 1000)
                    break
                }

                case 'verification_trust_badge': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'VerificationTrustBadgesPage' })
                    }, 1000)
                    break
                }

                case 'reject_profile': {
                    setTimeout(() => {
                        this.$router.push({ name: 'MyPage' })
                    }, 1000)
                    break
                }
            }
        },
        async openChat(message) {
            try {
                let chats = this.$store.getters.chats || []

                const chatId = typeof message.action_id === 'number' ? message.action_id : Number(message.action_id)

                if (chats.length === 0 || chatId) {
                    chats = await this.$store.dispatch('loadChats', true)
                }

                const chat = chats.find(c => c.id === chatId)

                if (!chat) return

                // if (!chat) {
                //     chats = await this.$store.dispatch('loadChats', true)
                //     chat = chats.find(c => c.id === chatId)
                // }

                await this.$store.dispatch('loadChat', {
                    chatId: chatId,
                    force: true,
                })

                const unreadCount = message.badge

                this.$router.push({
                    name: 'ChatroomPage',
                    params: { unreadCount },
                })
            } catch (e) {
                // alert(e)
            }
        },
        async imagePreprocessing({ base64, type }) {
            try {
                const res = await fetch(`data:${type};base64, ${base64}`)
                const blob = await res.blob()

                return blob
            } catch (e) {
                console.error(e)
            }
        },
        async checkAlreadySignup(email, phoneNumber) {
            const { res } = await userService.checkSignup(email, phoneNumber)
            return res
        },
    },
}
</script>

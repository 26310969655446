<template>
    <div class="time-picker">
        <div class="title">시간 선택</div>
        <div class="time-picker">
            <div class="am-pm m-b-8">
                <div
                    @click="onClickItem(item)"
                    class="item"
                    v-for="item in a"
                    :class="{ selected: (time[item.key] || {}).id === item.id }"
                    :key="item.value"
                    v-html="$translate(item.value)"
                />
            </div>
            <div class="hour-container m-b-8">
                <div class="sub-title">시</div>
                <div class="hour">
                    <div
                        class="item"
                        :class="{ selected: (time[item.key] || {}).id === item.id }"
                        v-for="item in hour"
                        :key="`0-${item.value}`"
                        v-html="Number(item.value)"
                        @click="onClickItem(item)"
                    />
                </div>
            </div>
            <div class="minute-container m-b-8">
                <div class="sub-title">분</div>
                <div class="minute">
                    <div
                        @click="onClickItem(item)"
                        class="item"
                        :class="{ selected: (time[item.key] || {}).id === item.id }"
                        v-for="item in minute"
                        :key="item.value"
                        v-html="$translate(item.value)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TimePicker',
    data: () => ({
        time: {
            A: null,
            hh: null,
            mm: null,
        },
    }),
    computed: {
        a() {
            return [
                { id: 1, key: 'A', value: 'AM' },
                { id: 2, key: 'A', value: 'PM' },
            ]
        },
        hour() {
            const hour = []
            for (let i = 1; i <= 12; i++) {
                hour.push({
                    id: i,
                    key: 'hh',
                    value: i < 10 ? `0${i}` : i,
                })
            }

            return hour
        },
        minute() {
            return [
                { id: 1, key: 'mm', value: '00' },
                { id: 2, key: 'mm', value: '30' },
            ]
        },
    },
    methods: {
        onClickItem(item) {
            this.time[item.key] = item

            if (this.time.A && this.time.hh && this.time.mm) {
                this.$emit('setTime', {
                    A: this.time.A.value,
                    hh: this.time.hh.value,
                    mm: this.time.mm.value,
                })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.grid-default {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}
.title {
    font-size: 18px;
    color: black;
    margin-bottom: 16px;
    @include spoqa-f-medium;
}
.time-picker {
    .sub-title {
        color: $grey-06;
        font-size: 14px;
        margin-bottom: 8px;
        @include spoqa-f-medium;
    }
    .am-pm,
    .minute {
        @extend .grid-default;
    }
    .hour {
        @extend .grid-default;
        grid-template-columns: repeat(6, 1fr);
    }
}
.item {
    border: 1px solid #b9bdc1;
    border-radius: 8px;
    padding: 5px 0;
    @include center;

    &.selected {
        border: 1px solid $blue-primary;
        color: $blue-primary;
    }
}
</style>

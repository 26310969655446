import { render, staticRenderFns } from "./InvitationFriendPage.vue?vue&type=template&id=2421e15e&scoped=true&"
import script from "./InvitationFriendPage.vue?vue&type=script&lang=js&"
export * from "./InvitationFriendPage.vue?vue&type=script&lang=js&"
import style0 from "./InvitationFriendPage.vue?vue&type=style&index=0&id=2421e15e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2421e15e",
  null
  
)

export default component.exports
<template>
    <div class="layout">
        <div class="title" v-html="title" />
        <div class="pre-match-list-wrapper" v-if="userWithSteps">
            <div class="noti-box">
                <div class="img">
                    <img width="16" height="16" :src="require('@/assets/images/icons/noti_off.png')" alt="" />
                </div>
                <span> 문의는 마이페이지 > 고객센터에 남겨주세요 </span>
            </div>
            <PreMatchList class="pre-match-list" />
        </div>
        <div class="steps m-b-36" v-else>
            <div
                class="step-item"
                :class="{ 'now-step': nowStep.id === step.id }"
                v-for="step in onboardSteps"
                :key="step.id"
            >
                <div class="left">
                    <div class="name" v-html="step.name" />
                </div>
                <div class="right">
                    <div
                        @click="step.handler"
                        class="btn"
                        :class="step.status"
                        v-show="step.status"
                        v-html="$translate(step.status)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreMatchList from './components/PreMatchList'

export default {
    name: 'OnboardChatsPage',
    components: {
        // ChatItem,
        PreMatchList,
    },
    props: ['chats'],
    computed: {
        status() {
            return this.profile.status
        },
        profile() {
            return (this.$store.getters.me || {}).profile || {}
        },
        profileStatus() {
            return this.profile.status === 'created' ? 'WRITE' : 'PENDING'
        },
        verifyStatus() {
            const userVeriStatus = (this.$store.getters.verifyStatus || {}).required || []

            if (this.declined.length) return 'WRITE'
            if (userVeriStatus.some(item => item.status === 'NOT_YET')) return 'WRITE'
            if (userVeriStatus.every(item => item.status === 'CONFIRMED')) return 'COMPLETE'

            return 'PENDING'
        },
        declined() {
            return this.$store.getters.declined
        },
        needCertificate() {
            return !(((this.$store.getters.me || {}).verification_badges || []).find(b => b.id === 13) || {}).confirmed
        },
        editStyleStatus() {
            if ((this.$store.getters.me || {}).style) {
                const { religion_type: religion, smoking_type: smoking, priority, university_type: univ } = (
                    this.$store.getters.me || {}
                ).style

                return religion === null || smoking === null || !priority || !univ ? 'WRITE' : 'COMPLETE'
            }

            return 'WRITE'
        },
        needContract() {
            if (this.profile.status === 'judging') return 'COMPLETE'

            return 'WRITE'
        },
        nowStep() {
            const activeSteps = this.onboardSteps.filter(step => step.status !== false)

            return activeSteps[activeSteps.length - 1]
        },
        userWithSteps() {
            // return true
            if ((this.$store.getters.me || {}).user_contract) {
                return true
            } else {
                return false
            }
        },
        title() {
            if (this.userWithSteps) {
                return `반가워요 ${(this.$store.getters.me || {}).name || ''}님,<br/>
                    모든 단계를 마치면 주선이 시작됩니다:)`
            } else {
                return '상세 프로필 작성 후<br>본격적으로 소개가 진행됩니다!'
            }
        },
        onboardSteps() {
            // status false / WRITE / PENDING / COMPLETE
            return [
                {
                    id: 1,
                    name: '전자계약서 서명',
                    title:
                        this.needContract === 'COMPLETE'
                            ? '심사가 완료되면<br>매칭매니저를 배정해드려요!'
                            : '마지막 서명 한번으로<br>계약서 작성 완료!',
                    timeSpend: 1,
                    status: this.profileStatus,
                    handler: () => {
                        if (this.profileStatus !== 'WRITE') return
                        this.$stackRouter.push({ name: 'MakeContractPage' })
                    },
                },
                {
                    id: 2,
                    name: '상세 프로필 작성',
                    title: '상세 프로필 작성 후<br>본격적으로 소개가 진행됩니다!',
                    timeSpend: 3,
                    status: this.profileStatus,
                    handler: () => {
                        if (this.profileStatus !== 'WRITE') return
                        this.$stackRouter.push({ name: 'ProfileSteps' })
                    },
                },
                {
                    id: 3,
                    name: '서류인증',
                    title: this.declined.length
                        ? '서류가 반려되었어요.<br>다시 한번 확인해주세요!'
                        : '신뢰도를 위한 직장 인증은 필수!<br>두번째로 직장 인증을 진행해주세요',
                    timeSpend: 2,
                    status: this.profileStatus === 'WRITE' ? false : this.verifyStatus,
                    handler: () => {
                        if (this.verifyStatus !== 'WRITE') return
                        this.$modal.custom({
                            component: 'ModalCertificate',
                            options: {
                                verificationBadge: this.$store.getters.verifyStatus.required.filter(
                                    v => v.category === 'certification_company'
                                )[0],
                            },
                        })
                        this.$stackRouter.push({ name: 'CertificatePage' })
                    },
                },
                {
                    id: 4,
                    name: '매칭선호사항',
                    title:
                        this.editStyleStatus === 'WRITE'
                            ? '마지막으로 매니저가 알 수 있게<br>원하는 이상형을 작성해주세요!'
                            : '심사가 완료되면 매칭매니저 배정 후 소개가 진행됩니다!',
                    timeSpend: 1,
                    status: this.verifyStatus === 'WRITE' ? false : this.editStyleStatus,
                    handler: () => {
                        if (this.editStyleStatus !== 'WRITE') return
                        this.$stackRouter.push({ name: 'EditStylePage' })
                    },
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.layout {
    .title {
        @include spoqa-f-bold;
        font-size: 18px;
        line-height: 28px;
    }

    .step-item {
        @include between;
        margin-top: 16px;
        padding: 12px;
        color: black;
        font-size: 16px;

        .left {
            display: flex;
            align-items: center;

            .number {
                @include center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid $grey-06;
                color: $grey-06;
                margin-right: 16px;
            }
            .time-spend {
                @include center;
                font-size: 14px;
                background: $grey-03;
                color: $grey-09;
                padding: 1px 2px;
                border-radius: 8px;
                margin-left: 4px;
            }
        }
        .right {
            .btn {
                // background: $blue-primary;
                font-size: 14px;
                padding: 4px 12px;
                height: 32px;

                &.COMPLETE {
                    color: $grey-05;
                    background: $grey-03;
                }
                &.WRITE {
                    color: white;
                    background: $blue-primary;
                }
                &.PENDING {
                    color: $blue-primary;
                    background: $blue-background;
                    padding: 4px 18px;
                }
            }
        }

        &.now-step {
            border-radius: 12px;
            border: 1px solid $grey-03;
            box-shadow: 0 2px 8px rgba(2, 2, 3, 0.12);

            .number {
                background: #111111;
                color: white;
                border: none;
            }
        }
    }

    .pre-match-list-wrapper {
        .noti-box {
            margin-top: 8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #111111;
            background: #f7f8f9;
            border-radius: 8px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            .img {
                background-color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
            }
        }
        .pre-match-list {
            margin-top: 16px;
        }
    }
}
</style>

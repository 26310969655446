<template>
    <div class="wrapper">
        <div class="family-items">
            <div class="item-wrapper" :key="idx" v-for="(f, idx) in profile.family">
                <template>
                    <div class="job-wrapper">
                        <div class="job-status">
                            {{ $translate($profile.familyType[f.family_type].name.toUpperCase()) }}
                        </div>
                        <div class="job-description" v-if="isMe && getLiveStatus(f)">
                            <span>{{ getLiveStatus(f) }}</span>
                            <span class="manager-only" v-if="showBadge(f, 'live_status')">매니저만</span>
                        </div>
                        <div class="job-description">
                            <span class="seperator" v-for="(fs, idx) in familySchoolItem(f)" :key="idx">
                                {{ fs.value }}
                            </span>
                        </div>

                        <div class="job-description">
                            <span class="seperator" v-for="(fj, idx) in familyJobItem(f)" :key="idx">
                                <span>{{ fj.value }}</span>
                                <span class="manager-only" v-if="fj.selective && showBadge(f, fj.key)">매니저만</span>
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailFamilyItem',
    props: ['value', 'keys', 'profile', 'userId'],
    data: () => ({
        profileChanged: false,
    }),
    beforeDestroy() {
        if (this.profileChanged) this.$toast.success('SAVED')
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
    },
    methods: {
        showBadge(f, key) {
            if (!this.isMe) return false
            if (key === 'live_status') {
                return true
            }
            return !f[`show_${key}`]
        },
        getLiveStatus(item) {
            if (item.family_type === 2) return false
            return item.live_status ? '살아계심' : '작고'
        },
        familySchoolItem(f) {
            const Item = [
                {
                    key: 'education_status',
                    value: (this.$profile.educationStatus[f.education_status] || {}).title,
                },
                {
                    key: 'university_name',
                    value: f.university_name,
                },
            ]
            return Item.filter(item => item.value)
        },
        familyJobItem(f) {
            const Item = [
                {
                    key: 'company_name',
                    value: f.company_name,
                },
                {
                    key: 'job_name',
                    value: f.job_name,
                    selective: true,
                },
                {
                    key: 'job_status',
                    value: f.job_status ? '현직' : null,
                },
            ]
            const result = Item.filter(item => item.value)
            return result
        },
    },
}
</script>
<style scoped lang="scss">
// .seperator:not(:last-child)::after {
//     content: '∙';
// }

.seperator:not(:last-child)::after {
    content: ' \A ';
    width: 4px;
    height: 4px;
    background-color: #94999e;
    border-radius: 100px;
    display: inline-block;
    padding: auto;
    vertical-align: middle;
    margin: 0 7px;
}
</style>

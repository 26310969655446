<template>
    <div class="basic-profile">
        <!-- <h3 class="title" v-html="getTitle" /> -->
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/personal.png" width="24px" height="24px" />
            <span>인적사항</span>
        </h3>

        <div style="z-index: 2" class="items" v-for="item in personalProfile" :key="item.id">
            <div class="item">
                <template>
                    <div class="key" v-html="$translate(item.key.toUpperCase())" />
                    <div class="value">
                        <div class="content">
                            <span>{{ item.value }}</span>
                            <span class="manager-only" v-if="showBadge(item.key)">매니저만</span>
                        </div>
                        <div v-if="item.description" class="health-detail">{{ item.description }}</div>
                    </div>
                </template>
            </div>
        </div>

        <div class="key m-b-12" v-html="`자기소개`" />
        <SelfIntroduction v-if="profile.intro" :intro="profile.intro" />
    </div>
</template>

<script>
import SelfIntroduction from '@/routes/user-detail/components/SelfIntroduction'
export default {
    name: 'PersonalProfile',
    components: { SelfIntroduction },
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getTitle() {
            return `<i class="material-icons">person</i> 인적사항`
        },
        personalProfile() {
            const items = [
                {
                    id: 1,
                    key: 'height',
                    value: this.profile.height,
                    result: `${this.profile.height}cm`,
                },
                {
                    id: 2,
                    key: 'region_name',
                    value: this.profile.region_name,
                },
                {
                    id: 3,
                    key: 'mbti',
                    value: this.profile.mbti,
                },
                {
                    id: 4,
                    key: 'exercise_freq',
                    value: this.profile.exercise_freq,
                },
                {
                    id: 5,
                    key: 'exercise_type',
                    value: this.profile.exercise_type,
                },
                {
                    id: 6,
                    key: 'smoking',
                    value: this.profile.smoking,
                    result: this.profile.smoking ? '흡연' : '비흡연',
                },
                {
                    id: 7,
                    key: 'drink_freq',
                    value: this.profile.drink_freq,
                },
                {
                    id: 8,
                    key: 'drink_style',
                    value: this.profile.drink_style,
                },
                {
                    id: 9,
                    key: 'health_status',
                    value: this.profile.health_status,
                    result: this.profile.health_status ? '좋지않음' : '양호',
                    description: this.profile.health_detail,
                },
                {
                    id: 11,
                    key: 'religion_name',
                    value: this.profile.religion_name,
                },
                {
                    id: 12,
                    key: 'pet',
                    value: this.profile.pet,
                },
            ]

            const result = items
                .filter(item => Number.isInteger(item.value) || typeof item.value === 'boolean' || item.value)
                .map(item => {
                    if (item.description) {
                        return { key: item.key, value: item.result, description: item.description }
                    } else if (item.result) {
                        return { key: item.key, value: item.result }
                    } else {
                        return { key: item.key, value: item.value }
                    }
                })

            return result
        },
    },
    methods: {
        showBadge(key) {
            if (!this.isMe) return false
            return !this.profile.privacy_setting[`show_${key}`]
        },
    },
}
</script>
<style scoped lang="scss">
.health-detail {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
}
</style>

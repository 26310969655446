<template>
    <div class="education-items">
        <div class="item-wrapper" :key="`edu-${item.type}`" v-for="item in schoolItems">
            <template v-if="show(item)">
                <div class="job-wrapper">
                    <div class="job-status">{{ $translate(item.type.toUpperCase()) }}</div>
                    <div class="job-title">
                        <span>{{ item.name }}</span>
                        <span class="manager-only" v-if="showBadge(item)">매니저만</span>
                    </div>
                    <div class="job-title">
                        <span v-if="item.college">{{ item.college }} </span>
                        <span v-if="item.major">{{ item.major }}</span>
                    </div>
                    <div class="job-description">
                        <!-- {{ `${eduStatus[item.status]} ${item.start}년 - ${item.end}년` }} -->
                        <span class="seperator" v-if="item.status !== null">{{ eduStatus[item.status] }}</span>
                        <span class="seperator" v-if="item.start > 0 && item.end > 0">{{
                            `${item.start}년 - ${item.end}년`
                        }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailEducationItem',
    props: {
        schoolItems: Array,
        profile: Object,
        userId: Number,
    },
    created() {},
    computed: {
        eduStatus() {
            return {
                0: '졸업',
                1: '수료',
                2: '중퇴',
                3: '재학',
                4: '휴학',
                5: '편입재학',
                6: '편입졸업',
            }
        },
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
    },
    methods: {
        show(item) {
            if (item.type !== 'high_shcool') {
                return true
            } else if (this.isMe) {
                return true
            } else {
                return false
            }
        },
        showBadge(item) {
            if (!this.isMe) return false
            let schoolFlag = false
            if (this.profile.privacy_setting[`show_${item.type}_name`]) {
                schoolFlag = this.profile.privacy_setting[`show_${item.type}_name`]
            } else {
                schoolFlag = this.profile.privacy_setting[`show_${item.type}_u_name`]
            }
            if (schoolFlag) {
                return false
            } else {
                return true
            }
        },
    },
}
</script>

<style scoped lang="scss">
// .seperator::after {
//     content: '\A';
//     width: 4px;
//     height: 4px;
//     background-color: #94999e;
//     border-radius: 100px;
//     display: inline-block;
//     margin: 0 7px;
//     vertical-align: middle;
// }
.seperator:not(:last-child)::after {
    content: '\A';
    width: 4px;
    height: 4px;
    background-color: #94999e;
    border-radius: 100px;
    display: inline-block;
    padding: auto;
    vertical-align: middle;
    white-space: nowrap;
    // word-break:nowrap;
    margin: 0 7px;
}
</style>

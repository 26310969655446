<template>
    <div class="slider">
        <div class="range">
            <span v-if="title" class="c-black" :class="{ required }" v-html="title" />
            <span class="" v-html="rangeStringify" />
        </div>
        <VueSlider v-model="newRange" v-bind="sliderOptions" @change="onChange" :class="{ disabled: !updated }" />
        <div class="min-max m-t-4">
            <div v-html="`${sliderOptions.min}${unit}`" />
            <div v-html="sliderOptions.max + unit + `+`" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        title: {
            type: String,
        },
        range: {
            type: Array,
            required: true,
        },
        myRange: {
            type: Array,
            required: true,
        },
        unit: {
            type: String,
            required: true,
        },
        sliderOptions: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
        },
        rangeDefault: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        newRange: [],
        updated: false,
    }),
    mounted() {
        this.init()
    },
    computed: {
        rangeStringify() {
            if (!this.updated) {
                return `<span class='default-value'>${this.rangeDefault}</span>`
            }
            return `<span class='range-start f-bold'>${this.newRange[0]}</span> ~ <span class='range-end f-bold'>${this.newRange[1]}</span>${this.unit}`
        },
    },
    methods: {
        init() {
            if (!this.myRange[0] && !this.myRange[1]) {
                this.newRange = [...this.range]
            } else {
                this.updated = true
                this.newRange = [...this.myRange]
            }
        },
        onChange(e) {
            this.updated = true
            this.$emit('change')
        },
    },
}
</script>

<style lang="scss" scoped>
.slider {
    .range {
        @include flex-row;
        @include flex-between;
        @include spoqa-f-medium;
        font-size: 16px;
        margin: 32px 20px 12px;
        font-size: 16px;
        line-height: 26px;
        color: #04c9b2;
        &::v-deep .default-value {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #b9bdc1;
        }
    }

    .vue-slider {
        z-index: 1;
        margin: 0 20px;
    }

    .min-max {
        margin: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: $grey-08;
    }
    .required::after {
        content: ' *';
        color: #ff3d6b;
    }

    .disabled::v-deep .vue-slider-process {
        background-color: #c2e4e0;
    }
}
</style>

<template>
    <div class="pre-match-list">
        <ul class="list-wrapper">
            <li
                class="list-item"
                v-for="(step, idx) in steps"
                :key="idx"
                :class="getCurrentStep === step.id ? 'primary' : step.style"
            >
                <div :class="getCurrentStep === step.id ? 'primary' : step.style">
                    <span>{{ step.title }}</span>
                    <button
                        class="btn btn-primary"
                        v-if="getCurrentStep >= step.id && step.action"
                        @click="onClickToStep(step)"
                        :class="getCurrentStep !== step.id ? 'disabled' : ''"
                    >
                        {{ step.action }}{{ getCurrentStep === step.id ? '하기' : '완료' }}
                    </button>
                </div>
                <ChatItem
                    v-if="getCurrentStep === 6 && step.id === 6 && chat"
                    class="chat-item-wrap"
                    :chat="chat"
                    :key="chat.id"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import ChatItem from './ChatItem'

export default {
    name: 'PreMatchList',
    props: {},
    data: () => ({}),
    components: {
        ChatItem,
    },
    watch: {
        getCurrentStep(newStep) {
            switch (newStep) {
                case 2:
                    this.$toast.success('작성 완료! 계약서는 마이페이지에서 확인할 수 있습니다.')
                    break
                case 3:
                    this.$toast.success('작성 완료! 진실성 담보 약정서는 마이페이지에서 확인할 수 있습니다.')
                    break
                case 4:
                    this.$toast.success('작성이 완료되었습니다.')
                    break
                case 5:
                    this.$toast.success('제출이 완료되었습니다.')
                    break
                case 6:
                    this.$store.dispatch('loadChats', true)
                    break
                default:
                    break
            }
        },
    },
    computed: {
        profile() {
            return (this.$store.getters.me || {}).profile || {}
        },
        steps() {
            return [
                {
                    id: 0,
                    title: '가입시작',
                    style: 'start-end',
                },
                {
                    id: 1,
                    title: '계약서 작성',
                    style: 'default',
                    action: '작성',
                    route: 'MakeContractPage', // 진실성 담보 약정서 페이지 추가하기
                },
                {
                    id: 2,
                    title: '진실성 담보 약정서',
                    style: 'default',
                    action: '작성',
                    route: 'MakeTruthContractPage',
                },
                {
                    id: 3,
                    title: '프로필 작성',
                    style: 'default',
                    action: '작성',
                    route: 'ProfileSteps',
                },
                {
                    id: 4,
                    title: '서류 제출',
                    style: 'default',
                    action: '제출',
                    route: 'CertificatePage',
                    props: {
                        onCertiAllSubmittedMove: this.onCertiAllSubmittedMove,
                    },
                },
                {
                    id: 5,
                    title: '매칭 선호 사항 작성',
                    style: 'default',
                    action: '작성',
                    route: 'EditStylePage',
                },
                {
                    id: 6,
                    title: '프로필 및 서류 심사',
                    style: 'default',
                },
                {
                    id: 7,
                    title: '매칭매니저 배정',
                    style: 'start-end',
                },
            ]
        },
        me() {
            return this.$store.getters.me
        },
        style() {
            return this.$store.getters.me.style || {}
        },
        myVerificationBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return this.myVerificationBadges.some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
        styleSubmitted() {
            const multiSelectItems = ['university', 'religion']
            const notAnswered = ['priority', 'max_age', 'min_age', 'max_height', 'min_height'].some(i => !this.style[i])
            const notAnsweredSmoking = this.style.smoking_type === null ? true : false
            const emptyListAnswer = multiSelectItems.some(i => !(this.$mustParse(this.style[`${i}_type`]) || []).length)
            return !(notAnswered || notAnsweredSmoking || emptyListAnswer)
        },
        getCurrentStep() {
            // 계약서 작성 안 했다면 1 조건 추가
            // return 1
            if (!this.me.user_contract.is_complete) return 1
            else if (!this.me.user_contract.truth_complete) return 2
            // 진실성 담보 약정서 2 추가
            else if (this.profile.status === 'created') return 3
            else if (!this.certiSubmitted) return 4
            else if (!this.styleSubmitted) return 5
            else return 6
        },
        chat() {
            // 심사팀 챗으로 변경
            return this.$store.getters.chats.filter(chat => chat.agent_id === 8)[0] || null
        },
        chats() {
            return this.$store.getters.chats
        },
    },
    methods: {
        onCertiAllSubmittedMove() {
            this.$stackRouter.pop()
            console.log(this.certiSubmitted)
        },
        onClickToStep(step) {
            if (!step.route) return
            if (step.id < this.getCurrentStep) return
            this.$stackRouter.push({
                name: step.route,
                props: step.props,
            })
        },
    },
    async mounted() {
        await this.$store.dispatch('loadMyVerificationBadges')
    },
}
</script>

<style lang="scss" scoped>
.pre-match-list {
    padding: 0 6px 0 18px;

    ul {
        li {
            position: relative;
            margin-bottom: 0;
            padding-bottom: 0px;
            border-radius: 100px;
            padding-left: 6px;
            font-size: 16px;
            line-height: 26px;
            div {
                // padding-left: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 0;
                height: 56px;
                margin-left: 10px;
                &.current-step {
                    font-weight: 700;
                }
                &.start-end {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 20px;
                    color: #666b70;
                    padding: 0;
                    height: 32px;
                }
                .btn {
                    height: 40px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 8px 16px;

                    &.disabled {
                        background: #f7f8f9;
                        color: #94999e;
                    }
                }
            }
            .chat-item-wrap {
                width: 100%;
                padding: 12px;
                margin-bottom: 12px;
                height: 100%;
            }
        }
        li:after {
            //총알
            content: '\A';
            width: 10px;
            height: 10px;
            background: red;
            border-radius: 100%;
            position: absolute;
            left: -4.5px;
            top: 22.5px; // 버튼 높이 (위아래 ㅍ패딩 포함)/2 - 총알 높이 / 2 , 이 높이만큼 first-child, last-child 위치조정
        }

        li:before {
            //세로선
            content: '';
            position: absolute;
            left: 0px;
            border-left: 1px solid #b9bdc1;
            height: 100%;
            width: 1px;
        }

        li:first-child:before {
            top: 10px;
        }
        li:last-child:before {
            bottom: 14px;
        }
        li.start-end:after {
            background: #94999e;
            top: 10px;
        }
        li.default:after {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border: 1px solid #666b70;
            left: -5.5px;
        }
        li.primary:after {
            width: 12px;
            height: 12px;
            background: #04c9b2;
            border: 1px solid #04c9b2;
            left: -5.5px;
        }
    }
}
</style>
